import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import FourFour                                             from './pages/404/404'
import Login                                                from './pages/auth/Login'
import PasswordReset                                        from './pages/auth/PasswordReset'
import BmPlatform                                           from './pages/BmPlatform/BmPlatform'
import BmPlatformFaq                                        from './pages/BmPlatform/BmPlatformFaq'
import BmPlatformHelpCentre                                 from './pages/BmPlatform/BmPlatformHelpCentre'
import DesignDock                                           from './pages/DesignDock/DesignDock'
import DesignDockError                                      from './pages/DesignDock/DesignDockError'
import Documents                                            from './pages/Documents'
import { DocumentsDownloadLog }                             from './pages/Downloads'
import CommunityForum                                       from './pages/Forum/CommunityForum'
import CommunityForumEditThread                             from './pages/Forum/CommunityForumEditThread'
import CommunityForumNewThread                              from './pages/Forum/CommunityForumNewThread'
import CommunityForumThread                                 from './pages/Forum/CommunityForumThread'
import Home                                                 from './pages/Home'
import HowToVideos                                          from './pages/HowToVideos/HowToVideos'
import NewSeniorLeaderNotification                          from './pages/NewSeniorLeaderNotification/NewSeniorLeaderNotification'
import NurserySupport                                       from './pages/NurserySupport/NurserySupport'
import RegulatoryBodyNotificationForm                       from './pages/RegulatoryBodyNotificationForm/RegulatoryBodyNotificationForm'
import Settings                                             from './pages/Settings/Settings'
import SettingsForumCategories                              from './pages/Settings/SettingsForumCategories'
import SettingsForumRank                                    from './pages/Settings/SettingsForumRank'
import SettingsForumRanks                                   from './pages/Settings/SettingsForumRanks'
import SettingsUsersAccountTypes                            from './pages/Settings/SettingsUsersAccountTypes'
import FranchiseDevelopmentDayPresentation                  from './pages/static/FranchiseDevelopmentDayPresentation'
import Suppliers                                            from './pages/Suppliers/Suppliers'
import SuppliersFeedback                                    from './pages/Suppliers/SuppliersFeedback'
import UserProfile                                          from './pages/User/UserProfile'
import UserProfileEdit                                      from './pages/User/UserProfileEdit'
import Users                                                from './pages/Users/Users'
import UsersEdit                                            from './pages/Users/UsersEdit'
import './App.css'

export default function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={ <Home /> } />

        <Route exact path="/bm-platform" element={ <BmPlatform /> } />
        <Route exact path="/bm-platform/faq" element={ <BmPlatformFaq /> } />
        <Route exact path="/bm-platform/help-centre" element={ <BmPlatformHelpCentre /> } />

        <Route exact path="/design-dock" element={ <DesignDock /> } />
        <Route exact path="/design-dock-error" element={ <DesignDockError /> } />

        <Route exact path="/documents/*" element={ <Documents /> } />
        <Route exact path="/downloads/*" element={ <DocumentsDownloadLog /> } />

        <Route exact path="/forum/add" element={ <CommunityForumNewThread /> } />
        <Route exact path="/forum/edit/:thread_id" element={ <CommunityForumEditThread /> } />
        <Route exact path="/forum/:thread_id" element={ <CommunityForumThread /> } />
        <Route exact path="/forum" element={ <CommunityForum /> } />

        <Route exact path="/franchise-development-day-presentation" element={ <FranchiseDevelopmentDayPresentation /> } />

        <Route exact path="/how-to-videos" element={ <HowToVideos /> } />

        <Route exact path="/login" element={ <Login /> } />

        <Route exact path="/new-senior-leader-notification" element={ <NewSeniorLeaderNotification /> } />

        <Route exact path="/nursery-support" element={ <NurserySupport /> } />

        <Route exact path="/password/reset" element={ <PasswordReset /> } />

        <Route exact path="/profile/edit" element={ <UserProfileEdit /> } />
        <Route exact path="/profile/:user_id" element={ <UserProfile /> } />
        <Route exact path="/profile" element={ <UserProfile /> } />

        <Route exact path="/regulatory-body-notification-form" element={ <RegulatoryBodyNotificationForm /> } />

        <Route exact path="/settings" element={ <Settings /> } />
        <Route exact path="/settings/users/account-types" element={ <SettingsUsersAccountTypes /> } />
        <Route exact path="/settings/forum/categories" element={ <SettingsForumCategories /> } />
        <Route exact path="/settings/forum/ranks" element={ <SettingsForumRanks /> } />
        <Route exact path="/settings/forum/ranks/add" element={ <SettingsForumRank /> } />
        <Route exact path="/settings/forum/ranks/edit/:rank_id" element={ <SettingsForumRank /> } />

        <Route exact path="/suppliers" element={ <Suppliers /> } />
        <Route exact path="/suppliers/feedback" element={ <SuppliersFeedback /> } />

        <Route exact path="/users-new/edit/:user_id" element={ <UsersEdit /> } />
        <Route exact path="/users-new" element={ <Users /> } />

        <Route path="*" element={ <Navigate to="/404" /> } />

        <Route path="/404" element={ <FourFour /> } />
      </Routes>
    </Router>
  )
}

import { useLazyQuery }             from '@apollo/client'
import { debounce }                 from 'lodash-es'
import { useEffect, useState }      from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams }          from 'react-router-dom'
import { LoadingEllipsis }          from '../../components/Loaders'
import Pagination                   from '../../components/Pagination/Pagination'
import SearchBar                    from '../../components/Search/SearchBar'
import UsersTable                   from '../../components/Users/UsersTable'
import { setAccountTypes }          from '../../features/user/currentUserSlice'
import { ACCOUNT_TYPES }            from '../../graphql/account_type'
import { GET_USERS }                from '../../graphql/user'
import MainLayout                   from '../../layouts/MainLayout'
import { setURLParam }              from '../../utilities'

export default function Users(): JSX.Element {
  const dispatch: any       = useDispatch()
  const [ searchParams ]    = useSearchParams()
  const currentUser: any    = useSelector( ( state: any ): any => state.currentUser.user )
  const accountTypes: any   = useSelector( ( state: any ): any => state.currentUser.accountTypes )
  const [ users, setUsers ] = useState( null )
  const [ query, setQuery ] = useState( '' )

  const [ getUsers, { loading, error, refetch } ] = useLazyQuery( GET_USERS, {
    notifyOnNetworkStatusChange: true,
    onCompleted:                 ( { users }: any ): void => setUsers( users )
  } )

  const fetchUsers: any = async ( query: string, page: number ): Promise<any> => {
    await getUsers( { variables: { input: { query: ( query?.length > 0 ) ? query : null, page: ( page > 0 ) ? page : 1 } } } )
  }

  const debouncedSearch: any = debounce( async ( query: string, page: number ): Promise<any> => await getUsers( { variables: { input: { query, page } } } ), 2000 )

  const handleSearch: any = ( value: string ): void => {
    setURLParam( 'page', 1 )

    if ( value.length && value.trim().length > 0 ) {
      setQuery( value )
      debouncedSearch( value, 1 )
    } else {
      setQuery( '' )
      fetchUsers( null, 1 )
    }
  }

  const [ getAccountTypes, { loading: loadingAccountTypes } ] = useLazyQuery( ACCOUNT_TYPES, {
    onCompleted: ( { accountTypes }: any ): void => dispatch( setAccountTypes( accountTypes ) )
  } )

  const fetchAccountTypes: any = async (): Promise<any> => {
    await getAccountTypes()
  }

  const setCurrentPage: any = ( query: string, currentPage: number = 1 ): any => {
    setURLParam( 'page', currentPage )
    fetchUsers( query ?? null, currentPage )
  }

  useEffect( (): any => {
    let shouldFetch: boolean = true

    if ( shouldFetch && !accountTypes?.length ) {
      fetchAccountTypes()
    }

    return (): void => {
      shouldFetch = false
    }
  }, [ accountTypes ] )

  useEffect( (): any => {
    let shouldFetch: boolean = true
    const pageParam: number  = !isNaN( parseInt( searchParams.get( 'page' ) ) ) ? parseInt( searchParams.get( 'page' ) ) : 1
    const queryParam: string = searchParams.get( 'q' )

    if ( shouldFetch ) {
      if ( queryParam ) {
        setQuery( queryParam )
      } else {
        setQuery( '' )
      }

      fetchUsers( queryParam, pageParam )
    }

    return (): void => {
      shouldFetch = false
    }
  }, [ currentUser ] )

  return (
    <MainLayout>
      <div className="max-w-screen-3xl mx-auto w-full">
        <div className="flex-grow xl:p-8 p-3 space-y-4 xl:space-y-8">
          { ( loading || loadingAccountTypes ) && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4 mt-4 w-full" /> }
          { !loading && !loadingAccountTypes && currentUser?.admin && users?.data &&
            <>
              <div className="row flex items-center w-full bg-white rounded-3xl p-1.5 2xl:py-2 2xl:px-4 mb-6 text-blue-input">
                <SearchBar
                  loading={ loading }
                  results={ users?.data ?? null }
                  pagination={ users?.links ?? null }
                  onChange={ handleSearch }
                  setPage={ setCurrentPage }
                  placeholder="Search users"
                  type="users"
                />
              </div>

              <div className="row flex flex-col items-center w-full bg-white rounded-3xl p-3 xl:p-4">
                <div className="col flex flex-col w-full">
                  <UsersTable users={ users.data } accountTypes={ accountTypes } refetch={ refetch } />

                  { ( users.links?.totalPages > 0 ) &&
                    <Pagination
                      className="justify-content-center"
                      currentPage={ users.links.page }
                      totalCount={ users.links.totalResults }
                      pageSize={ users.links.perPage }
                      onPageChange={ ( page: any ): any => setCurrentPage( query, page ) }
                    />
                  }
                </div>
              </div>
            </>
          }
          { !currentUser?.admin &&
            <div className="row flex flex-col items-center w-full bg-white rounded-3xl p-3 xl:p-4">
              Access Denied!
            </div>
          }
          { error &&
            <div className="row flex flex-col items-center w-full bg-white rounded-3xl p-3 xl:p-4">
              <b>Error occurred when fetching Users List:</b>
              { error.graphQLErrors.map( ( { message }: any, i: number ): any => (
                <div key={ i } className="flex w-full"><span>{ message }</span></div>
              ) ) }
            </div>
          }
        </div>
      </div>
    </MainLayout>
  )
}

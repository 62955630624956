import { useState }          from 'react'
import { Button, TextField } from '../'

export default function SettingsForumCategoryForm( props: any ): JSX.Element {
  const { handleFormSubmit, forumCategory }         = props
  const [ forumCategoryName, setForumCategoryName ] = useState( forumCategory?.name ?? '' )
  const [ nameError, setNameError ]                 = useState( false )

  const handleSubmit: any = (): any => {
    setNameError( false )

    if ( !forumCategoryName.length || forumCategoryName.length < 3 ) {
      setNameError( true )
      return
    }

    if ( forumCategory?.name === forumCategoryName ) return

    handleFormSubmit( forumCategoryName, forumCategory?.id ?? '' )
  }

  return (
    <div className="flex flex-col w-full bg-white rounded-3xl p-4 xl:p-5 space-y-4">
      <div className="flex items-center justify-between">
        <div className="flex flex-wrap items-center">
          <TextField
            label=""
            value={ forumCategoryName }
            name="name"
            type="text"
            onChange={ setForumCategoryName }
            placeholder={ forumCategory?.id ? '' : 'Type new category name ...' }
            wrapperClasses="w-full"
            fieldClasses={ `py-4 px-5 border ${ nameError ? 'border-red-600' : 'border-grey-light' } rounded-2xl w-full text-blue-input font-medium placeholder-grey-input` }
            isRequired
          />
        </div>
        <div className="flex flex-wrap items-center">
          <Button label={ forumCategory ? 'Update' : 'Create' } yellow onClick={ handleSubmit } />
        </div>
      </div>
    </div>
  )
}

import { useState }          from 'react'
import { Button, TextField } from '../'

export default function SettingsAccountTypeForm( props: any ): JSX.Element {
  const { handleFormSubmit, accountType }       = props
  const [ accountTypeName, setAccountTypeName ] = useState( accountType?.name ?? '' )
  const [ nameError, setNameError ]             = useState( false )

  const handleSubmit: any = (): any => {
    setNameError( false )

    if ( !accountTypeName.length || accountTypeName.length < 3 ) {
      setNameError( true )
      return
    }

    if ( accountType?.name === accountTypeName ) return

    handleFormSubmit( accountTypeName, accountType?.id ?? '' )
  }

  return (
    <div className="flex flex-col w-full bg-white rounded-3xl p-4 xl:p-5 space-y-4">
      <div className="flex items-center justify-between">
        <div className="flex flex-wrap items-center">
          <TextField
            label=""
            value={ accountTypeName }
            name="name"
            type="text"
            onChange={ setAccountTypeName }
            placeholder={ accountType?.id ? '' : 'Type new account type name ...' }
            wrapperClasses="w-full"
            fieldClasses={ `py-4 px-5 border ${ nameError ? 'border-red-600' : 'border-grey-light' } rounded-2xl w-full text-blue-input font-medium placeholder-grey-input` }
            isRequired
          />
        </div>
        <div className="flex flex-wrap items-center">
          <Button label={ accountType ? 'Update' : 'Create' } yellow onClick={ handleSubmit } />
        </div>
      </div>
    </div>
  )
}

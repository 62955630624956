import { MagnifyingGlassIcon }        from '@heroicons/react/24/outline'
import { useState }                   from 'react'
import { Modal }                      from '../Modal'
import CommunityForumAskQuestionModal from './CommunityForumAskQuestionModal'

export default function CommunityForumAskQuestion( props: any ): JSX.Element {
  const { isColumn, label, wrapperClasses, similarOnly } = props
  const [ isOpen, setIsOpen ]                          = useState( false )
  const [ question, setQuestion ]                      = useState( '' )
  const [ error, setError ]                            = useState( false )

  const handleCloseModal: any = (): void => {
    setQuestion( '' )
    setError( false )
    setIsOpen( false )
  }

  const handleSetQuestion: any = ( e: any ): void => {
    e.preventDefault()

    setError( false )
    setQuestion( e.target.value )
  }

  const handleAskQuestion: any = async ( e: any ): Promise<void> => {
    e.preventDefault()

    if ( !question.length || question.length < 3 ) {
      setError( true )
      return
    }

    setError( false )
    setIsOpen( true )
  }

  return (
    <div className={ wrapperClasses ?? 'flex flex-col justify-center bg-blue-secondary rounded-3xl w-full p-4' }>
      <h3 className={ `text-white font-bold text-2xl mb-4${ isColumn ? ' text-center' : ' text-center 2xl:text-left' }` }>{ label ?? 'Ask a question to the network' }</h3>

      <form className={ `flex flex-col items-center w-full rounded-full${ isColumn ? '' : ' 2xl:flex-row 2xl:space-x-2' }` } onSubmit={ handleAskQuestion }>
        <div className={ `flex relative mb-3 w-full items-center rounded-full overflow-hidden${ isColumn ? '' : ' 2xl:mb-0' }${ error ? ' border border-red-500' : '' }` }>
          <div className="absolute left-3 top-1/2 transform -translate-y-1/2 ">
            <MagnifyingGlassIcon className="text-blue-input w-5 h-5" />
          </div>

          <input
            id="ask-question"
            type="search"
            placeholder="Type your question here..."
            value={ question }
            onChange={ handleSetQuestion }
            className="pl-10 w-full bg-blue-light-secondary active:bg-blue-light-secondary border-none focus:border-transparent focus:ring-0 text-sm font-medium placeholder:text-blue-input"
          />
        </div>

        <button type="submit" className={ `w-full px-8 py-1.5 font-medium text-blue-input bg-yellow-300 rounded-full${ isColumn ? '' : ' 2xl:w-fit 2xl:px-12' }` }>
          Submit
        </button>
      </form>

      <Modal
        fit
        hFit
        top
        maxHeight={ 'max-content' }
        flush
        isOpen={ isOpen }
        onClose={ handleCloseModal }
        content={ <CommunityForumAskQuestionModal question={ question } similarOnly={ similarOnly } /> }
      />
    </div>
  )
}

import { useLexicalComposerContext }                                                  from '@lexical/react/LexicalComposerContext'
import { LexicalTypeaheadMenuPlugin, TypeaheadOption, useBasicTypeaheadTriggerMatch } from '@lexical/react/LexicalTypeaheadMenuPlugin'
import { TextNode }                                                                   from 'lexical'
import * as React                                                                     from 'react'
import { useCallback, useEffect, useMemo, useState }                                  from 'react'
import * as ReactDOM                                                                  from 'react-dom'

import { $createMentionNode } from '../nodes/MentionNode'

const PUNCTUATION: string =
        '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;'
const NAME: string        = '\\b[A-Z][^\\s' + PUNCTUATION + ']'

const DocumentMentionsRegex: any = {
  NAME,
  PUNCTUATION
}

const PUNC: string = DocumentMentionsRegex.PUNCTUATION

const TRIGGERS: string = [ '@' ].join( '' )

// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS: string = '[^' + TRIGGERS + PUNC + '\\s]'

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS: string =
        '(?:' +
        '\\.[ |$]|' + // E.g. "r. " in "Mr. Smith"
        ' |' + // E.g. " " in "Josh Duck"
        '[' +
        PUNC +
        ']|' + // E.g. "-' in "Salier-Hellendag"
        ')'

const LENGTH_LIMIT: number = 75

const AtSignMentionsRegex: any = new RegExp(
  '(^|\\s|\\()(' +
  '[' +
  TRIGGERS +
  ']' +
  '((?:' +
  VALID_CHARS +
  VALID_JOINS +
  '){0,' +
  LENGTH_LIMIT +
  '})' +
  ')$'
)

// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT: number = 50

// Regex used to match alias.
const AtSignMentionsRegexAliasRegex: any = new RegExp(
  '(^|\\s|\\()(' +
  '[' +
  TRIGGERS +
  ']' +
  '((?:' +
  VALID_CHARS +
  '){0,' +
  ALIAS_LENGTH_LIMIT +
  '})' +
  ')$'
)

// At most, 5 suggestions are shown in the popup.
const SUGGESTION_LIST_LENGTH_LIMIT: number = 5

const mentionsCache: any = new Map()

function useMentionLookupService( mentionString: string | null, mentionsUsersList: any ): any
{
  const [ results, setResults ] = useState<Array<string>>( [] )

  const lookupService: any = {
    search( string: string, callback: ( results: Array<string> ) => void ): void
    {
      setTimeout( (): void => {
        const results: any = mentionsUsersList.filter( ( user: any ): any => user.name.toLowerCase().includes( string.toLowerCase() ) )
        callback( results )
      }, 500 )
    }
  }

  useEffect( (): void => {
    const cachedResults: any = mentionsCache.get( mentionString )

    if ( mentionString == null ) {
      setResults( [] )
      return
    }

    if ( cachedResults === null ) {
      return
    } else if ( cachedResults !== undefined ) {
      setResults( cachedResults )
      return
    }

    mentionsCache.set( mentionString, null )
    lookupService.search( mentionString, ( newResults: any ): void => {
      mentionsCache.set( mentionString, newResults )
      setResults( newResults )
    } )
  }, [ mentionString ] )

  return results
}

function checkForAtSignMentions(
  text: string,
  minMatchLength: number
): any
{
  let match: any = AtSignMentionsRegex.exec( text )

  if ( match === null ) {
    match = AtSignMentionsRegexAliasRegex.exec( text )
  }
  if ( match !== null ) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace: any = match[ 1 ]

    const matchingString: any = match[ 3 ]
    if ( matchingString.length >= minMatchLength ) {
      return {
        leadOffset:        match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[ 2 ]
      }
    }
  }
  return null
}

function getPossibleQueryMatch( text: string ): any
{
  return checkForAtSignMentions( text, 1 )
}

class MentionTypeaheadOption extends TypeaheadOption
{
  name: string
  id: string
  email: string
  picture: JSX.Element
  setRefElement: any
  key: any

  constructor( user: any, picture: JSX.Element )
  {
    super( user.name )
    this.name    = user.name
    this.id      = user.id
    this.email   = user.email
    this.picture = picture
  }
}

function MentionsTypeaheadMenuItem( {
                                      index,
                                      isSelected,
                                      onClick,
                                      onMouseEnter,
                                      option
                                    }: {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: MentionTypeaheadOption;
} ): JSX.Element
{
  let className: string = 'item cursor-pointer mb-2'

  if ( isSelected ) {
    className += ' selected text-input-header font-bold'
  }

  return (
    <li
      key={ option.key }
      tabIndex={ -1 }
      className={ className }
      ref={ option.setRefElement }
      role="option"
      aria-selected={ isSelected }
      id={ 'typeahead-item-' + index }
      onMouseEnter={ onMouseEnter }
      onClick={ onClick }>
      { option.picture }
      <span className="text p-2">{ option.name }</span>
    </li>
  )
}

export default function NewMentionsPlugin( props: any ): JSX.Element | null {
  const [ editor ] = useLexicalComposerContext()

  const [ queryString, setQueryString ] = useState<string | null>( null )

  const results: any = useMentionLookupService( queryString, props.mentionsUsersList )

  const checkForSlashTriggerMatch: any = useBasicTypeaheadTriggerMatch( '/', {
    minLength: 0
  } )

  const options: MentionTypeaheadOption[] = useMemo(
    (): MentionTypeaheadOption[] =>
      results
        .map( ( result: any ): MentionTypeaheadOption => new MentionTypeaheadOption( result, <i className="icon user" /> ) )
        .slice( 0, SUGGESTION_LIST_LENGTH_LIMIT ),
    [ results ]
  )

  const onSelectOption: any = useCallback(
    (
      selectedOption: MentionTypeaheadOption,
      nodeToReplace: TextNode | null,
      closeMenu: () => void
    ): void => {
      editor.update( (): void => {
        const mentionNode: any = $createMentionNode( selectedOption )
        if ( nodeToReplace ) {
          nodeToReplace.replace( mentionNode )
        }
        mentionNode.select()
        closeMenu()
      } )
    },
    [ editor ]
  )

  const checkForMentionMatch: any = useCallback(
    ( text: string ): any => {
      const slashMatch: any = checkForSlashTriggerMatch( text, editor )
      if ( slashMatch !== null ) {
        return null
      }
      return getPossibleQueryMatch( text )
    },
    [ checkForSlashTriggerMatch, editor ]
  )

  return (
    <LexicalTypeaheadMenuPlugin<MentionTypeaheadOption>
      onQueryChange={ setQueryString }
      onSelectOption={ onSelectOption }
      triggerFn={ checkForMentionMatch }
      options={ options }
      menuRenderFn={ (
        anchorElementRef: any,
        { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }: any
      ): any =>
        anchorElementRef.current && results.length
        ? ReactDOM.createPortal(
          <div className="typeahead-popover mentions-menu mt-6 whitespace-nowrap">
            <ul>
              { options.map( ( option: MentionTypeaheadOption, i: number ): JSX.Element => (
                <MentionsTypeaheadMenuItem
                  index={ i }
                  isSelected={ selectedIndex === i }
                  onClick={ (): void => {
                    setHighlightedIndex( i )
                    selectOptionAndCleanUp( option )
                  } }
                  onMouseEnter={ (): void => {
                    setHighlightedIndex( i )
                  } }
                  key={ i }
                  option={ option }
                />
              ) ) }
            </ul>
          </div>,
          anchorElementRef.current
        )
        : null
      }
    />
  )
}

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ChangeEvent } from "react";
import Api from '../../../utilities/axios';

//import "../styles.css";

import type { InsertImagePayload } from "./ImagePlugin";
import { INSERT_IMAGE_COMMAND } from "./ImagePlugin";
import { UPLOAD_FILE } from "@/graphql/file";
import {  useMutation } from '@apollo/client';

export function FillURL() {
  const srcfile = prompt("Enter the URL of the image:", "");

  return srcfile;
}

export default function ToolbarPlugin(props: any): JSX.Element {
  const [editor] = useLexicalComposerContext();

  // const [uploadFile, { loading, error }] = useMutation(UPLOAD_FILE, {
  //   onCompleted: (data) => shouldCleanup(data),
  //   onError: (error) => handleError(error)
  // });

  const addImage = (payload: InsertImagePayload) => {
    editor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);
  };

  const handleImageUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result?.toString().split(',')[1];
        const fileType = file.type.split('/')[1]; // Extract file type
        let src = `data:image/${fileType};base64,${base64String}`;
        const altText = file.name;
        const img = new Image();

        if (props.directImageUpload) {
          // Upload directly to S3 and use the location url for the image src
         try {
            const baseUrl = process.env.REACT_APP_UPLOAD_HOST;
            const formData = new FormData();
            formData.append('file', file);
            const res = await Api(baseUrl).post('/', formData);
            src = res.data.url
         } catch (error) {
            console.log(error)
         }
        }

        img.onload = () => {
          const { width, height } = img;
          console.log('Image dimensions:', width, height);
          addImage({
            altText,
            src,
            width,
            //height
          });
        };
        img.src = src;
      };
      reader.readAsDataURL(file);
    }
  };
  
  const getImageDimensions = (blob: Blob) => {
    return new Promise<{ width: number; height: number }>((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = () => {
        reject(new Error('Failed to load image'));
      };
      img.src = URL.createObjectURL(blob);
    });
  };
  
  const arrayBufferToString = (arrayBuffer: ArrayBuffer) => {
    const uintArray = new Uint8Array(arrayBuffer);
    let binaryString = '';
    for (let i = 0; i < uintArray.length; i++) {
      binaryString += String.fromCharCode(uintArray[i]);
    }
    return btoa(binaryString);
  };
  

  return (
    <div className="flex">
      <label className="relative toolbar-item">
        <span className="icon image"></span>
        <input className="absolute hidden" type="file" accept="image/*" onChange={handleImageUpload} />
      </label>
      {/* <button
        onClick={() =>
          onClick({
            altText: "URL image",
            src: FillURL()
          })
        }
        className={"toolbar-item hidden spaced "}
      >
        <span className="text">Insert from URL</span>
      </button> */}
    </div>
  );
}

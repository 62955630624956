import { useLazyQuery }                   from '@apollo/client'
import { useEffect, useState }            from 'react'
import { GET_FORUM_THREADS_USER_REPLIES } from '../../graphql/communityForum'
import CommunityForumThreadReply          from '../Forum/CommunityForumThreadReply'
import { LoadingEllipsis }                from '../Loaders'
import Pagination                         from '../Pagination/Pagination'

export default function UserProfileCommunityForumRepliesTab( props: any ): JSX.Element {
  const { user }                                                          = props
  const [ forumThreadReplies, setForumThreadReplies ]                     = useState( null )
  const [ forumThreadRepliesPagination, setForumThreadRepliesPagination ] = useState( null )
  const [ page, setPage ]                                                 = useState( 1 )

  const [ fetchForumReplies, { loading, error, refetch } ] = useLazyQuery( GET_FORUM_THREADS_USER_REPLIES, {
    onCompleted: ( { forumThreadUserReplies }: any ): void => {
      setForumThreadReplies( forumThreadUserReplies.data )
      setForumThreadRepliesPagination( forumThreadUserReplies.links )
    }
  } )

  useEffect( (): any => {
    let shouldFetch: boolean = true

    if ( shouldFetch && user ) {
      fetchForumReplies( { variables: { input: { author_id: user.id, page: ( page > 0 ) ? page : 1 } } } )
    }

    return (): void => {
      shouldFetch = false
    }
  }, [ user, page ] )

  return (
    <>
      { loading && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }
      { !loading && forumThreadReplies &&
        <div className="row flex flex-col items-center w-full bg-white rounded-xl px-4 pb-2 pt-2 lg:px-5">
          <div className="col flex flex-col w-full">
            { forumThreadReplies.map( ( reply: any, index: any ): any => <CommunityForumThreadReply
              canModify={ false }
              key={ reply.id }
              reply={ reply }
              replyable={ false }
              refetch={ refetch }
              isLast={ forumThreadReplies.length === ( index + 1 ) }
            /> ) }
          </div>

          { ( forumThreadRepliesPagination?.totalPages > 0 ) &&
            <Pagination
              className="justify-content-center pb-2 lg:pb-3"
              currentPage={ forumThreadRepliesPagination.page }
              totalCount={ forumThreadRepliesPagination.totalResults }
              pageSize={ forumThreadRepliesPagination.perPage }
              onPageChange={ ( page: number ): void => setPage( page ) }
            /> }
        </div>
      }
      { error &&
        <div className="flex flex-col w-full bg-white rounded-xl p-4 lg:p-5">
          <b>Error occurred while fetching Forum Threads:</b>
          { error.graphQLErrors.map( ( { message }: any, i: number ): any => (
            <div key={ i } className="flex w-full"><span>{ message }</span></div>
          ) ) }
        </div>
      }
    </>
  )
}

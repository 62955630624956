import { useQuery }                      from '@apollo/client'
import { format, parseISO }              from 'date-fns'
import { useState }                      from 'react'
import { Link }                          from 'react-router-dom'
import { GET_FORUM_RECENT_THREADS_LIST } from '../../graphql/communityForum'
import { LoadingEllipsis }               from '../Loaders'
import UserAvatar                        from '../User/UserAvatar'

export default function CommunityForumRecentQuestions( props: any ): JSX.Element {
  const { excludedId, header, user, qty, setShowRecentQuestions } = props
  const [ recentQuestions, setRecentQuestions ]                   = useState( [] )

  const { loading, error } = useQuery( GET_FORUM_RECENT_THREADS_LIST, {
    variables:   {
      input: {
        author_id:   user?.id ?? null,
        excluded_id: excludedId ?? null,
        limit:       qty ?? 5
      }
    },
    onCompleted: ( { forumRecentThreadsList }: any ): any => {
      if ( setShowRecentQuestions ) {
        setShowRecentQuestions( ( forumRecentThreadsList.length > 0 ) )
      }
      setRecentQuestions( forumRecentThreadsList )
    }
  } )

  return (
    <>
      { loading && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4 mt-4" /> }
      { !loading && ( recentQuestions.length > 0 ) &&
        <>
          <div className="text-xl text-blue-header font-bold w-full">{ header ?? 'Your previously asked questions:' }</div>
          { recentQuestions.map( ( question: any, index: any ): any =>
                                   <Link to={ `/forum/${ question.id }` } key={ index } className="flex overflow-hidden p-2 xl:p-4 shadow-soft rounded-2xl mt-3 2xl:mt-6">
                                     <div className="rounded-xl">
                                       <UserAvatar user={ user ?? question.author } minSize={ 16 } maxSize={ 16 } />
                                     </div>
                                     <div className="flex flex-col justify-center ml-4">
                                       <div className="text-blue-input font-bold">{ question.title }</div>
                                       <div className="text-grey-input">{ format( parseISO( question.created_at ), 'do MMMM yyyy' ) }</div>
                                     </div>
                                   </Link> ) }
        </>
      }
      { error &&
        <div className="flex flex-col w-full bg-white rounded-xl p-4 lg:p-5">
          <b>Error occurred while fetching Recent Questions:</b>
          { error.graphQLErrors.map( ( { message }: any, i: number ): any => (
            <div key={ i } className="flex w-full"><span>{ message }</span></div>
          ) ) }
        </div>
      }
    </>
  )
}

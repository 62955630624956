import { useQuery }                 from '@apollo/client'
import { useState }                 from 'react'
import { GET_FORUM_TOP_USERS_LIST } from '../../graphql/user'
import { LoadingEllipsis }          from '../Loaders'
import CommunityForumUserInfo       from './CommunityForumUserInfo'

export default function CommunityForumTopUsers( props: any ): JSX.Element {
  const { limit }                         = props
  const [ topUsersList, setTopUsersList ] = useState( [] )

  const { loading, error } = useQuery( GET_FORUM_TOP_USERS_LIST, {
    variables:   { input: { limit } },
    onCompleted: ( { forumTopUsersList }: any ): any => setTopUsersList( forumTopUsersList )
  } )

  return (
    <>
      { loading && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4 mt-4" /> }
      { !loading && ( topUsersList.length > 0 ) &&
        <>
          <div className="text-xl text-blue-header font-bold w-full">Top { limit } users:</div>
          { topUsersList.map( ( user: any, index: any ): any => <CommunityForumUserInfo compact list user={ user } key={ index } /> ) }
        </>
      }
      { error &&
        <div className="flex flex-col w-full bg-white rounded-xl p-4 lg:p-5">
          <b>Error occurred while fetching Top Users List:</b>
          { error.graphQLErrors.map( ( { message }: any, i: number ): any => (
            <div key={ i } className="flex w-full"><span>{ message }</span></div>
          ) ) }
        </div>
      }
    </>
  )
}

import { gql } from '@apollo/client'

const ForumCategoryFragment: any = gql`
    fragment ForumCategoryData on ForumCategory {
        id
        slug
        name
    }
`

const ForumRankFragment: any = gql`
    fragment ForumRankData on ForumRank {
        id
        level
        threshold
        name
        description
        image
    }
`

const ForumAttachmentFragment: any = gql`
    fragment ForumAttachmentData on ForumAttachment {
      id
      thread_id
      reply_id
      source
      type
      file_type
      file_ext
      name
      url
    }
`

const AuthorFragment: any = gql`
    fragment AuthorData on User {
        id
        roi_id
        avatar_url
        name
        email
        admin
        forum_points
        forum_rank_id
        account_type_id
    }
`

const ReplyLikeFragment: any = gql`
  fragment ReplyLikeData on ForumReplyLike {
    id
    thread_id
    reply_id
    user_id
    is_liked
    created_at
    updated_at
  }
`

const ForumThreadReplyFragment: any = gql`
    fragment ForumThreadReplyData on ForumThreadReply {
        id
        thread_id
        author {
          ...AuthorData
        }
        author_id
        parent_id
        body
        likes {
          ...ReplyLikeData
        }
        attachments
        deleted_at
        created_at
        updated_at
    }
    ${ AuthorFragment }
    ${ ReplyLikeFragment }
`

const ForumThreadFragment: any = gql`
    fragment ForumThreadData on ForumThread {
        id
        title
        slug
        body
        best_reply_id
        category {
          ...ForumCategoryData
        }
        category_id
        attachments
        forum_thread_users_notification
        author {
          ...AuthorData
        }
        author_id
        views
        sticky
        resolved
        deleted_at
        created_at
        updated_at
    }
    ${ ForumCategoryFragment }
    ${ AuthorFragment }
`

const ForumThreadUsersNotificationFragment: any = gql`
  fragment ForumThreadUsersNotificationData on ForumThreadUsersNotification {
    id
    thread_id
    user_id
    enabled
    created_at
    updated_at
  }
`

const ForumThreadListFragment: any = gql`
    fragment ForumThreadListData on ForumThread {
        id
        title
        slug
        category_id
        author {
          name
        }
        forum_thread_users_notification
        author_id
        last_reply
        replies_count
        replies_liked_count
        views
        sticky
        resolved
        deleted_at
        created_at
        updated_at
    }
`

const LinksFragment: any = gql`
    fragment LinksData on PaginatorLinks {
        totalResults
        totalPages
        page
        perPage
        prevPage
        nextPage
    }
`

export const TOGGLE_REPLY_LIKE: any = gql`
  mutation ToggleReplyLike($input: MutationToggleReplyLikeInput!) {
    toggleReplyLike(input: $input) {
      __typename
      ... on FieldError {
        field
        message
      }
      ... on MutationToggleReplyLikeSuccess {
        data {
          ...ReplyLikeData
        }
      }
    }
  }
${ ReplyLikeFragment }
`

export const TOGGLE_FORUM_THREAD_USERS_NOTIFICATION: any = gql`
  mutation ToggleForumThreadUsersNotification($input: MutationToggleForumThreadUsersNotificationInput!) {
    toggleForumThreadUsersNotification(input: $input) {
      __typename
      ... on FieldError {
        field
        message
      }
      ... on MutationToggleForumThreadUsersNotificationSuccess {
        data {
          ...ForumThreadUsersNotificationData
        }
      }
    }
  }
  ${ ForumThreadUsersNotificationFragment }
`

export const GET_FORUM_CATEGORIES: any = gql`
    query ForumCategories {
        forumCategories {
            ...ForumCategoryData
        }
    }
    ${ ForumCategoryFragment }
`

export const CREATE_FORUM_CATEGORY: any = gql`
  mutation CreateForumCategory($input: MutationCreateForumCategoryInput!) {
    createForumCategory(input: $input) {
      __typename
      ... on FieldError {
        field
        message
      }
      ... on MutationCreateForumCategorySuccess {
        data {
          ...ForumCategoryData
        }
      }
    }
  }
  ${ ForumCategoryFragment }
`

export const UPDATE_FORUM_CATEGORY: any = gql`
  mutation UpdateForumCategory($input: MutationUpdateForumCategoryInput!) {
    updateForumCategory(input: $input) {
      __typename
      ... on FieldError {
        field
        message
      }
      ... on MutationUpdateForumCategorySuccess {
        data {
          ...ForumCategoryData
        }
      }
    }
  }
  ${ ForumCategoryFragment }
`

export const GET_FORUM_RANK_BY_ID: any = gql`
  query ForumRankById($input: QueryForumRankByIdInput!) {
    forumRankById(input: $input) {
      ...ForumRankData
    }
  }
  ${ ForumRankFragment }
`

export const GET_FORUM_RANKS: any = gql`
    query ForumRank {
        forumRanks {
            ...ForumRankData
        }
    }
    ${ ForumRankFragment }
`

export const CREATE_FORUM_RANK: any = gql`
  mutation CreateForumRank($input: MutationCreateForumRankInput!) {
    createForumRank(input: $input) {
      __typename
      ... on FieldError {
        field
        message
      }
      ... on MutationCreateForumRankSuccess {
        data {
          ...ForumRankData
        }
      }
    }
  }
  ${ ForumRankFragment }
`

export const UPDATE_FORUM_RANK: any = gql`
  mutation UpdateForumRank($input: MutationUpdateForumRankInput!) {
    updateForumRank(input: $input) {
      __typename
      ... on FieldError {
        field
        message
      }
      ... on MutationUpdateForumRankSuccess {
        data {
          ...ForumRankData
        }
      }
    }
  }
  ${ ForumRankFragment }
`

export const DELETE_FORUM_RANK: any = gql`
  mutation DeleteForumRank($input: MutationDeleteForumRankInput!) {
    deleteForumRank(input: $input) {
      __typename
      ... on FieldError {
        field
        message
      }
      ... on MutationDeleteForumRankSuccess {
        data
      }
    }
  }
`

export const GET_FORUM_THREAD_LIST: any = gql`
    query ForumThreadList($input: QueryForumThreadListInput!) {
        forumThreadList(input: $input) {
          data {
            ...ForumThreadListData
          }
          links {
            ...LinksData
          }
        }
    }
    ${ ForumThreadListFragment }
    ${ LinksFragment }
`

export const GET_FORUM_RECENT_THREADS_LIST: any = gql`
  query ForumRecentThreadsList($input: QueryForumRecentThreadsListInput!) {
    forumRecentThreadsList(input: $input) {
      ...ForumThreadListData
    }
  }
  ${ ForumThreadListFragment }
`

export const CREATE_FORUM_ATTACHMENT: any = gql`
  mutation CreateForumAttachment($input: MutationCreateForumAttachmentInput!) {
    createForumAttachment(input: $input) {
      __typename
      ... on FieldError {
        field
        message
      }
      ... on MutationCreateForumAttachmentSuccess {
        data {
          ...ForumAttachmentData
        }
      }
    }
  }
  ${ ForumAttachmentFragment }
`

export const GET_FORUM_THREAD_ATTACHMENTS: any = gql`
  query ForumAttachments($input: QueryForumAttachmentsInput!) {
    forumAttachments(input: $input) {
      data {
        ...ForumAttachmentData
      }
      links {
        ...LinksData
      }
    }
  }
  ${ ForumAttachmentFragment }
  ${ LinksFragment }
`

export const GET_FORUM_THREAD_BY_ID: any = gql`
    query ForumThreadById($input: QueryForumThreadByIdInput!) {
        forumThreadById(input: $input) {
          ...ForumThreadData
        }
    }
    ${ ForumThreadFragment }
`

export const GET_FORUM_THREAD_REPLIES: any = gql`
  query ForumThreadReplies($input: QueryForumThreadRepliesInput!) {
    forumThreadReplies(input: $input) {
      data {
        ...ForumThreadReplyData
      }
      links {
        ...LinksData
      }
    }
  }
  ${ ForumThreadReplyFragment }
  ${ LinksFragment }
`

export const GET_FORUM_THREADS_USER_REPLIES: any = gql`
  query ForumThreadUserReplies($input: QueryForumThreadUserRepliesInput!) {
    forumThreadUserReplies(input: $input) {
      data {
        thread {
          id
          title
          created_at
        }
        ...ForumThreadReplyData
      }
      links {
        ...LinksData
      }
    }
  }
  ${ ForumThreadReplyFragment }
  ${ LinksFragment }
`

export const GET_FORUM_THREAD_REPLY_BY_ID: any = gql`
  query ForumThreadReplyById($input: QueryForumThreadReplyByIdInput!) {
    forumThreadReplyById(input: $input) {
      ...ForumThreadReplyData
    }
  }
  ${ ForumThreadReplyFragment }
`

export const CREATE_FORUM_THREAD: any = gql`
    mutation CreateForumThread($input: MutationCreateForumThreadInput!) {
      createForumThread(input: $input) {
        __typename
        ... on FieldError {
          field
          message
        }
        ... on MutationCreateForumThreadSuccess {
          data {
            ...ForumThreadData
          }
        }
      }
    }
    ${ ForumThreadFragment }
`

export const UPDATE_FORUM_THREAD: any = gql`
    mutation UpdateForumThread($input: MutationUpdateForumThreadInput!) {
      updateForumThread(input: $input) {
        __typename
        ... on FieldError {
          field
          message
        }
        ... on MutationUpdateForumThreadSuccess {
          data {
            ...ForumThreadData
          }
        }
      }
    }
    ${ ForumThreadFragment }
`

export const DELETE_FORUM_THREAD: any = gql`
    mutation DeleteForumThread($input: MutationDeleteForumThreadInput!) {
        deleteForumThread(input: $input) {
          __typename
          ... on FieldError {
            field
            message
          }
          ... on MutationDeleteForumThreadSuccess {
            data
          }
        }
    }
`

export const DELETE_FORUM_ATTACHMENT: any = gql`
    mutation DeleteForumAttachment($input: MutationDeleteForumAttachmentInput!) {
        deleteForumAttachment(input: $input) {
          __typename
          ... on FieldError {
            field
            message
          }
          ... on MutationDeleteForumAttachmentSuccess {
            data
          }
        }
    }
`

export const CREATE_FORUM_THREAD_REPLY: any = gql`
  mutation CreateForumReply($input: MutationCreateForumThreadReplyInput!) {
    createForumThreadReply(input: $input) {
      __typename
      ... on FieldError {
        field
        message
      }
      ... on MutationCreateForumThreadReplySuccess {
        data {
          ...ForumThreadReplyData
        }
      }
    }
  }
  ${ ForumThreadReplyFragment }
`

export const UPDATE_FORUM_THREAD_REPLY: any = gql`
  mutation UpdateForumThreadReply($input: MutationUpdateForumThreadReplyInput!) {
    updateForumThreadReply(input: $input) {
      __typename
      ... on FieldError {
        field
        message
      }
      ... on MutationUpdateForumThreadReplySuccess {
        data {
          ...ForumThreadReplyData
        }
      }
    }
  }
  ${ ForumThreadReplyFragment }
`

export const REASSIGN_USERS_POINTS: any = gql`
    mutation ReassignUsersPoints {
        reassignUsersPoints {
            __typename
            ... on FieldError {
                field
                message
            }
            ... on MutationReassignUsersPointsSuccess {
                data
            }
        }
    }
`

export const DELETE_FORUM_THREAD_REPLY: any = gql`
  mutation DeleteForumThreadReply($input: MutationDeleteForumThreadReplyInput!) {
    deleteForumThreadReply(input: $input) {
      __typename
      ... on FieldError {
        field
        message
      }
      ... on MutationDeleteForumThreadReplySuccess {
        data
      }
    }
  }
`

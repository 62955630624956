import { useMutation, useQuery }                                   from '@apollo/client'
import { ArrowUturnLeftIcon }                                      from '@heroicons/react/24/solid'
import { useDispatch, useSelector }                                from 'react-redux'
import { useNavigate }                                             from 'react-router-dom'
import { Button }                                                  from '../../components'
import { LoadingEllipsis }                                         from '../../components/Loaders'
import SettingsAccountTypeForm                                     from '../../components/Settings/SettingsAccountTypeForm'
import { setToasts, showToaster }                                  from '../../features/toaster/toasterSlice'
import { setAccountTypes }                                         from '../../features/user/currentUserSlice'
import { ACCOUNT_TYPES, CREATE_ACCOUNT_TYPE, UPDATE_ACCOUNT_TYPE } from '../../graphql/account_type'
import MainLayout                                                  from '../../layouts/MainLayout'

export default function SettingsUsersAccountTypes(): JSX.Element {
  const navigate: any     = useNavigate()
  const dispatch: any     = useDispatch()
  const currentUser: any  = useSelector( ( state: any ): any => state.currentUser.user )
  const accountTypes: any = useSelector( ( state: any ): any => state.currentUser.accountTypes )

  const { loading, refetch } = useQuery( ACCOUNT_TYPES, {
    onCompleted: ( { accountTypes }: any ): void => dispatch( setAccountTypes( accountTypes ) )
  } )

  const [ createAccountType, { loading: creating } ] = useMutation( CREATE_ACCOUNT_TYPE, {
    onCompleted: (): void => dispatchMessage( 'success', 'Account Type created!' ),
    onError:     (): void => dispatchMessage( 'error', 'Creating failed. Please try again' )
  } )

  const [ updateAccountType, { loading: updating } ] = useMutation( UPDATE_ACCOUNT_TYPE, {
    onCompleted: (): void => dispatchMessage( 'success', 'Account Type updated!' ),
    onError:     (): void => dispatchMessage( 'error', 'Update failed. Please try again' )
  } )

  const dispatchMessage: any = ( type: string, message: string ): void => {
    dispatch( setToasts( [ { id: '1', type, message } ] ) )
    dispatch( showToaster( true ) )
  }

  const handleFormSubmit: any = async ( name: string, id: string ): Promise<void> => {
    const formData: any = { name }

    if ( id.length ) {
      formData.id = id
      await updateAccountType( { variables: { input: formData } } )
    } else {
      await createAccountType( { variables: { input: formData } } )
    }

    await refetch()
  }

  return (
    <MainLayout>
      <div className="max-w-screen-3xl mx-auto w-full">
        <div className="flex-grow xl:p-8 p-3 space-y-4 xl:space-y-8">
          { !currentUser && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4 mt-4 w-full" /> }
          { currentUser?.admin &&
            <>
              <div className="w-24 my-4">
                <Button
                  label="Back"
                  blue
                  onClick={ (): any => navigate( -1 ) }
                  icon={ <ArrowUturnLeftIcon className="w-4 h-4" /> }
                />
              </div>

              { ( loading || creating || updating ) && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }

              { ( !loading && !creating && !updating ) &&
                <>
                  { accountTypes?.map( ( accountType: any, index: any ): any =>
                                         <SettingsAccountTypeForm
                                           handleFormSubmit={ handleFormSubmit }
                                           accountType={ accountType }
                                           key={ index }
                                         /> ) }

                  <SettingsAccountTypeForm handleFormSubmit={ handleFormSubmit } />
                </>
              }
            </>
          }
          { !currentUser?.admin &&
            <div className="row flex flex-col items-center w-full bg-white rounded-3xl p-3 xl:p-4">
              Access Denied!
            </div>
          }
        </div>
      </div>
    </MainLayout>
  )
}

import { configureStore }   from '@reduxjs/toolkit'
import selectedAvatarSlice  from '../features/avatar/selectedAvatarSlice'
import documentsSlice       from '../features/documents/documentsSlice'
import emailComposeReducer  from '../features/email/composeSlice'
import foldersSlice         from '../features/email/foldersSlice'
import noteSlice            from '../features/email/noteSlice'
import subjectRenameSlice   from '../features/email/subjectRenameSlice'
import trashFolderIdReducer from '../features/email/trashFolderIdSlice'
import communityForum       from '../features/forum/communityForumSlice'
import integrationReducer   from '../features/integration/integrationSlice'
import toasterReducer       from '../features/toaster/toasterSlice'
import currentTenantReducer from '../features/user/currentTenantSlice'
import currentUserReducer   from '../features/user/currentUserSlice'
import userViewReducer      from '../features/user/userViewSlice'

export const store = configureStore( {
  reducer: {
    toaster:        toasterReducer,
    currentUser:    currentUserReducer,
    userView:       userViewReducer,
    currentTenant:  currentTenantReducer,
    emailCompose:   emailComposeReducer,
    integration:    integrationReducer,
    trashFolderId:  trashFolderIdReducer,
    folders:        foldersSlice,
    documents:      documentsSlice,
    subjectRename:  subjectRenameSlice,
    note:           noteSlice,
    selectedAvatar: selectedAvatarSlice,
    communityForum: communityForum
  }
} )
import { useLazyQuery }         from '@apollo/client'
import { useEffect, useState }  from 'react'
import { useSelector }          from 'react-redux'
import { useParams }            from 'react-router-dom'
import { LoadingEllipsis }      from '../../components/Loaders'
import SettingsForumRankForm    from '../../components/Settings/SettingsForumRankForm'
import { GET_FORUM_RANK_BY_ID } from '../../graphql/communityForum'
import MainLayout               from '../../layouts/MainLayout'

export default function SettingsForumRank(): JSX.Element {
  const { rank_id }                 = useParams()
  const currentUser: any            = useSelector( ( state: any ): any => state.currentUser.user )
  const [ forumRank, setForumRank ] = useState( null )

  const [ getRank, { loading } ] = useLazyQuery( GET_FORUM_RANK_BY_ID, {
    onCompleted: ( { forumRankById }: any ): void => setForumRank( forumRankById )
  } )

  const fetchRank: any = async (): Promise<void> => {
    await getRank( { variables: { input: { id: rank_id } } } )
  }

  useEffect( (): any => {
    let shouldFetch: boolean = true

    if ( shouldFetch && rank_id ) {
      fetchRank()
    }

    return (): void => {
      shouldFetch = false
    }
  }, [ rank_id ] )

  return (
    <MainLayout>
      <div className="max-w-screen-3xl mx-auto w-full">
        <div className="flex-grow xl:p-8 p-3 space-y-4 xl:space-y-8">
          { ( loading || !currentUser ) && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4 mt-4 w-full" /> }
          { !loading && currentUser?.admin &&
            <>
              { forumRank &&
                <SettingsForumRankForm forumRank={ forumRank } />
              }
              { !forumRank &&
                <SettingsForumRankForm />
              }
            </>
          }
          { !currentUser?.admin &&
            <div className="row flex flex-col items-center w-full bg-white rounded-3xl p-3 xl:p-4">
              Access Denied!
            </div>
          }
        </div>
      </div>
    </MainLayout>
  )
}

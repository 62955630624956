import { useLazyQuery, useMutation }                                                                              from '@apollo/client'
import { TrashIcon, XCircleIcon }                                                                                 from '@heroicons/react/20/solid'
import { UploadCloud }                                                                                            from 'lucide-react'
import { useEffect, useRef, useState }                                                                            from 'react'
import Dropzone                                                                                                   from 'react-dropzone'
import { useDispatch, useSelector }                                                                               from 'react-redux'
import { Button }                                                                                                 from '../../components'
import { Editor }                                                                                                 from '../../components/Editor/Editor'
import { Image }                                                                                                  from '../../components/Image'
import { LoadingEllipsis }                                                                                        from '../../components/Loaders/Loaders'
import { setForumListRefetch, setMentionsUsersList, setThreadAttachmentsRefetch }                                 from '../../features/forum/communityForumSlice'
import { setToasts, showToaster }                                                                                 from '../../features/toaster/toasterSlice'
import { CREATE_FORUM_ATTACHMENT, CREATE_FORUM_THREAD_REPLY, DELETE_FORUM_ATTACHMENT, UPDATE_FORUM_THREAD_REPLY } from '../../graphql/communityForum'
import { GET_MENTIONS_USERS_LIST }                                                                                from '../../graphql/user'
import { assetFilePath }                                                                                          from '../../utilities'
import Api                                                                                                        from '../../utilities/axios'

export default function CommunityForumThreadReplyForm( props: any ): JSX.Element {
  const { forumThreadReply, forumThreadId, parentId, setReplyFormOpen, refetch } = props
  const uploadApiUrl: string                                                     = process.env.REACT_APP_UPLOAD_HOST
  const appCdnUrl: string                                                        = process.env.REACT_APP_CDN + ( process.env.REACT_APP_CDN.slice( -1 ) === '/' ? '' : '/' )
  const dispatch: any                                                            = useDispatch()
  const currentTenant: any                                                       = useSelector( ( state: any ): any => state.currentTenant.tenant )
  const mentionsUsersList: any                                                   = useSelector( ( state: any ): any => state.communityForum.mentionsUsersList )
  const [ loading, setLoading ]                                                  = useState( false )
  const [ submitting, setSubmitting ]                                            = useState( false )
  const [ body, setBody ]                                                        = useState( forumThreadReply?.body ?? '<p></p>' )
  const [ filesToUpload, setFilesToUpload ]                                      = useState( [] )
  const filePreviewContainer: any                                                = useRef<HTMLElement | null>( null )
  const formEditor: any                                                          = useRef<HTMLElement | null>( null )

  const [ fetchMentionsUsersList, { loading: loadingMentions } ] = useLazyQuery( GET_MENTIONS_USERS_LIST, {
    notifyOnNetworkStatusChange: true,
    onCompleted:                 ( { mentionsUsersList }: any ): void => dispatch( setMentionsUsersList( mentionsUsersList ) )
  } )

  const [ createForumThreadReply, { loading: creating } ] = useMutation( CREATE_FORUM_THREAD_REPLY, {
    onCompleted: (): void => {
      dispatchMessage( 'success', 'Reply added!' )
      setReplyFormOpen( null )
      if ( !filesToUpload.length ) {
        refetch( true )
      }
      dispatch( setForumListRefetch( true ) )
    },
    onError:     (): void => dispatchMessage( 'error', 'Reply failed. Please try again' )
  } )

  const [ createForumAttachment ] = useMutation( CREATE_FORUM_ATTACHMENT, {
    onCompleted: (): void => handleAttachmentSuccessAction( 'Attachment uploaded!' ),
    onError:     (): void => dispatchMessage( 'error', 'Attachment NOT uploaded!' )
  } )

  const [ updateForumThreadReply, { loading: updating } ] = useMutation( UPDATE_FORUM_THREAD_REPLY, {
    onCompleted: (): void => {
      dispatchMessage( 'success', 'Reply updated!' )
      setReplyFormOpen( null )
      if ( !filesToUpload.length ) {
        refetch( false )
      }
    },
    onError:     (): void => dispatchMessage( 'error', 'Update failed. Please try again' )
  } )

  const [ deleteForumAttachment, { loading: deletingAttachment } ] = useMutation( DELETE_FORUM_ATTACHMENT, {
    onCompleted: ( { deleteForumAttachment }: any ): any => {
      if ( deleteForumAttachment.__typename === 'FieldError' ) {
        dispatchMessage( 'error', 'Attachment not deleted. Server error.' )
      } else {
        handleAttachmentSuccessAction( 'Attachment deleted successfully' )
      }
    },
    onError:     (): void => dispatchMessage( 'error', 'Attachment not deleted. Please try again' )
  } )

  const handleAttachmentSuccessAction: any = ( message: string ): void => {
    refetch()
    dispatchMessage( 'success', message )
    dispatch( setThreadAttachmentsRefetch( true ) )
  }

  const handleSubmit: any = async (): Promise<void> => {
    if ( submitting || !body.replace( /(<([^>]+)>)/gi, '' ).length ) return

    setSubmitting( true )

    const formData: any          = { body }
    const mentionsUsersList: any = []
    const mentionsHtmlList: any  = document.querySelectorAll( '.lexical-mention' )

    if ( mentionsHtmlList.length ) {
      mentionsHtmlList.forEach( ( mention: any ): void => {
        mentionsUsersList.push( {
                                  user_id:    mention.dataset.lexicalMentionId,
                                  user_email: mention.dataset.lexicalMentionEmail,
                                  user_name:  mention.dataset.lexicalMentionName
                                } )
      } )
    }

    if ( mentionsUsersList.length ) {
      formData.mentions = mentionsUsersList
    }

    let replyId: string | null = forumThreadReply ? forumThreadReply.id : null

    if ( forumThreadReply ) {
      formData.id = replyId
      await updateForumThreadReply( { variables: { input: formData } } )
    } else {
      formData.thread_id = forumThreadId
      formData.parent_id = parentId ?? null

      const { data } = await createForumThreadReply( { variables: { input: formData } } )

      if ( data?.createForumThreadReply?.data?.id ) {
        replyId = data.createForumThreadReply.data.id
      }
    }

    setTimeout( async (): Promise<void> => {
      if ( replyId ) {
        await uploadFiles( replyId )
        setFilesToUpload( [] )
      }

      setSubmitting( false )
    }, 500 )
  }

  const handleSetFiles: any = ( files: any ): void => {
    const initialFilesQuantity: number = files.length
    const loadableFiles: any           = filesToUpload.filter( ( f: any ): any => !f.deleted )
    files                              = files.filter( ( f: any ): any => f.size < 10485760 )

    if ( files.length !== initialFilesQuantity ) {
      alert( 'Max file size is 10MB. Some of files has been removed.' )
    }

    if ( ( loadableFiles.length >= 5 ) || ( forumThreadReply?.attachments.length >= 5 ) ) {
      return alert( 'Limit of 5 files reached' )
    }

    if ( forumThreadReply ) {
      if ( ( loadableFiles.length + files.length ) > ( 5 - forumThreadReply.attachments.length ) ) {
        files.splice( Math.max( 0, ( 5 - forumThreadReply.attachments.length ) ) )
      }
    } else {
      if ( loadableFiles.length + files.length > 5 ) {
        files.splice( Math.max( 0, 5 - loadableFiles.length ) )
      }
    }

    setFilesToUpload( [ ...filesToUpload, ...files ] )
  }

  const handleRemoveFileToUpload: any = ( index: number ): void => {
    setFilesToUpload( [ ...filesToUpload.map( ( f: any, i: number ): any => {
      if ( index === i ) {
        f.deleted = true
      }

      return f
    } ) ] )
  }

  const handleDeleteAttachment: any = async ( e: any ): Promise<void> => {
    e.preventDefault()

    if ( !forumThreadReply?.attachment_url || deletingAttachment ) return

    if ( window.confirm( 'Are you sure you want to delete this attachment?' ) ) {
      deleteForumAttachment( { variables: { input: { id: forumThreadReply.id } } } )
    }
  }

  const dispatchMessage: any = ( type: string, message: string ) => {
    dispatch( setToasts( [ { id: '1', type, message } ] ) )
    dispatch( showToaster( true ) )
  }

  const uploadFiles: any = async ( replyId: string ): Promise<void> => {
    if ( !filesToUpload.length ) return

    try {
      for await ( const file of filesToUpload ) {
        if ( !file.deleted ) {
          await uploadAttachment( replyId, file )
        }
      }
    } catch ( e ) {
      dispatchMessage( 'error', 'Upload failed' )
    }
  }

  const uploadAttachment: any = async ( replyId: string, file: any ): Promise<void> => {
    setLoading( true )

    const formData: FormData = new FormData()

    formData.append( 'file', file )
    formData.append( 'is_public', 'true' )
    formData.append( 'folder', currentTenant.uuid + '/forum/attachments/' + replyId )

    try {
      const { data } = await Api( uploadApiUrl, 'multipart/form-data' ).post( '/', formData )

      if ( data ) {
        const filename: any      = file.name.replace( /\.[^/.]+$/, '' )
        const fileTypeArray: any = file.type.split( '/' )

        await createForumAttachment( {
                                       variables: {
                                         input: {
                                           'thread_id': forumThreadId,
                                           'reply_id':  replyId,
                                           'source':    'reply',
                                           'type':      fileTypeArray[ 0 ],
                                           'file_ext':  fileTypeArray[ fileTypeArray.length - 1 ],
                                           'file_type': file.type,
                                           'name':      filename.length ? filename : 'no_name',
                                           'url':       data[ 0 ].path
                                         }
                                       }
                                     } )
      }
    } catch ( error ) {
      console.error( `Error uploading file (${ file.name }):`, error )
    }

    setLoading( false )
  }

  const previewFile: any = ( file: any, index: number ): any => {
    if ( filePreviewContainer.current && file.type.startsWith( 'image/' ) ) {
      const preview: any = filePreviewContainer.current.querySelector( `.image-preview-${ index }` )
      const reader: any  = new FileReader()

      if ( preview ) {
        reader.addEventListener( 'load', (): any => preview.src = reader.result, false )
        reader.readAsDataURL( file )
      }
    }
  }

  useEffect( (): any => {
    let shouldScrollToEditor: boolean = true

    if ( !loading ) {
      if ( shouldScrollToEditor && formEditor.current ) {
        formEditor.current.parentElement.parentElement.scrollIntoView()
      }
    }

    return (): void => {
      shouldScrollToEditor = false
    }
  }, [ forumThreadReply, loading ] )

  useEffect( (): any => {
    let shouldFetch: boolean = true

    if ( shouldFetch && !mentionsUsersList.length ) {
      fetchMentionsUsersList()
    }

    return (): void => {
      shouldFetch = false
    }
  }, [ mentionsUsersList ] )

  return (
    <div className="flex flex-col md:flex-row w-full space-y-4 md:space-y-0 md:space-x-6">
      { ( updating || creating || loading || loadingMentions ) && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }
      { ( !updating && !creating && !loading && !loadingMentions ) &&
        <>
          <div ref={ formEditor } className="flex flex-wrap items-center p-6 pt-2 bg-grey-light-secondary rounded-2xl w-full md:w-2/3 forum-lexical">
            <Editor
              /* @ts-ignore */
              initialHtml={ body }
              mentionsUsersList={ mentionsUsersList }
              actionNode={ ( content: any ): void => setBody( content ) }
              placeholder="Type Something..."
              isRequired
              isForumEditor
            />
          </div>

          <div className="flex flex-col w-full md:w-1/3">
            <div className="text-blue-header font-medium mb-4">Attachments</div>
            <div className="flex flex-col w-full">
            <div className="md:min-h-[218px] cursor-pointer no-select p-6 border bg-grey-light-secondary rounded-2xl">
              <Dropzone onDrop={ ( attachedFiles: any ): any => handleSetFiles( attachedFiles ) } multiple={ true }>
                { ( { getRootProps, getInputProps }: any ): any => (
                  <div className="flex justify-center items-center h-full">
                    <div { ...getRootProps() }>
                      <input { ...getInputProps() } />

                      <div className="flex items-center justify-center flex-col space-y-2">
                        <UploadCloud className="w-12 h-12" />
                        <p className="text-sm font-medium">Drag 'n' drop or click to select file</p>
                      </div>
                    </div>
                  </div>
                ) }
              </Dropzone>
            </div>

              { ( filesToUpload.length > 0 ) &&
                <div ref={ filePreviewContainer } className="mt-4 pb-2 flex items-center flex-wrap gap-3">
                  { filesToUpload.map( ( file: any, index: number ): any => {
                    return ( !file.deleted &&
                             <div className="flex flex-col w-16 mr-2 h-auto relative rounded-md relative" key={ index }>
                               <button
                                 title="Remove"
                                 className="absolute -right-1 -top-1 bg-white shadow rounded-full"
                                 onClick={ (): any => handleRemoveFileToUpload( index ) }
                               >
                                 <XCircleIcon className="w-4 h-4" />
                               </button>

                               <Image
                                 klass={ `image-preview-${ index }` }
                                 src={ previewFile( file, index ) }
                                 defaultSrc={ assetFilePath( file.type.split( '/' ).pop() ) }
                               />

                               <small className="text-xs truncate w-16">{ file.name }</small>
                             </div> )
                  } ) }
                </div>
              }

              <div className="flex flex-wrap items-center mt-4 2xl:mt-8">
              <div className="flex w-full">
                <Button yellow label="Send reply" onClick={ handleSubmit } />
              </div>

                {/*<div className="flex pt-5 ml-2">*/ }
                {/*  <Button type="button" label="Cancel" onClick={ (): any => setReplyFormOpen( null ) } secondary />*/ }
                {/*</div>*/ }
            </div>
          </div>

            { ( forumThreadReply?.attachments?.length > 0 ) &&
              <div className="flex flex-wrap items-center w-full mb-3">
                <div className="w-full border-b my-4">Media:</div>

                <div className="grid grid-cols-4 lg:grid-cols-8 gap-2 lg:gap-4 w-full">
                  { forumThreadReply.attachments.map( ( attachment: any ): any =>
                                                        <div className="relative flex items-center justify-center border w-full" key={ attachment.id }>
                                                          <button
                                                            title="Delete"
                                                            className="absolute -right-2 -top-2 bg-red-500 text-white rounded-full"
                                                            onClick={ (): any => handleDeleteAttachment( attachment ) }
                                                            disabled={ deletingAttachment }
                                                          >
                                                            <TrashIcon className="w-6 h-6 p-1" />
                                                          </button>

                                                          <div className="flex flex-col">
                                                            <div className="flex items-center justify-center w-16 h-16 lg:w-24 lg:h-24">
                                                              <Image
                                                                klass="max-w-16 max-h-16 lg:max-w-24 lg:max-h-24"
                                                                src={ appCdnUrl + attachment.url }
                                                                alt={ attachment.name }
                                                                defaultSrc={ assetFilePath( attachment.file_ext ) }
                                                              />
                                                            </div>
                                                            <small className="text-xs truncate w-16 lg:w-24">{ attachment.name }</small>
                                                          </div>
                                                        </div> ) }
                </div>
              </div>
            }
          </div>
        </>
      }
    </div>
  )
}

import { useLazyQuery }                               from '@apollo/client'
import { EnvelopeIcon, MapPinIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { useEffect, useState }                        from 'react'
import { useDispatch, useSelector }                   from 'react-redux'
import { Link, useParams }                            from 'react-router-dom'
import { LoadingEllipsis }                            from '../../components/Loaders'
import UserAvatar                                     from '../../components/User/UserAvatar'
import UserProfileCommunityForumQuestionsTab          from '../../components/User/UserProfileCommunityForumQuestionsTab'
import UserProfileCommunityForumRepliesTab            from '../../components/User/UserProfileCommunityForumRepliesTab'
import { setForumCategories }                         from '../../features/forum/communityForumSlice'
import { setAccountTypes }                            from '../../features/user/currentUserSlice'
import { ACCOUNT_TYPES }                              from '../../graphql/account_type'
import { GET_FORUM_CATEGORIES }                       from '../../graphql/communityForum'
import { GET_USER_BY_ID }                             from '../../graphql/user'
import MainLayout                                     from '../../layouts/MainLayout'
// import UserProfileDocumentsDownloadLogTab                             from '../../components/User/UserProfileDownloadLogTab'

export default function UserProfile(): JSX.Element {
  const { user_id }                     = useParams()
  const dispatch: any                   = useDispatch()
  const currentUser: any                = useSelector( ( state: any ): any => state.currentUser.user )
  const forumCategories: any            = useSelector( ( state: any ): any => state.communityForum.categories )
  const accountTypes: any               = useSelector( ( state: any ): any => state.currentUser.accountTypes )
  const [ user, setUser ]               = useState( null )
  const [ accountType, setAccountType ] = useState( null )
  const [ activeTab, setActiveTab ]     = useState( 'forum-questions' )

  const navStyle: string = 'w-full h-full px-3 py-1 flex items-center whitespace-nowrap justify-center bg-blue-secondary'

  const [ getForumCategories, { loading: loadingCategories } ] = useLazyQuery( GET_FORUM_CATEGORIES, {
    onCompleted: ( { forumCategories }: any ): void => dispatch( setForumCategories( forumCategories ) )
  } )

  const [ getUserById, { loading: loadingUser } ] = useLazyQuery( GET_USER_BY_ID, {
    onCompleted: ( { userById }: any ): void => {
      setUser( userById )
      accountTypes && setAccountType( accountTypes.find( ( at: any ): any => at.id === userById.account_type_id ) )
    }
  } )

  const [ getAccountTypes ] = useLazyQuery( ACCOUNT_TYPES, {
    onCompleted: ( { accountTypes }: any ): void => dispatch( setAccountTypes( accountTypes ) )
  } )

  useEffect( (): any => {
    let shouldFetch: boolean = true

    if ( shouldFetch && !accountTypes?.length ) {
      getAccountTypes()
    }

    return (): void => {
      shouldFetch = false
    }
  }, [ accountTypes ] )

  useEffect( (): any => {
    let shouldFetch: boolean = true

    if ( shouldFetch && !forumCategories.length ) {
      getForumCategories()
    }

    return (): void => {
      shouldFetch = false
    }
  }, [ forumCategories ] )

  useEffect( (): any => {
    let shouldFetch: boolean = true

    if ( shouldFetch && user_id ) {
      getUserById( { variables: { input: { id: user_id } } } )
    } else {
      setUser( currentUser )
      accountTypes && setAccountType( accountTypes.find( ( at: any ): any => at.id === currentUser.account_type_id ) )
    }

    return (): void => {
      shouldFetch = false
    }
  }, [ currentUser, user_id ] )

  return (
    <MainLayout>
      <div className="max-w-screen-3xl mx-auto w-full">
        <div className="flex-grow xl:p-8 p-3 space-y-4 xl:space-y-8">
          { ( loadingCategories || loadingUser ) && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4 mt-4 w-full" /> }
          { ( !loadingCategories && !loadingUser ) && user &&
            <>
              <div className="row flex items-center w-full bg-white rounded-xl p-4 xl:p-5 relative">
                { currentUser && ( user.id === currentUser.id ) &&
                  <Link
                    to={ '/profile/edit' }
                    className="absolute top-4 xl:top-5 right-4 xl:right-5 right-0 text-blue-secondary hover:text-blue-secondary-darker"
                  >
                    <PencilSquareIcon className="w-4 xl:w-5 h-4 xl:h-5" />
                  </Link>
                }

                <div className="col w-24 flex flex-col mr-4">
                  <div className="avatar rounded-full">
                    <UserAvatar user={ user } />
                  </div>
                </div>

                <div className="col flex flex-col w-full">
                  <h4 className="card-title mb-0 text-xl text-medium">{ user.name }</h4>

                  { accountType &&
                    <h5 className="card-title">{ accountType.name }</h5>
                  }

                  <div className="flex text-muted text-sm text-gray-500">
                    <div className="mt-2">
                      <a href={ `mailto:${ user.email }` } className="flex items-center justify-center">
                        <EnvelopeIcon className="w-5 h-5 mr-1" />
                        <span className="text-blue-500 hover:text-blue-700">{ user.email }</span>
                      </a>
                    </div>

                    { user.location &&
                      <div className="ml-2">
                        <MapPinIcon className="w-5 h-5" />
                        <span className="text-blue-500">{ user.location.name }</span>
                      </div>
                    }
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col flex justify-end w-full">
                  <div className="flex rounded-full text-sm font-medium text-white overflow-hidden">
                    <button
                      className={ navStyle }
                      onClick={ (): void => setActiveTab( 'forum-questions' ) }
                    >
                      <span>Forum Questions</span>
                    </button>

                    <button
                      className={ navStyle }
                      onClick={ (): void => setActiveTab( 'forum-replies' ) }
                    >
                      <span>Forum Replies</span>
                    </button>

                    {/*<button*/ }
                    {/*  type="button"*/ }
                    {/*  className={ navStyle }*/ }
                    {/*  onClick={ (): void => setActiveTab( 'download-log' ) }*/ }
                    {/*>*/ }
                    {/*  <span>Download Log</span>*/ }
                    {/*</button>*/ }
                  </div>
                </div>
              </div>

              <div className="row flex flex-col items-center w-full bg-white rounded-3xl p-3 xl:p-4">
                <div className="col flex flex-col w-full">
                  { ( activeTab === 'forum-questions' ) &&
                    <UserProfileCommunityForumQuestionsTab user={ user } forumCategories={ forumCategories } />
                  }
                  { ( activeTab === 'forum-replies' ) && <UserProfileCommunityForumRepliesTab user={ user } /> }
                  {/*{ ( activeTab === 'download-log' ) && <UserProfileDocumentsDownloadLogTab /> }*/ }
                </div>
              </div>
            </>
          }
        </div>
      </div>
    </MainLayout>
  )
}

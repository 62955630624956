import { useMutation }                                from '@apollo/client'
import { DocumentIcon }                               from '@heroicons/react/24/outline'
import { ArrowDownTrayIcon, TrashIcon }               from '@heroicons/react/24/solid'
import { UndoIcon }                                   from 'lucide-react'
import { useState }                                   from 'react'
import { useDispatch, useSelector }                   from 'react-redux'
import TextBadge                                      from '../../../components/Badge/TextBadge'
import PreviewDocumentModal                           from '../../../components/Modal/PreviewDocumentModal'
import { setToasts, showToaster }                     from '../../../features/toaster/toasterSlice'
import { DELETE_DOCUMENT_FILE, RENAME_DOCUMENT_FILE } from '../../../graphql/document'
import { CREATE_DOWNLOAD_LOG }                        from '../../../graphql/downloadLog'
import { formatBytes, prefetchFileAndDownload }       from '../../../utilities'

interface FileTableItemProps
{
  canDelete?: boolean;
  itemDetails?: any;
  idx?: number;
  refetch: () => void;
}

export default function FileTableItem( props: FileTableItemProps ): JSX.Element {
  const { canDelete, itemDetails: item, idx, refetch } = props
  const [ newName, setNewName ]                        = useState( item.name )
  const currentUser: any                               = useSelector( ( state: any ): any => state.currentUser.user )
  const [ renaming, setRenaming ]                      = useState( false )
  const dispatch: any                                  = useDispatch()

  const [ deleteFile ] = useMutation( DELETE_DOCUMENT_FILE, {
    onCompleted: (): void => handleSuccess(),
    onError:     (): void => dispatchMessage( 'error', 'File not deleted. Please try again' )
  } )

  const [ renameFile ] = useMutation( RENAME_DOCUMENT_FILE, {
    onCompleted: (): void => setRenaming( false ),
    onError:     (): void => dispatchMessage( 'error', 'File could not be renamed' )
  } )

  const [ createDownloadLog ] = useMutation( CREATE_DOWNLOAD_LOG )

  const handleDeleteItem: any = async (): Promise<void> => {
    if ( window.confirm( 'Are you sure you want to delete this file?' ) ) {
      await deleteFile( { variables: { input: { unique_id: item.unique_id } } } )
    }
  }

  const handleDownload: any = async ( e: any ): Promise<void> => {
    e.preventDefault()

    await prefetchFileAndDownload( item.path, item.name )

    await createDownloadLog( {
                               variables: {
                                 input: {
                                   document_unique_id: item.document_id,
                                   file_id:            item.id,
                                   file_unique_id:     item.unique_id,
                                   name:               item.name,
                                   type:               'document'
                                 }
                               }
                             } )
  }

  const handleSuccess: any = (): void => {
    dispatchMessage( 'success', 'File deleted successfully' )
    refetch()
  }

  const dispatchMessage: any = ( type: string, message: string ): void => {
    dispatch( setToasts( [ { id: '1', type, message } ] ) )
    dispatch( showToaster( true ) )
  }

  const handleRowClick: any = (): void => {
    if ( currentUser?.admin && !renaming ) {
      setRenaming( true )
    }
  }

  const undoRename: any = (): void => {
    setRenaming( false )
    setNewName( item.name )
  }

  const markup: any = (
    <>
     <td className="border-b border-gray-table-border p-3 px-0">
        <DocumentIcon className="w-5 h-5" />
      </td>
      <td title={ currentUser?.admin ? 'Click to rename' : '' } role="button" className="border-b border-gray-table-border p-3" onClick={ handleRowClick }>
        <div>
          { item.name } ({ formatBytes( item.size ) })
          { idx === 0 && <TextBadge classes="ml-2" color="blue-secondary" text="white">Current</TextBadge> }
        </div>
      </td>
      <td className="border-b border-gray-table-border p-3">
        <div className="flex">
          <PreviewDocumentModal file={ item } />
          { canDelete &&
            <button
              className="p-0 mr-3 float-right hover:text-red-600"
              color="default"
              onClick={ handleDeleteItem }
            >
              <TrashIcon className="w-4 h-4" />
            </button>
          }
          <button
            className="p-0 mr-1"
            color="default"
            onClick={ handleDownload }
          >
            <ArrowDownTrayIcon className="w-4 h-4" />
          </button>
          {/*{*/ }
          {/*  <PreviewDocumentModal file={ item } preview={ '/files/' + item.id + '/preview' } key={ 'file-' + item.id } /> */ }
          {/*}*/ }
        </div>
      </td>
    </>
  )

  const renameMarkup: any = (
    <>
      <td className="border-b border-gray-table-border p-3 px-0">
        <DocumentIcon className="w-5 h-5" />
      </td>
      <td>
        <form className="flex items-center gap-x-2" onSubmit={ async ( e: any ): Promise<void> => {
          e.preventDefault()
          await renameFile( { variables: { input: { unique_id: item.unique_id, name: newName } } } )
        } }>
          <input
            type="text"
            autoFocus
            required
            value={ newName }
            onChange={ ( ( event: any ): void => setNewName( event.target.value ) ) }
            className="border-none w-full focus:border-none text-sm"
          />
        </form>
      </td>
      <td className="pl-3">
        <button onClick={ undoRename }>
          <UndoIcon className="w-5 h-5" />
        </button>
      </td>
    </>
  )

  return (
    <tr role="button" className="no-link cursor-pointer hover:bg-gray-50">
      { renaming ? renameMarkup : markup }
    </tr>
  )
}

import { XMarkIcon } from '@heroicons/react/24/solid'
import ReactModal    from 'react-modal'
import '../../assets/thread-media.css'

export default function CommunityForumModal( props: any ): JSX.Element {
  const { ID, open, onClose, content } = props
  const modalStyles                = {
    overlay: {
      zIndex:          9999,
      backgroundColor: 'black'
    },

    content: {
      padding:      0,
      inset:        0,
      borderRadius: 0,
      overflow:     'hidden'
    }
  }

  return (
    <ReactModal
      id={ ID ?? 'cf-modal' }
      style={ modalStyles }
      isOpen={ open }
      contentLabel="Community Forum Thread Media Gallery"
    >
      <button onClick={ (): void => onClose( false ) } className="close white">
        <XMarkIcon className="w-5 h-5" />
      </button>
      { content }
    </ReactModal>
  )
}

import React, { useState, useEffect } from "react";
import NavItem from "./NavItem";
import { GENERATE_UUID } from '../../utilities';
import { routes as bmRoutes } from "@bm/aside";
import { useSelector, useDispatch } from 'react-redux';
import createAxiosInstance  from '../../utilities/axios';
import { setCurrentTenant } from '../../features/user/currentTenantSlice';
import classNames from "classnames";
import Cookies from 'js-cookie'
import './Nav.scss';
import { ToggleButton } from "../ToggleButton/ToggleButton";

export default function Nav(props) {
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.currentUser.user);
    const [isOpen, setIsOpen] =  useState(false);
    const [navSlim, setNavSlim] =  useState( ( typeof Cookies.get('navSlim') === 'string' ) || ( document.body.offsetWidth <= 768 ) );
    const [kurrentTenant, setKurrentTenant] = useState();
    const klass = "MainNav flex flex-col ---group translate-x-0 mt-[55px] -translate-x-full  ---lg:relative left-0 bottom-0 top-0 transform bg-white text-gray-400 font-medium ease-in-out transition-all duration-300 z-30";

    const config = {
        currentUser,
        currentTenant: kurrentTenant
    };
    const routes = bmRoutes(config);

    const getCurrentTenant = async () => {
        const Api = createAxiosInstance(process.env.REACT_APP_V1_HOST);

        try {
            const { data } = await Api.get('/api/current-tenant');
            setKurrentTenant(data);
            dispatch(setCurrentTenant(data))
        } catch (error) {
            console.log("Error getting current tenant", error)
        }
    }

    useEffect(() => {
        getCurrentTenant()
        //setNavSlim(typeof Cookies.get('navSlim') === 'string')
    }, []);

    useEffect(() => {
        window.addEventListener('navSlim', (event) => setNavSlim(event.detail))
    }, []);

    return (
        <aside  style={{ width: navSlim ? '52px' : '200px' }} className={classNames(klass, { slim: navSlim })}>
           <div
            className={classNames("group-hover:!w-[200px] relative flex flex-col flex-1 !overflow-y-auto overflow-x-hidden transition duration-150 ease-in-out overflow-hidden h-content", {
                'scrollbar-hide': navSlim
            })}>
            <ToggleButton setIsOpen={ setIsOpen } isOpen={ isOpen } />
                {/* TODO: Place overflow scroll here, on nav */}
                <nav className="min-h-[100%] flex-grow">
                    <ul>
                        {routes.map(route => <NavItem key={ GENERATE_UUID() } {...route} />)}
                    </ul>
                </nav>
           </div>
           <button type="button" className="group flex justify-end bg-right flex-50 bg-white w-full">
                <div className="w-[50px] h-[50px] bg-12 bg-chevron-grey group-hover:bg-chevron-white  bg-no-repeat bg-center">

                </div>
           </button>
        </aside>
    )
}
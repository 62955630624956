import CommunityForumAskQuestion from '../../components/Forum/CommunityForumAskQuestion'
import { ArrowUturnLeftIcon }    from '@heroicons/react/20/solid'
import { useDispatch }           from 'react-redux'
import { useNavigate }           from 'react-router-dom'
import { Button }                from '../../components/Button'
import CommunityForumThreadForm  from '../../components/Forum/CommunityForumThreadForm'
import { setForumListRefetch }   from '../../features/forum/communityForumSlice'
import MainLayout                from '../../layouts/MainLayout'

export default function CommunityForumNewThread(): JSX.Element {
  const dispatch: any = useDispatch()
  const navigate: any = useNavigate()

  return (
    <MainLayout>
      <div className="max-w-screen-3xl mx-auto w-full">
        <div className="flex-grow 2xl:p-8 px-4 py-8 space-y-4 2xl:space-y-8">

          <div className="row">
            <div className="col flex 2xl:-mb-4">
              <div className="flex text-sm font-medium">
                <Button
                  blue
                  label="Back"
                  onClick={ (): any => navigate( -1 ) }
                  icon={ <ArrowUturnLeftIcon className="w-4 h-4" /> }
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col 2xl:flex-row 2xl:space-x-8">
            <div className="w-full 2xl:w-2/3">
              <div className="row flex flex-col items-center w-full bg-white rounded-3xl p-3 2xl:p-4">
                <div className="col flex flex-col w-full">
                  <CommunityForumThreadForm refetch={ (): void => dispatch( setForumListRefetch( true ) ) } />
                </div>
              </div>
            </div>

            <div className="w-full 2xl:w-1/3 mt-4 2xl:mt-0">
              <div className="row flex flex-col items-center w-full">
                <CommunityForumAskQuestion
                  label="Search previously asked questions"
                  isColumn
                  similarOnly
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

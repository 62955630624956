// slice reducer
import { createSlice } from '@reduxjs/toolkit'

export const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState: {
    user: null,
    accountTypes: [],
  },
  reducers: {
    setCurrentUser: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.user = action.payload
    },
    setAccountTypes: ( state, action ) => {
      state.accountTypes = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setCurrentUser, setAccountTypes } = currentUserSlice.actions

export default currentUserSlice.reducer

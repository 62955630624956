import { PencilSquareIcon } from '@heroicons/react/24/outline'
import { Link }             from 'react-router-dom'

export default function SettingsForumTab(): JSX.Element {
  return (
    <div className="flex flex-col space-y-4 w-full">
      <div className="flex items-center justify-between w-full">
        <div>Categories</div>
        <Link to={ `/settings/forum/categories` }>
          <button className="flex items-center text-sm text-blue-input font-medium px-3 py-1 bg-yellow-300 rounded-full">
            <PencilSquareIcon className="w-4 h-4" />
            <span className="pl-1 text-left">Edit</span>
          </button>
        </Link>
      </div>

      <div className="flex items-center justify-between w-full">
        <div>Ranks</div>
        <Link to={ `/settings/forum/ranks` }>
          <button className="flex items-center text-sm text-blue-input font-medium px-3 py-1 bg-yellow-300 rounded-full">
            <PencilSquareIcon className="w-4 h-4" />
            <span className="pl-1 text-left">Edit</span>
          </button>
        </Link>
      </div>
    </div>
  )
}

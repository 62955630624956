import NavButton            from './NavButton'
import { LoadingEllipsis }  from '../../components/Loaders/Loaders'
import { useQuery }         from '@apollo/client'
import { useDispatch, useSelector }      from 'react-redux'
import { setDocumentsData } from '../../features/documents/documentsSlice'
import { GET_DOCUMENTS }    from '../../graphql/document'
import RecentDocuments      from './RecentDocuments'
import FolderTable          from './DocumentFolders/FolderTable'
import FileDropzone         from '../../components/FileDropzone/FileDropzone'
import { useParams }        from 'react-router-dom'

export default function Home() {
  const { page }           = useParams()
  const dispatch           = useDispatch()
  const currentUser        = useSelector((state: any) => state.currentUser.user);
  const { loading, error, refetch } = useQuery( GET_DOCUMENTS, {
    notifyOnNetworkStatusChange: true,
    variables:   {
      input: {
        page:      parseInt( page ) ?? 1,
        perPage:   15,
        sort:      'desc',
        sortBy:    'created_at',
        parent_id: null
      }
    },
    onCompleted: ( { documents } ) => dispatch( setDocumentsData( documents ) )
  } )

  return (
    <div>
      { currentUser.admin ? <NavButton document={ null } /> : null }

      <div>
        <h4 className="font-medium text-xl">Recent Files</h4>
        { loading && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }
        { !loading && <RecentDocuments /> }
      </div>

      <div className="mt-5">
        <h4 className="font-medium text-xl mb-3">Root Folder</h4>

        <FileDropzone
          newDocument
          documentUniqueId={ null }
          refetch={() => refetch()}
          helpText='You can select multiple files at once. Files will be added to this root folder'
        />

        { loading && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }
        { !loading && <FolderTable folderId={ null } /> }

        {
          error &&
          <div className="flex flex-col">
            <b>Error occurred when fetching documents:</b> { ' ' }
            { error.graphQLErrors.map( ( { message }, i: number ) => (
              <span key={ i }>{ message }</span>
            ) ) }
          </div>
        }
      </div>
    </div>
  )
}

import { useLazyQuery, useMutation }       from '@apollo/client'
import { ArrowUturnLeftIcon }              from '@heroicons/react/24/solid'
import { useEffect, useState }             from 'react'
import { useDispatch, useSelector }        from 'react-redux'
import { useNavigate }                     from 'react-router-dom'
import { Button, TextField }               from '../'
import { SelectOption }                    from '../../components/Select/Select'
import UserAvatar                          from '../../components/User/UserAvatar'
import { setToasts, showToaster }          from '../../features/toaster/toasterSlice'
import { setAccountTypes }                 from '../../features/user/currentUserSlice'
import { ACCOUNT_TYPES }                   from '../../graphql/account_type'
import { DELETE_AVATAR_FILE, UPDATE_USER } from '../../graphql/user'
import Api                                 from '../../utilities/axios'
import { LoadingEllipsis }                 from '../Loaders'
import { Switch }                          from '../Switch'

export default function UserEditForm( props: any ): JSX.Element {
  const { user }                            = props
  const navigate: any                       = useNavigate()
  const dispatch: any                       = useDispatch()
  const currentTenant: any                  = useSelector( ( state: any ): any => state.currentTenant.tenant )
  // const locations: any                      = useSelector( ( state: any ): any => state.currentTenant.locations )
  const accountTypes: any                   = useSelector( ( state: any ): any => state.currentUser.accountTypes )
  const [ name, setName ]                   = useState( user ? user.name : '' )
  const [ email, setEmail ]                 = useState( user ? user.email : '' )
  const [ password, setPassword ]           = useState( '' )
  // const [ location, setLocation ]           = useState( null )
  const [ admin, setAdmin ]                 = useState( user ? user.admin : false )
  const [ director, setDirector ]           = useState( user ? user.director : false )
  const [ accountType, setAccountType ]     = useState( ( user && accountTypes ) ? accountTypes.find( ( at: any ): any => at.id === user.account_type_id ) : null )
  const [ nameError, setNameError ]         = useState( false )
  const [ emailError, setEmailError ]       = useState( false )
  const [ passwordError, setPasswordError ] = useState( false )
  const [ fileToUpload, setFileToUpload ]   = useState( null )
  const uploadApiUrl: string                = process.env.REACT_APP_UPLOAD_HOST

  const [ updateUser, { loading: updating } ] = useMutation( UPDATE_USER, {
    onCompleted: (): void => dispatchMessage( 'success', 'Profile updated!' ),
    onError:     (): void => dispatchMessage( 'error', 'Update failed. Please try again' )
  } )

  const [ deleteAvatarFile ] = useMutation( DELETE_AVATAR_FILE, {
    onError: ( e: any ): any => console.error( 'old avatar not deleted: ', e )
  } )

  const dispatchMessage: any = ( type: string, message: string ): void => {
    dispatch( setToasts( [ { id: '1', type, message } ] ) )
    dispatch( showToaster( true ) )
  }

  const handleFileChange: any = async ( e: any ): Promise<void> => {
    if ( e.target.files ) {
      const file: any = e.target.files[ 0 ]

      if ( file.size > 1048576 ) {
        alert( 'Max file size is 1MB. File NOT uploaded!' )
      } else {
        setFileToUpload( file )
      }
    }
  }

  const uploadAvatar: any = async (): Promise<any> => {
    const formData: FormData = new FormData()

    formData.append( 'file', fileToUpload )
    formData.append( 'is_public', 'true' )
    formData.append( 'folder', currentTenant.uuid + '/user/' + user.id + '/avatar' )

    try {
      const { data } = await Api( uploadApiUrl, 'multipart/form-data' ).post( '/', formData )

      return data[ 0 ]?.path?.length ? data[ 0 ].path : null
    } catch ( error ) {
      console.error( `Error uploading file (${ fileToUpload.name }):`, error )
      return null
    }
  }

  const resetErrors: any = (): void => {
    setNameError( false )
    setEmailError( false )
    setPasswordError( false )
  }

  const checkErrors: any = (): boolean => {
    resetErrors()

    let hasErrors: boolean = false
    const emailRegex: any  = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    if ( !name.length ) {
      setNameError( true )
      hasErrors = true
    }

    if ( !email.length || ( email.length < 7 ) || !email.match( emailRegex ) ) {
      setEmailError( true )
      hasErrors = true
    }

    if ( !!password.length && ( password.length > 0 && password.length < 7 ) ) {
      setPasswordError( true )
      hasErrors = true
    }

    return hasErrors
  }

  const handleSubmit: any = async (): Promise<void> => {
    if ( await checkErrors() ) return

    const formData: any = {
      id:              user.id,
      name,
      email,
      admin,
      director,
      account_type_id: accountType.id ?? accountType
    }

    if ( password.length > 0 ) {
      if ( !window.confirm( 'Are you sure you want to change the password for this user?' ) ) {
        return
      }

      formData.password = password
    }

    try {
      if ( fileToUpload ) {
        formData.avatar_url = await uploadAvatar()

        if ( formData.avatar_url && user.avatar_url?.length ) {
          await deleteAvatarFile( { variables: { input: { path: user.avatar_url } } } )
        }
      }

      await updateUser( { variables: { input: formData } } )
    } catch ( e ) {
      console.log( 'e: ', e )
    }
  }

  const [ getAccountTypes, { loading, error } ] = useLazyQuery( ACCOUNT_TYPES, {
    onCompleted: ( { accountTypes }: any ): void => {
      setAccountType( accountTypes.find( ( at: any ): any => at.id === user.account_type_id ) )
      dispatch( setAccountTypes( accountTypes ) )
    }
  } )

  useEffect( (): any => {
    let shouldFetch: boolean = true

    if ( shouldFetch && !accountTypes?.length ) {
      getAccountTypes()
    }

    return (): void => {
      shouldFetch = false
    }
  }, [ accountTypes ] )

  return (
    <>
      <div className="w-24 my-4">
        <Button
          label="Back"
          blue
          onClick={ (): any => navigate( -1 ) }
          icon={ <ArrowUturnLeftIcon className="w-4 h-4" /> }
        />
      </div>

      { ( updating || loading || error ) && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }

      { !updating && !loading && !error &&
        <div className="flex flex-col items-center w-full bg-white rounded-3xl p-4 xl:p-5">
          <div className="flex flex-wrap items-center w-full mb-3">
            <div className="w-1/3">Avatar</div>
            <div className="w-2/3">
              <UserAvatar user={ user } />

              <div className="mb-4">
                <input id="file" type="file" accept="image/*" onChange={ handleFileChange } />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap items-center w-full mb-3">
            <div className="w-1/3">Name</div>
            <div className="w-2/3">
              <TextField
                label={ 'Name' }
                value={ name }
                name="title"
                type="text"
                onChange={ setName }
                errorField={ nameError ? 'name' : '' }
                isRequired
              />
            </div>
          </div>

          <div className="flex flex-wrap items-center w-full mb-3">
            <div className="w-1/3">Email</div>
            <div className="w-2/3">
              <TextField
                label={ 'Email' }
                value={ email }
                name="email"
                type="text"
                onChange={ setEmail }
                errorField={ emailError ? 'email' : '' }
                isRequired
              />
            </div>
          </div>

          <div className="flex flex-wrap items-center w-full mb-3">
            <div className="w-1/3">Password</div>
            <div className="w-2/3">
              <TextField
                label={ 'Password' }
                value={ password }
                name="password"
                type="password"
                onChange={ setPassword }
                errorField={ passwordError ? 'password' : '' }
              />
            </div>
          </div>

          {/*<div className="flex flex-wrap items-center w-full mb-3">*/ }
          {/*  <div className="w-1/3">Location</div>*/ }
          {/*  <div className="w-2/3">*/ }
          {/*migrate locations first*/ }
          {/*<SelectOption*/ }
          {/*  setSelected={ setLocation }*/ }
          {/*  selected={ location }*/ }
          {/*  placeholder={ location.name }*/ }
          {/*  fieldClasses="pl-2 border-none focus:ring-0 font-bold"*/ }
          {/*  options={ locations.map( ( sort: any ): any => {*/ }
          {/*    return { key: sort.id, value: sort.name }*/ }
          {/*  } ) }*/ }
          {/*/>*/ }
          {/*  </div>*/ }
          {/*</div>*/ }

          <div className="flex flex-wrap items-center w-full mb-3">
            <div className="w-1/3">Director/Manager</div>
            <div className="w-2/3">
              <Switch
                checked={ director }
                onChange={ setDirector }
              />
            </div>
          </div>

          <div className="flex flex-wrap items-center w-full mb-3">
            <div className="w-1/3">Admin</div>
            <div className="w-2/3">
              <Switch
                checked={ admin }
                onChange={ setAdmin }
              />
            </div>
          </div>

          <div className="flex flex-wrap items-center w-full mb-3">
            <div className="w-1/3">Account Type</div>
            <div className="w-2/3">
              <div className="flex">
                <SelectOption
                  setSelected={ setAccountType }
                  selected={ accountType }
                  placeholder={ accountType?.name }
                  wrapperClasses="text-grey-input rounded-2xl border border-grey-light overflow-hidden"
                  fieldClasses="border-none focus:ring-0"
                  options={ accountTypes.map( ( sort: any ): any => {
                    return { key: sort.id, value: sort.name }
                  } ) }
                />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap w-full mt-3">
            <div className="flex pt-5">
              <Button label="Submit" yellow onClick={ handleSubmit } />
            </div>
          </div>
        </div>
      }
    </>
  )
}

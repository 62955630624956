import { useLazyQuery }                                           from '@apollo/client'
import { ArrowDownTrayIcon }                                      from '@heroicons/react/24/solid'
import { format, parseISO }                                       from 'date-fns'
import { useEffect, useState }                                    from 'react'
import { useSelector }                                            from 'react-redux'
import { useSearchParams }                                        from 'react-router-dom'
import { GET_FORUM_THREAD_REPLIES, GET_FORUM_THREAD_REPLY_BY_ID } from '../../graphql/communityForum'
import { assetFilePath, prefetchFileAndDownload }                 from '../../utilities'
import { Button }                                                 from '../Button'
import { Image }                                                  from '../Image'
import { LoadingEllipsis }                                        from '../Loaders'
import CommunityForumModal                                        from '../Modal/CommunityForumModal'
import Pagination                                                 from '../Pagination/Pagination'
import { SelectOption }                                           from '../Select/Select'
import CommunityForumNotificationsBellButton                      from './CommunityForumNotificationsBellButton'
import CommunityForumSingleReply                                  from './CommunityForumSingleReply'
import CommunityForumThreadReply                                  from './CommunityForumThreadReply'
import CommunityForumThreadReplyForm                              from './CommunityForumThreadReplyForm'
import CommunityForumThreadSingleAttachment                       from './CommunityForumThreadSingleAttachment'

interface ConversationProps
{
  forumThread: any
  refetch: any
}

export default function CommunityForumThreadConversationTab( props: ConversationProps ): JSX.Element {
  const { forumThread, refetch }                                          = props
  const [ searchParams, setSearchParams ]                                 = useSearchParams()
  const currentUser: any                                                  = useSelector( ( state: any ): any => state.currentUser.user )
  const [ sortBy, setSortBy ]                                             = useState( searchParams.get( 'sortBy' ) ?? 'newestCreated' )
  const [ showAttachment, setShowAttachment ]                             = useState( null )
  const [ replyFormOpen, setReplyFormOpen ]                               = useState( null )
  const [ forumThreadReplies, setForumThreadReplies ]                     = useState( null )
  const [ forumThreadRepliesPagination, setForumThreadRepliesPagination ] = useState( null )
  const [ bestReply, setBestReply ]                                       = useState( null )
  const [ hasReplies, setHasReplies ]                                     = useState( false )
  const threadRepliesCount: number                                        = forumThreadRepliesPagination?.totalResults ?? forumThreadReplies?.length ?? 0
  const appCdnUrl: string                                                 = process.env.REACT_APP_CDN + ( process.env.REACT_APP_CDN.slice( -1 ) === '/' ? '' : '/' )

  const sortOptions: any    = [
    { id: 'newestCreated', name: 'Newest' },
    { id: 'oldestCreated', name: 'Oldest' }
  ]
  const selectedOption: any = sortOptions.find( ( option: any ): any => option.id === sortBy )

  const [ getForumThreadBestReply, { loading: loadingBestReply } ] = useLazyQuery( GET_FORUM_THREAD_REPLY_BY_ID, {
    notifyOnNetworkStatusChange: true,
    onCompleted:                 ( { forumThreadReplyById }: any ): void => setBestReply( forumThreadReplyById )
  } )

  const [ getForumThreadReplies, {
    loading: loadingReplies,
    error:   errorLoadingReplies,
    refetch: refetchThreadReplies
  } ] = useLazyQuery( GET_FORUM_THREAD_REPLIES, {
    notifyOnNetworkStatusChange: true,
    onCompleted:                 ( { forumThreadReplies }: any ): void => {
      setForumThreadReplies( forumThreadReplies.data )
      setForumThreadRepliesPagination( forumThreadReplies.links )

      if ( forumThreadReplies.data.length > 0 ) {
        setHasReplies( true )
      } else {
        setHasReplies( null )
      }
    }
  } )

  const resolveSort: any = (): string => {
    switch ( sortBy ) {
      case 'oldestCreated':
        return 'asc'
      case 'newestCreated':
        return 'desc'
      default:
        return 'desc'
    }
  }

  const resolveSortBy: any = (): string => {
    switch ( sortBy ) {
      case 'oldestCreated':
      case 'newestCreated':
        return 'created_at'
      default:
        return 'created_at'
    }
  }

  const handleAttachment: any = ( attachment: any ): void => {
    if ( attachment.type === 'application' ) {
      window.open( appCdnUrl + attachment.url, '_blank' ).focus()
    }

    if ( attachment.type === 'image' ) {
      setShowAttachment( attachment )
    }
  }

  const fetchForumThreadReplies: any = async ( page: number = 1 ): Promise<void> => {
    if ( isNaN( page ) ) page = 1

    setSearchParams( ( params: URLSearchParams ): URLSearchParams => {
      if ( page !== 1 ) {
        params.set( 'page', `${ page }` )
      } else {
        if ( params.has( 'page' ) ) params.delete( 'page' )
      }

      if ( sortBy !== 'newestCreated' ) {
        params.set( 'sortBy', sortBy )
      } else {
        if ( params.has( 'sortBy' ) ) params.delete( 'sortBy' )
      }

      return params
    } )

    await getForumThreadReplies( {
                                   variables: {
                                     input: {
                                       page,
                                       thread_id: forumThread.id,
                                       sort:      resolveSort(),
                                       sortBy:    resolveSortBy()
                                     }
                                   }
                                 } )
  }

  const canModify: any = ( authorId: string ): boolean => {
    return (
      currentUser.admin ||
      currentUser.id === authorId
    )
  }

  const refetchAll: any = ( isNew: boolean = false ): void => {
    if ( isNew ) refetch()

    refetchThreadReplies()
  }

  useEffect( (): any => {
    let shouldFetch: boolean = true

    if ( shouldFetch ) {
      if ( !!forumThread?.best_reply_id ) {
        getForumThreadBestReply( { variables: { input: { id: forumThread.best_reply_id } } } )
      } else {
        setBestReply( null )
      }
    }

    return (): void => {
      shouldFetch = false
    }
  }, [ forumThread ] )

  useEffect( (): any => {
    let shouldFetchReplies: boolean = true

    if ( shouldFetchReplies ) {
      fetchForumThreadReplies( !forumThreadReplies ? ( parseInt( searchParams.get( 'page' ) ) ?? 1 ) : 1 ).catch( console.error )
    }

    return (): void => {
      shouldFetchReplies = false
    }
  }, [ sortBy ] )

  return (
    <>
      <div className="row flex flex-col items-center w-full bg-white rounded-3xl p-4 lg:p-5">
        <div className="col flex justify-between w-full">
          <h4 className="title text-blue-header font-bold text-2xl mt-4 mr-4">{ forumThread.title }</h4>
          <CommunityForumNotificationsBellButton threadId={ forumThread.id } notificationsEnabled={ forumThread.forum_thread_users_notification?.enabled } refetch={ refetch } />
        </div>

        <div className="col flex py-2 w-full">
          <p className="text-blue-header font-medium mt-2" dangerouslySetInnerHTML={ { __html: forumThread.body } } />
        </div>

        <div className="col flex py-2 w-full">
          <div className="flex text-grey-input w-full">Posted on { format( parseISO( forumThread.created_at ), 'do MMMM yyyy hh:mm a' ) }</div>
        </div>

        { forumThread.attachments?.length > 0 &&
          <div className="flex items-center justify-between w-full pt-4 2xl:pt-8 border-t border-grey-line mt-4 2xl:mt-8">
            <div className="flex text-blue-header text-xl font-semibold">Attachments:</div>
            <div className="flex">
              { forumThread.attachments.map( ( attachment: any ): any =>
                                               <CommunityForumThreadSingleAttachment
                                                 classes="flex p-2 w-16 lg:w-24 h-16 lg:h-24 h-full items-center justify-center cursor-pointer ml-3 2xl:ml-6 rounded-2xl border border-grey-line"
                                                 attachment={ attachment }
                                                 key={ attachment.id }
                                                 handleAttachment={ (): any => handleAttachment( attachment ) }
                                               /> ) }
            </div>
          </div>
        }
      </div>

      { ( loadingReplies || loadingBestReply ) && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }

      { ( !loadingReplies && !loadingBestReply ) && hasReplies &&
        <>
          { bestReply &&
            <div className="row flex items-center w-full bg-gray-50 rounded-xl p-4 lg:p-5">
              <div className="col flex flex-col w-full">
                <CommunityForumSingleReply
                  canModify={ canModify( forumThread.author_id ) }
                  reply={ bestReply }
                  bestReplyId={ bestReply.id }
                  setBestReply={ setBestReply }
                />
              </div>
            </div>
          }

          <div className="row flex flex-col items-center w-full bg-white rounded-3xl p-4 lg:p-5">
            <div className="col flex justify-between w-full">
              <div className="flex items-center w-full text-blue-header text-lg font-bold">Replies: { threadRepliesCount }</div>

              <div className="flex">
                <div className="flex items-center pl-4 text-grey-input rounded-2xl border border-grey-light overflow-hidden">
                  <div className="text-base	font-medium whitespace-nowrap">Sort by:</div>
                  <SelectOption
                    setSelected={ setSortBy }
                    selected={ selectedOption }
                    placeholder={ selectedOption.name }
                    fieldClasses="pl-2 border-none focus:ring-0 font-bold"
                    options={ sortOptions.map( ( sort: any ): any => {
                      return { key: sort.id, value: sort.name }
                    } ) }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row flex flex-col items-center w-full bg-white rounded-3xl p-4 lg:p-5">
            <div className="col flex flex-col w-full">
              { forumThreadReplies.map( ( reply: any, index: any ): any => <CommunityForumThreadReply
                replyable
                key={ reply.id }
                reply={ reply }
                refetch={ ( isNew: boolean ): any => refetchAll( isNew ) }
                canModify={ canModify( forumThread.author_id ) }
                replyFormOpen={ replyFormOpen }
                setReplyFormOpen={ setReplyFormOpen }
                bestReplyId={ forumThread.best_reply_id }
                setBestReply={ setBestReply }
                isLast={ forumThreadReplies.length === ( index + 1 ) }
              /> ) }
            </div>

            { ( forumThreadRepliesPagination?.totalPages > 0 ) &&
              <Pagination
                className="justify-content-center pb-2 lg:pb-3"
                currentPage={ forumThreadRepliesPagination.page }
                totalCount={ forumThreadRepliesPagination.totalResults }
                pageSize={ forumThreadRepliesPagination.perPage }
                onPageChange={ ( page: number ): Promise<void> => fetchForumThreadReplies( page ) }
              /> }
          </div>
        </>
      }

      { ( !loadingReplies && !loadingBestReply ) &&
        <div className="row flex flex-col items-center w-full bg-white rounded-3xl p-4 lg:p-5">
          { ( replyFormOpen !== 0 ) &&
            <div className="flex justify-between items-center w-full">
              <div className="flex items-center w-full text-blue-header text-xl font-bold">
                { ( threadRepliesCount > 0 ) ? 'More to add? Submit your own response' : 'This post has no replies. Be the first to respond.' }
              </div>
              <div className="w-36">
                <Button yellow label="Reply now" onClick={ (): void => setReplyFormOpen( 0 ) } />
              </div>
            </div>
          }
          { ( replyFormOpen === 0 ) &&
            <CommunityForumThreadReplyForm
              forumThreadId={ forumThread.id }
              setReplyFormOpen={ setReplyFormOpen }
              refetch={ ( isNew: boolean ): any => refetchAll( isNew ) }
            />
          }
        </div>
      }

      { !!showAttachment &&
        <CommunityForumModal
          open={ !!showAttachment }
          onClose={ (): void => setShowAttachment( null ) }
          content={
            <div className="thread-media-image">
              <button
                onClick={ async (): Promise<void> => await prefetchFileAndDownload( showAttachment.url, `${ showAttachment.name }.${ showAttachment.file_ext }`, true ) }
                className="absolute z-10 p-[10px] left-0 top-0 bg-white opacity-50 hover:opacity-75"
              >
                <ArrowDownTrayIcon className="w-5 h-5" />
              </button>
              <Image
                klass="thread-media-image"
                src={ appCdnUrl + showAttachment.url }
                alt={ showAttachment.name }
                defaultSrc={ assetFilePath( showAttachment.file_ext ) }
              />
            </div> }
        />
      }

      { errorLoadingReplies &&
        <div className="flex flex-col w-full bg-white rounded-xl p-4 lg:p-5">
          <b>Error occurred while fetching Forum Thread Replies:</b>
          { errorLoadingReplies.graphQLErrors.map( ( { message }: any, i: number ): any => (
            <div key={ i } className="flex w-full"><span>{ message }</span></div>
          ) ) }
        </div>
      }
    </>
  )
}

import { useQuery }              from '@apollo/client'
import { useState }              from 'react'
import { useDispatch }           from 'react-redux'
import { Link }                  from 'react-router-dom'
import { LoadingEllipsis }       from '../../components/Loaders/Loaders'
import { setForumListRefetch }   from '../../features/forum/communityForumSlice'
import { GET_FORUM_THREAD_LIST } from '../../graphql/communityForum'
import { truncateText }          from '../../utilities'
import CommunityForumThreadForm  from './CommunityForumThreadForm'

export default function  CommunityForumAskQuestionModal( props: any ): JSX.Element {
  const { question, similarOnly }                              = props
  const dispatch: any                             = useDispatch()
  const [ similarQuestions, setSimilarQuestions ] = useState( null )

  const { loading, error } = useQuery( GET_FORUM_THREAD_LIST, {
    variables:   { input: { query: question, limit: 5, sortBy: 'created_at' } },
    onCompleted: ( { forumThreadList }: any ): void => setSimilarQuestions( forumThreadList.data )
  } )

  return (
    <div className="w-72 md:w-[36rem] lg:w-[48rem] flex flex-col flex-grow">
      { loading && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4 mt-4" /> }

      { !loading &&
        <>
          { similarQuestions?.length > 0 &&
            <div className={ `mx-4 ${ similarOnly ? 'my-4' : 'mt-4' } p-4 rounded-2xl bg-grey-light-secondary` }>
              <div className="text-blue-header font-bold text-lg px-4 mb-3">You may be interested in similar questions already asked:</div>

              { similarQuestions.map( ( q: any, i: any ): any => (
                <div key={ i } className="flex justify-between items-center bg-white py-2.5 px-4 mt-2.5 rounded-2xl"
                >
                  <Link to={ '/forum/' + q.id } className="font-bold text-blue-input">
                    <span>{ truncateText( q.title, 150 ) }</span>
                  </Link>

                  <Link
                    to={ '/forum/' + q.id }
                    className="hidden lg:flex justify-end items-center py-1 px-8 text-blue-input bg-yellow-300 rounded-full"
                  >
                    <span>Open</span>
                  </Link>
                </div>
              ) ) }
            </div>
          }

          { similarOnly && !similarQuestions?.length &&
            <div className="mx-4 my-4 p-4 rounded-2xl bg-grey-light-secondary">
              <div className="text-blue-header font-bold text-lg px-4">Nothing found...</div>
            </div>
          }

          { !similarOnly &&
            <div className="p-8 rounded-2xl">
              <CommunityForumThreadForm isModal question={ question } refetch={ (): void => dispatch( setForumListRefetch( true ) ) } />
            </div>
          }
        </>
      }

      { error &&
        <div className="flex flex-col w-full bg-white rounded-xl p-4 lg:p-5">
          <b>Error occurred:</b>
          { error.graphQLErrors.map( ( { message }: any, i: number ): any => (
            <div key={ ( i + 1000 ) } className="flex w-full"><span>{ message }</span></div>
          ) ) }
        </div>
      }
    </div>
  )
}

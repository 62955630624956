import { createSlice } from '@reduxjs/toolkit'

export const documentsSlice = createSlice( {
  name:         'documents',
  initialState: {
    folders:        [],
    data:           [],
    file:           [],
    pagination:     {},
    fetchingFolder: false
  },
  reducers:     {
    setCurrentDocument: ( state, action ) => {
      state.file = action.payload
    },
    setDocumentFolders: ( state, action ) => {
      state.folders = action.payload
    },
    setDocumentsData:   ( state, action ) => {
      state.data       = action.payload.data
      state.pagination = action.payload.links
    }
  }
} )

export const { setCurrentDocument, setDocumentFolders, setDocumentsData } = documentsSlice.actions

export default documentsSlice.reducer

import React, { useEffect, useState } from 'react'

type ImageProps = {
  src: string;
  defaultSrc: string;
  alt?: string;
  klass?: string;
};

export const Image: React.FC<ImageProps> = ( { src, defaultSrc, alt, klass }: ImageProps ): JSX.Element => {
  const [ imageSrc, setImageSrc ] = useState( src )

  const handleImageError: any = ( e: any ): void => {
    setImageSrc( defaultSrc )
  }

  /**
   * Always update when parent is updated or when src is changed.
   */
  useEffect( (): void => {
    setImageSrc( src ?? defaultSrc )
  }, [ src ] )

  return <img className={ klass } loading="lazy" src={ imageSrc } onError={ handleImageError } alt={ alt } />
}

import { ArrowUturnLeftIcon } from '@heroicons/react/24/solid'
import { useState }           from 'react'
import { useSelector }        from 'react-redux'
import { useNavigate }        from 'react-router-dom'
import { Button }             from '../../components'
import { LoadingEllipsis }    from '../../components/Loaders'
import SettingsForumTab       from '../../components/Settings/SettingsForumTab'
import SettingsGeneralTab     from '../../components/Settings/SettingsGeneralTab'
import SettingsUsersTab     from '../../components/Settings/SettingsUsersTab'
import MainLayout             from '../../layouts/MainLayout'

export default function Settings(): JSX.Element {
  const currentUser: any            = useSelector( ( state: any ): any => state.currentUser.user )
  const navigate: any               = useNavigate()
  const [ activeTab, setActiveTab ] = useState( 'users' )

  const navStyle: string = 'w-full h-full px-3 py-1 flex items-center whitespace-nowrap justify-center bg-blue-secondary'

  return (
    <MainLayout>
      <div className="max-w-screen-3xl mx-auto w-full">
        <div className="flex-grow xl:p-8 p-3 space-y-4 xl:space-y-8">
          { !currentUser && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4 mt-4 w-full" /> }
          { currentUser?.admin &&
            <>
              <div className="flex justify-between w-full my-4">
                <div className="flex rounded-full text-sm font-medium text-white overflow-hidden">
                  <Button
                    label="Back"
                    blue
                    onClick={ (): any => navigate( -1 ) }
                    icon={ <ArrowUturnLeftIcon className="w-4 h-4" /> }
                  />
                </div>
                <div className="flex rounded-full text-sm font-medium text-white overflow-hidden">
                  {/*<button*/ }
                  {/*  className={ navStyle }*/ }
                  {/*  onClick={ (): void => setActiveTab( 'general' ) }*/ }
                  {/*>*/ }
                  {/*  <span>General</span>*/ }
                  {/*</button>*/ }

                  <button
                    className={ navStyle }
                    onClick={ (): void => setActiveTab( 'users' ) }
                  >
                    <span>Users</span>
                  </button>

                  <button
                    className={ navStyle }
                    onClick={ (): void => setActiveTab( 'forum' ) }
                  >
                    <span>Forum</span>
                  </button>
                </div>
              </div>

              <div className="row flex flex-col items-center w-full bg-white rounded-3xl p-3 xl:p-4">
                { ( activeTab === 'general' ) && <SettingsGeneralTab /> }
                { ( activeTab === 'forum' ) && <SettingsForumTab /> }
                { ( activeTab === 'users' ) && <SettingsUsersTab /> }
              </div>
            </>
          }
          { !currentUser?.admin &&
            <div className="row flex flex-col items-center w-full bg-white rounded-3xl p-3 xl:p-4">
              Access Denied!
            </div>
          }
        </div>
      </div>
    </MainLayout>
  )
}

import UsersTableItem from './UsersTableItem'

export default function UsersTable( props: any ): JSX.Element {
  const { users, accountTypes, refetch } = props

  return (
    <>
      <div className="px-2 2xl:px-4 rounded-3xl overflow-x-auto">
        <div className={ `bg-white rounded-2xl p-2 2xl:p-4 w-full grid grid-users-table-cols-admin gap-4` }>
          <div className="flex justify-center font-bold flex-col">Name</div>

          <div className="text-blue-header font-bold">Email</div>

          <div className="text-blue-header font-bold">Type</div>

          <div className="flex justify-center font-bold items-center">&nbsp;</div>
        </div>
      </div>

      <div className="bg-blue-subtle p-2 2xl:p-4 rounded-3xl overflow-x-auto space-y-2 2xl:space-y-4">
        { users.map( ( user: any, index: any ): any =>
                       <UsersTableItem
                         accountType={ accountTypes.find( ( at: any ): boolean => at.id === user.account_type_id ) }
                         key={ index }
                         user={ user }
                         refetch={ refetch }
                       /> ) }
      </div>
    </>
  )
}

import { useMutation }                            from '@apollo/client'
import { useDispatch }                            from 'react-redux'
// @ts-ignore
import { ReactComponent as BellIcon }             from '../../assets/svgs/bell.svg'
import { setToasts, showToaster }                 from '../../features/toaster/toasterSlice'
import { TOGGLE_FORUM_THREAD_USERS_NOTIFICATION } from '../../graphql/communityForum'
import { LoadingEllipsis }                        from '../Loaders/Loaders'

export default function CommunityForumNotificationsBellButton( props: any ): JSX.Element {
  const { threadId, notificationsEnabled, refetch } = props
  const dispatch: any                               = useDispatch()

  const updateList: any = ( enabled: boolean ): void => {
    const msg = enabled
                ? 'Email notifications for this question has been enabled!'
                : 'Email notifications for this question has been removed!'

    dispatchMessage( 'success', msg, true )

    if ( refetch ) refetch()
  }

  const [ toggleForumThreadUsersNotification, { loading: notificationsUpdating } ] = useMutation( TOGGLE_FORUM_THREAD_USERS_NOTIFICATION, {
    variables:   { input: { thread_id: threadId, enabled: !notificationsEnabled } },
    onCompleted: (): void => updateList( !notificationsEnabled )
  } )

  const dispatchMessage: any = ( type: string, message: string ): void => {
    dispatch( setToasts( [ { id: '1', type, message } ] ) )
    dispatch( showToaster( true ) )
  }

  return (
    <div className="flex items-center justify-center">
      { notificationsUpdating && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }
      { !notificationsUpdating &&
        <button
          title="Notifications"
          onClick={ (): any => toggleForumThreadUsersNotification() }
          className="w-8 h-8 rounded-full flex items-center justify-center hover:bg-gray-100 focus:ring-2 focus:ring-gray-200"
        >
          { notificationsEnabled && <BellIcon className="fill-yellow-300 w-5 h-5" /> }
          { !notificationsEnabled && <BellIcon className="w-5 h-5" /> }
        </button>
      }
    </div>
  )
}

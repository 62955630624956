import { useMutation }                                  from '@apollo/client'
import { ArrowDownTrayIcon }                            from '@heroicons/react/24/solid'
import { useState }                                     from 'react'
import { useDispatch }                                  from 'react-redux'
import { setForumListRefetch }                          from '../../features/forum/communityForumSlice'
import { setToasts, showToaster }                       from '../../features/toaster/toasterSlice'
import { DELETE_FORUM_THREAD_REPLY, TOGGLE_REPLY_LIKE } from '../../graphql/communityForum'
import { assetFilePath, prefetchFileAndDownload }       from '../../utilities'
import CommunityForumSingleReply                        from '../Forum/CommunityForumSingleReply'
import CommunityForumThreadReplyForm                    from '../Forum/CommunityForumThreadReplyForm'
import { Image }                                        from '../Image'
import { LoadingEllipsis }                              from '../Loaders'
import CommunityForumModal                              from '../Modal/CommunityForumModal'
import CommunityForumThreadSingleAttachment             from './CommunityForumThreadSingleAttachment'

export default function CommunityForumThreadReply( props: any ): JSX.Element {
  const {
          canModify,
          reply,
          replyable,
          bestReplyId,
          replyFormOpen,
          setReplyFormOpen,
          setBestReply,
          refetch,
          isLast
        } = props

  const dispatch: any                         = useDispatch()
  const [ showAttachment, setShowAttachment ] = useState( null )
  const [ isEdit, setIsEdit ]                 = useState( null )
  const appCdnUrl: string                     = process.env.REACT_APP_CDN + ( process.env.REACT_APP_CDN.slice( -1 ) === '/' ? '' : '/' )

  const [ toggleReplyLike, { loading } ] = useMutation( TOGGLE_REPLY_LIKE, {
    onCompleted: (): void => {
      dispatch( setForumListRefetch( true ) )
      refetch( false )
    }
  } )

  const [ deleteForumThreadReply, { loading: deleting } ] = useMutation( DELETE_FORUM_THREAD_REPLY, {
    onCompleted: (): void => {
      dispatchMessage( 'success', 'Reply deleted!' )
      refetch()
    }
  } )

  const toggleLike: any = async (): Promise<void> => {
    await toggleReplyLike( { variables: { input: { reply_id: reply.id, thread_id: reply.thread_id } } } )
  }

  const handleReplyForm: any = ( replyId: string | null, isEdit: boolean = false ): void => {
    setReplyFormOpen( replyId )
    setIsEdit( isEdit )
  }

  const handleDelete: any = async ( e: any ): Promise<void> => {
    e.preventDefault()

    if ( window.confirm( 'Are you sure you want to delete this Reply?' ) ) {
      await deleteForumThreadReply( { variables: { input: { id: reply.id } } } )
    }
  }

  const handleAttachment: any = ( attachment: any ): any => {
    if ( attachment.type === 'application' ) {
      window.open( appCdnUrl + attachment.url, '_blank' ).focus()
    }

    if ( attachment.type === 'image' ) {
      setShowAttachment( attachment )
    }
  }

  const dispatchMessage: any = ( type: string, message: string ): void => {
    dispatch( setToasts( [ { id: '1', type, message } ] ) )
    dispatch( showToaster( true ) )
  }

  return (
    <div className={ `thread-reply ${ isLast ? 'mb-0' : 'mb-16' }` }>
      { ( loading || deleting ) && <LoadingEllipsis klass="bg-white ellipse-yellow ellipse-xl p-4" /> }
      { !loading && !deleting &&
        <>
          <CommunityForumSingleReply
            canModify={ canModify }
            reply={ reply }
            replyable={ replyable }
            bestReplyId={ bestReplyId }
            setBestReply={ setBestReply }
            toggleLike={ toggleLike }
            handleDelete={ handleDelete }
          />

          { reply.attachments?.length > 0 &&
            <div className="col grid grid-cols-4 lg:grid-cols-8 gap-2 lg:gap-4 w-full py-2">
              { reply.attachments.map( ( attachment: any ): any =>
                                         <CommunityForumThreadSingleAttachment
                                           classes="flex p-2 w-16 lg:w-24 h-16 lg:h-24 h-full items-center justify-center cursor-pointer rounded-2xl border border-grey-line"
                                           attachment={ attachment }
                                           key={ attachment.id }
                                           handleAttachment={ (): any => handleAttachment( attachment ) }
                                         /> ) }
            </div>
          }

          { replyable && ( replyFormOpen === reply.id ) &&
            <CommunityForumThreadReplyForm
              forumThreadId={ reply.thread_id }
              parentId={ reply.id }
              setReplyFormOpen={ handleReplyForm }
              forumThreadReply={ isEdit ? reply : null }
              refetch={ refetch }
            />
          }

          { !!showAttachment &&
            <CommunityForumModal
              open={ !!showAttachment }
              onClose={ (): void => setShowAttachment( null ) }
              content={ <div className="thread-media-image">
                <button
                  onClick={ async (): Promise<void> => await prefetchFileAndDownload( showAttachment.url, `${ showAttachment.name }.${ showAttachment.file_ext }`, true ) }
                  className="absolute z-10 p-[10px] left-0 top-0 bg-white opacity-50 hover:opacity-75"
                >
                  <ArrowDownTrayIcon className="w-5 h-5" />
                </button>
                <Image
                  klass="thread-media-image"
                  src={ appCdnUrl + showAttachment.url }
                  alt={ showAttachment.name }
                  defaultSrc={ assetFilePath( showAttachment.file_ext ) }
                />
              </div> }
            />
          }
        </>
      }
    </div>
  )
}

import { createSlice } from '@reduxjs/toolkit'

export const communityForumSlice = createSlice( {
  name:         'communityForum',
  initialState: {
    data:                     [],
    categories:               [],
    ranks:                    [],
    pagination:               {},
    mentionsUsersList:        [],
    refetch:                  false,
    ranksRefetch:             false,
    threadAttachmentsRefetch: false
  },
  reducers:     {
    setForumCategories:          ( state, action ) => {
      state.categories = action.payload
    },
    setForumRanks:               ( state, action ) => {
      state.ranks = action.payload
    },
    setForumListRefetch:         ( state, action ) => {
      state.refetch = action.payload
    },
    setThreadAttachmentsRefetch: ( state, action ) => {
      state.threadAttachmentsRefetch = action.payload
    },
    setForumRanksRefetch: ( state, action ) => {
      state.ranksRefetch = action.payload
    },
    setThreadList:               ( state, action ) => {
      state.data       = action.payload.data
      state.pagination = action.payload.links
    },
    setMentionsUsersList:        ( state, action ) => {
      state.mentionsUsersList = action.payload
    }
  }
} )

export const {
               setForumCategories,
               setForumRanks,
               setForumListRefetch,
               setThreadAttachmentsRefetch,
               setForumRanksRefetch,
               setThreadList,
               setMentionsUsersList
             } = communityForumSlice.actions

export default communityForumSlice.reducer

import { useState, useEffect, useLayoutEffect, useRef } from "react";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $generateHtmlFromNodes, $generateNodesFromDOM  } from "@lexical/html";
import { $isAtNodeEnd } from "@lexical/selection";
import { $insertNodes, $getNodeByKey, $createRangeSelection, $getSelection, $setSelection, $isRangeSelection, $getRoot, $getTextContent, $createParagraphNode } from "lexical";

// interface Props {
//     initialHtml?: string;
//     onHtmlChanged: (html: string) => void;
// }

const HtmlPlugin = ({ initialHtml, onHtmlChanged }) => {
    const [editor] = useLexicalComposerContext();

    useLayoutEffect(() => {
        editor.update(() => {
            const editorContent = $generateHtmlFromNodes(editor);

            if (editorContent !== initialHtml) {
                
               

                const parser = new DOMParser();
                const dom = parser.parseFromString(initialHtml, "text/html");
                const nodes = $generateNodesFromDOM(editor, dom);
                
                $insertNodes(nodes);
               

                // This keeps the cursor at the start. To control this
                // find a way to pass in an option: if/else
                const rangeSelection = $createRangeSelection();
                $setSelection(rangeSelection);
            }
        });

    }, [initialHtml]);

    return (
        <OnChangePlugin
            onChange={(editorState) => {
                editorState.read(() => {
                    onHtmlChanged($generateHtmlFromNodes(editor));
                });
            }}
        />
    );
};

export default HtmlPlugin;
import { useLazyQuery, useMutation }              from '@apollo/client'
import { ArrowUturnLeftIcon }                     from '@heroicons/react/24/solid'
import { useEffect }                              from 'react'
import { useDispatch, useSelector }               from 'react-redux'
import { useNavigate }                            from 'react-router-dom'
import { Button }                                 from '../../components'
import { LoadingEllipsis }                        from '../../components/Loaders'
import { setForumRanks, setForumRanksRefetch }    from '../../features/forum/communityForumSlice'
import { setToasts, showToaster }                 from '../../features/toaster/toasterSlice'
import { GET_FORUM_RANKS, REASSIGN_USERS_POINTS } from '../../graphql/communityForum'
import MainLayout                                 from '../../layouts/MainLayout'

export default function SettingsForumRanks(): JSX.Element {
  const navigate: any          = useNavigate()
  const dispatch: any          = useDispatch()
  const currentUser: any       = useSelector( ( state: any ): any => state.currentUser.user )
  const forumRanks: any        = useSelector( ( state: any ): any => state.communityForum.ranks )
  const forumRanksRefetch: any = useSelector( ( state: any ): any => state.communityForum.ranksRefetch )

  const [ getRanks, { loading, refetch } ] = useLazyQuery( GET_FORUM_RANKS, {
    notifyOnNetworkStatusChange: true,
    onCompleted:                 ( { forumRanks }: any ): void => dispatch( setForumRanks( forumRanks ) )
  } )

  const fetchRanks: any = async (): Promise<void> => {
    await getRanks()
  }

  const refetchRanks: any = async (): Promise<void> => {
    await refetch()
    dispatch( setForumRanksRefetch( false ) )
  }

  const dispatchMessage: any = ( type: string, message: string ): void => {
    dispatch( setToasts( [ { id: '1', type, message } ] ) )
    dispatch( showToaster( true ) )
  }

  const [ reassignPoints, { loading: reassigning } ] = useMutation( REASSIGN_USERS_POINTS, {
    notifyOnNetworkStatusChange: true,
    onCompleted:                 (): any => dispatchMessage( 'success', 'Points reassigning process in the background.' ),
    onError:                     (): any => dispatchMessage( 'error', 'Please check if points has been reassigned after few minutes.' )
  } )

  const reassignUsersPoints: any = async (): Promise<void> => {
    const confirmed: boolean = window.confirm( 'Are you sure you want to reassign all users forum points?' )

    if ( confirmed ) {
      await reassignPoints()
    }
  }

  useEffect( (): any => {
    let shouldFetch: boolean = true

    if ( shouldFetch && !forumRanks?.length ) {
      fetchRanks()
    }

    if ( forumRanksRefetch ) {
      refetchRanks()
    }

    return (): void => {
      shouldFetch = false
    }
  }, [ forumRanks ] )

  return (
    <MainLayout>
      <div className="max-w-screen-3xl mx-auto w-full">
        <div className="flex-grow xl:p-8 p-3 space-y-4 xl:space-y-8">
          { !currentUser && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4 mt-4 w-full" /> }
          { currentUser?.admin &&
            <>
              <div className="flex justify-between w-full my-4">
                <div className="flex">
                  <Button
                    label="Back"
                    blue
                    onClick={ (): any => navigate( -1 ) }
                    icon={ <ArrowUturnLeftIcon className="w-4 h-4" /> }
                  />
                </div>
                <div className="flex space-x-4">
                  <Button label="Add new rank" yellow onClick={ (): any => navigate( '/settings/forum/ranks/add' ) } />
                </div>
              </div>

              { ( loading || reassigning ) && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }

              { !loading && !reassigning &&
                <>
                  { forumRanks?.map( ( forumRank: any, index: any ): any =>
                                       <div className="flex items-center justify-between w-full bg-white rounded-3xl p-3 xl:p-4" key={ index }>
                                         <div>{ forumRank.name } ({ forumRank.level })</div>

                                         <div className="flex flex-wrap items-center">
                                           <Button label="Edit" yellow onClick={ (): any => navigate( `/settings/forum/ranks/edit/${ forumRank.id }` ) } />
                                         </div>
                                       </div> ) }

                  { currentUser.email === 'dawid@denfield.co.uk' &&
                    <div className="flex justify-end w-full my-4">
                      <div className="flex">
                        <Button label="Reassign users forum points" yellow onClick={ (): any => reassignUsersPoints() } />
                      </div>
                    </div>
                  }
                </>
              }
            </>
          }
          { !currentUser?.admin &&
            <div className="row flex flex-col items-center w-full bg-white rounded-3xl p-3 xl:p-4">
              Access Denied!
            </div>
          }
        </div>
      </div>
    </MainLayout>
  )
}

import { useMutation }                     from '@apollo/client'
import { ArrowUturnLeftIcon }              from '@heroicons/react/24/solid'
import { useState }                        from 'react'
import { useDispatch, useSelector }        from 'react-redux'
import { useNavigate }                     from 'react-router-dom'
import { Button, TextField }               from '../'
import UserAvatar                          from '../../components/User/UserAvatar'
import { setToasts, showToaster }          from '../../features/toaster/toasterSlice'
import { DELETE_AVATAR_FILE, UPDATE_USER } from '../../graphql/user'
import Api                                 from '../../utilities/axios'
import { LoadingEllipsis }                 from '../Loaders'

export default function UserProfileForm( props: any ): JSX.Element {
  const { user }                          = props
  const navigate: any                     = useNavigate()
  const dispatch: any                     = useDispatch()
  const currentTenant: any                = useSelector( ( state: any ): any => state.currentTenant.tenant )
  const [ name, setName ]                 = useState( user ? user.name : '' )
  const [ nameError, setNameError ]       = useState( false )
  const [ fileToUpload, setFileToUpload ] = useState( null )
  const uploadApiUrl: string              = process.env.REACT_APP_UPLOAD_HOST

  const [ updateUser, { loading: updating } ] = useMutation( UPDATE_USER, {
    onCompleted: (): void => dispatchMessage( 'success', 'Profile updated!' ),
    onError:     (): void => dispatchMessage( 'error', 'Update failed. Please try again' )
  } )

  const [ deleteAvatarFile ] = useMutation( DELETE_AVATAR_FILE, {
    onError: ( e: any ): any => console.error( 'old avatar not deleted: ', e )
  } )

  const dispatchMessage: any = ( type: string, message: string ): void => {
    dispatch( setToasts( [ { id: '1', type, message } ] ) )
    dispatch( showToaster( true ) )
  }

  const resetErrors: any = (): void => {
    setNameError( false )
  }

  const handleFileChange: any = async ( e: any ): Promise<void> => {
    if ( e.target.files ) {
      const file: any = e.target.files[ 0 ]

      if ( file.size > 1048576 ) {
        alert( 'Max file size is 1MB. File NOT uploaded!' )
      } else {
        setFileToUpload( file )
      }
    }
  }

  const uploadAvatar: any = async (): Promise<any> => {
    const formData: FormData = new FormData()

    formData.append( 'file', fileToUpload )
    formData.append( 'is_public', 'true' )
    formData.append( 'folder', currentTenant.uuid + '/user/' + user.id + '/avatar' )

    try {
      const { data } = await Api( uploadApiUrl, 'multipart/form-data' ).post( '/', formData )

      return data[ 0 ]?.path?.length ? data[ 0 ].path : null
    } catch ( error ) {
      console.error( `Error uploading file (${ fileToUpload.name }):`, error )
      return null
    }
  }

  const handleSubmit: any = async (): Promise<void> => {
    resetErrors()

    if ( !name.length ) {
      setNameError( true )
      return
    }

    const formData: any = { name, id: user.id }

    try {
      if ( fileToUpload ) {
        formData.avatar_url = await uploadAvatar()

        if ( formData.avatar_url && user.avatar_url?.length ) {
          await deleteAvatarFile( { variables: { input: { path: user.avatar_url } } } )
        }
      }

      await updateUser( { variables: { input: formData } } )
    } catch ( e ) {
      console.log( 'e: ', e )
    }
  }

  return (
    <>
      <div className="w-24 my-4">
        <Button
          label="Back"
          blue
          onClick={ (): any => navigate( -1 ) }
          icon={ <ArrowUturnLeftIcon className="w-4 h-4" /> }
        />
      </div>

      { updating && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }

      { !updating &&
        <div className="flex flex-col items-center w-full bg-white rounded-3xl p-4 xl:p-5">
          <div className="flex flex-wrap items-center w-full mb-3">
            <div className="w-1/3">Avatar</div>
            <div className="w-2/3">
              <UserAvatar user={ user } />

              <div className="mb-4">
                <input id="file" type="file" accept="image/*" onChange={ handleFileChange } />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap items-center w-full mb-3">
            <div className="w-1/3">Name</div>
            <div className="w-2/3">
              <TextField
                label={ 'Name' }
                value={ name }
                name="title"
                type="text"
                onChange={ setName }
                errorField={ nameError ? 'name' : '' }
                isRequired
              />
            </div>
          </div>

          <div className="flex flex-wrap items-center w-full mb-3">
            <div className="w-1/3">Email</div>
            <div className="w-2/3">
              <TextField
                label={ 'Email' }
                value={ user.email }
                name="email"
                type="text"
                disabled
              />
            </div>
          </div>

          <div className="flex flex-wrap w-full mt-3">
            <div className="flex pt-5">
              <Button label="Submit" yellow onClick={ handleSubmit } />
            </div>
          </div>
        </div>
      }
    </>
  )
}

import { useMutation }                 from '@apollo/client'
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/20/solid'
import { useDispatch }                 from 'react-redux'
import { Link }                        from 'react-router-dom'
import { setToasts, showToaster }      from '../../features/toaster/toasterSlice'
import { DELETE_USER }                 from '../../graphql/user'
import { LoadingEllipsis }             from '../Loaders'

export default function UsersTableItem( props: any ): JSX.Element {
  const { user, accountType, refetch } = props
  const dispatch: any                  = useDispatch()

  const [ deleteUser, { loading: deleting } ] = useMutation( DELETE_USER, {
    onCompleted: (): void => {
      dispatchMessage( 'success', 'User deleted!' )
      refetch()
    }
  } )

  const dispatchMessage: any = ( type: string, message: string ): void => {
    dispatch( setToasts( [ { id: '1', type, message } ] ) )
    dispatch( showToaster( true ) )
  }

  const handleDelete: any = ( e: any ): void => {
    e.preventDefault()

    if ( window.confirm( 'Are you sure you want to delete this User?' ) ) {
      deleteUser( { variables: { input: { id: user.id, soft_delete: true } } } )
    }
  }

  return (
    <>
      { deleting &&
        <LoadingEllipsis klass="bg-white ellipse-yellow ellipse-xl p-4" />
      }
      { !deleting &&
        <div className={ `bg-white rounded-2xl p-2 2xl:p-4 w-full grid grid-users-table-cols-admin gap-4` }>
          <div className="flex text-blue-header">
            <Link to={ '/profile/' + user.id } className="text-blue-header">
              <span>{ user.name }</span>
            </Link>
          </div>

          <div className="flex">
            <a href={ `mailto:${ user.email }` }>
              <span className="text-blue-500 hover:text-blue-700 break-words">{ user.email }</span>
            </a>
          </div>

          <div className="flex text-blue-header">{ accountType?.name ?? '-' }</div>

          <div className="flex justify-center items-center">
            <Link to={ `/users-new/edit/${ user.id }` } className="flex p-0 mr-3 text-blue-secondary hover:text-blue-secondary-darker">
              <PencilSquareIcon className="w-4 h-4" />
            </Link>

            {/*<button*/}
            {/*  className="p-0 float-right hover:text-red-600"*/}
            {/*  color="default"*/}
            {/*  onClick={ handleDelete }*/}
            {/*>*/}
            {/*  <TrashIcon className="w-4 h-4" />*/}
            {/*</button>*/}
          </div>
        </div>
      }
    </>
  )
}

import { useEffect }                 from 'react'
import { useDispatch, useSelector }  from 'react-redux'
import { useNavigate }               from 'react-router-dom'
import { setForumListRefetch }       from '../../features/forum/communityForumSlice'
import Pagination                    from '../Pagination/Pagination'
import CommunityForumThreadListTable from './CommunityForumThreadListTable'

export default function CommunityForumHomeTable( props: any ): JSX.Element {
  const { categoryId, refetch, sortBy } = props
  const navigate: any                   = useNavigate()
  const dispatch: any                   = useDispatch()
  const threadList: any                 = useSelector( ( state: any ): any => state.communityForum )
  const currentUser: any                = useSelector( ( state: any ): any => state.currentUser.user )
  const forumCategories: any            = useSelector( ( state: any ): any => state.communityForum.categories )

  const urlFormat: any = ( paramsString: string = '' ): string => {
    let urlString: string = '/forum'
    const params: any     = new URLSearchParams( paramsString )

    if ( categoryId ) {
      params.set( 'category', categoryId )
    }

    if ( sortBy && !params.has( 'sortBy' ) ) {
      params.set( 'sortBy', sortBy )
    }

    return params ? `${ urlString }?${ params }` : urlString
  }

  const handleCategoryChange: any = ( catId: string ): void => {
    if ( !catId ) {
      navigate( '/forum' )
    } else if ( categoryId !== catId ) {
      navigate( `/forum?category=${ catId }` )
    }
  }

  const handleSortChange: any = ( sortById: string ): void => {
    if ( !sortById ) {
      navigate( urlFormat() )
    } else if ( sortBy !== sortById ) {
      navigate( urlFormat( `sortBy=${ sortById }` ) )
    }
  }

  const setCurrentPage: any = ( page: number = 1 ): any => navigate( urlFormat( `page=${ page }` ) )

  useEffect( (): any => {
    let shouldFetch: any = true

    if ( shouldFetch && threadList?.refetch ) {
      refetch()
      dispatch( setForumListRefetch( false ) )
    }

    return (): void => {
      shouldFetch = false
    }
  }, [ threadList ] )

  return (
    <>
      { threadList?.data.length < 1 && <p>No questions yet...</p> }

      { threadList?.data.length > 0 &&
        <CommunityForumThreadListTable
          sortable
          sortBy={ sortBy }
          threadList={ threadList.data }
          isAdmin={ currentUser.admin }
          categoryId={ categoryId }
          handleCategoryChange={ handleCategoryChange }
          handleSortChange={ handleSortChange }
          refetch={ refetch }
          forumCategories={ forumCategories }
        />
      }

      { ( threadList?.pagination.totalPages > 0 ) &&
        <Pagination
          className="justify-content-center"
          currentPage={ threadList.pagination.page }
          totalCount={ threadList.pagination.totalResults }
          pageSize={ threadList.pagination.perPage }
          onPageChange={ ( page: any ): any => setCurrentPage( page ) }
        />
      }
    </>
  )
}

import React, { forwardRef, Ref } from "react";
import PropTypes, { Requireable } from 'prop-types';
import classNames from "classnames";

interface ButtonProps {
  label: string;
  type?: 'button' | 'link';
  submittable?: boolean;
  shouldOpenInNewTab?: boolean;
  auto?: boolean;
  secondary?: boolean;
  blue?: boolean;
  yellow?: boolean;
  disabled?: boolean;
  clear?: boolean;
  loading?: boolean,
  onClick?: () => void;
  href?: string;
  icon?: React.ReactNode;
}

export const Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonProps>((props, ref) => {
  const AsLink = () => (
    <a
      ref={ref as Ref<HTMLAnchorElement>}
      aria-label={props.label}
      target={props.shouldOpenInNewTab ? '_blank' : '_self'}
      href={props.href}
      className={classNames("w-full h-full px-3 py-1.5 flex items-center justify-center gap-x-3", {
        'invisible': props.loading
      })}
    >
      <>
        {props.icon ? props.icon : null}
        <span>{props.label}</span>
      </>
    </a>
  );

  const AsButton = () => (
    <button
      disabled={ props.disabled }
      type={props.submittable ? 'submit' : 'button'}
      ref={ref as Ref<HTMLButtonElement>}
      onClick={props.onClick}
      className={classNames("w-full h-full px-3 py-1.5 flex items-center justify-center gap-x-3", {
        'text-blue-input': props.yellow,
        'invisible': props.loading
      })}
    >
      <>
        {props.icon ? props.icon : null}
        <span>{props.label}</span>
      </>
    </button>
  );

  const LoadingState = () => props.loading && (
    <div className="absolute">
      <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z" fill="currentColor"></path>
      </svg>
    </div>
  );

  return (
    <div className={classNames("Button relative text-white font-medium flex items-center justify-center rounded-full text-center text-sm", {
      'w-full': !props.auto,
      'w-fit': props.auto,
      'bg-none border !text-black hover:bg-gray-50': props.clear,
      'text-white bg-blue-secondary': props.blue,
      'text-blue-input bg-yellow-300': props.yellow,
      'bg-blue-600 hover:bg-blue-500 text-white': !props.blue && !props.yellow && !props.clear && !props.secondary && !props.disabled,
      'bg-gray-200 !text-gray-600': props.secondary || props.disabled
    })}
    >
      <LoadingState />
      {props.type === 'button' ? <AsButton /> : <AsLink />}
    </div>
  );
});

Button.defaultProps = {
  type: 'button',
  submittable: false,
  shouldOpenInNewTab: false,
  auto: false,
  clear: false,
  secondary: false
};

Button.propTypes = {
    auto: PropTypes.bool,
    clear: PropTypes.bool,
    secondary: PropTypes.bool,
    label: PropTypes.string.isRequired,
    submittable: PropTypes.bool,

    // Not required as button can be a link
    onClick: PropTypes.func,

    // Opens web link in a new tab
    shouldOpenInNewTab: PropTypes.bool,

    icon: PropTypes.node,

    // When link, href must be provided
    href: function(props, propName, componentName) {
        if ((props['type'] == "link" && (props[propName] == undefined || typeof(props[propName]) != 'string'))) {
            return new Error('Please provide a href for this link!');
        }
    },

    type: PropTypes.oneOf(['button', 'link'])
};
import { useLazyQuery }              from '@apollo/client'
import { useEffect, useState }       from 'react'
import { useSelector }               from 'react-redux'
import { GET_FORUM_THREAD_LIST }     from '../../graphql/communityForum'
import CommunityForumThreadListTable from '../Forum/CommunityForumThreadListTable'
import { LoadingEllipsis }           from '../Loaders'
import Pagination                    from '../Pagination/Pagination'

export default function UserProfileCommunityForumQuestionsTab( props: any ): JSX.Element {
  const { user, forumCategories }                                     = props
  const currentUser: any                                              = useSelector( ( state: any ): any => state.currentUser.user )
  const [ forumThreadsList, setForumThreadsList ]                     = useState( null )
  const [ forumThreadsListPagination, setForumThreadsListPagination ] = useState( null )
  const [ page, setPage ]                                             = useState( 1 )

  const [ fetchForumQuestions, { loading, error, refetch } ] = useLazyQuery( GET_FORUM_THREAD_LIST, {
    onCompleted: ( { forumThreadList }: any ): void => {
      setForumThreadsList( forumThreadList.data )
      setForumThreadsListPagination( forumThreadList.links )
    }
  } )

  useEffect( (): any => {
    let shouldFetch: boolean = true

    if ( shouldFetch && user ) {
      fetchForumQuestions( { variables: { input: { author_id: user.id, page: ( page > 0 ) ? page : 1 } } } )
    }

    return (): void => {
      shouldFetch = false
    }
  }, [ user, page ] )

  return (
    <>
      { loading && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }
      { forumThreadsList?.length < 1 && <p>No questions added yet...</p> }
      { !loading && ( forumThreadsList?.length > 0 ) &&
        <CommunityForumThreadListTable
          isAdmin={ currentUser.admin }
          sortable={ false }
          threadList={ forumThreadsList }
          forumCategories={ forumCategories }
          refetch={ refetch }
        />
      }
      { ( forumThreadsListPagination?.totalPages > 0 ) &&
        <Pagination
          className="justify-content-center"
          currentPage={ forumThreadsListPagination.page }
          totalCount={ forumThreadsListPagination.totalResults }
          pageSize={ forumThreadsListPagination.perPage }
          onPageChange={ ( page: any ): any => setPage( page ) }
        />
      }
      { error &&
        <div className="flex flex-col w-full bg-white rounded-xl p-4 lg:p-5">
          <b>Error occurred while fetching Forum Threads:</b>
          { error.graphQLErrors.map( ( { message }: any, i: number ): any => (
            <div key={ i } className="flex w-full"><span>{ message }</span></div>
          ) ) }
        </div>
      }
    </>
  )
}

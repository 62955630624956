import { useLazyQuery }                                         from '@apollo/client'
import { ArrowDownTrayIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import React, { useEffect, useState }                           from 'react'
import { useDispatch, useSelector }                             from 'react-redux'
import 'slick-carousel/slick/slick.css'
import Slider                                                   from 'react-slick'
import { setThreadAttachmentsRefetch }                          from '../../features/forum/communityForumSlice'
import { GET_FORUM_THREAD_ATTACHMENTS }                         from '../../graphql/communityForum'
import { assetFilePath, prefetchFileAndDownload }               from '../../utilities'
import { Image }                                                from '../Image'
import { LoadingEllipsis }                                      from '../Loaders'
import CommunityForumModal                                      from '../Modal/CommunityForumModal'
import Pagination                                               from '../Pagination/Pagination'
import CommunityForumThreadSingleAttachment                     from './CommunityForumThreadSingleAttachment'

export default function CommunityForumThreadMediaTab( props: { threadId: string } ): JSX.Element {
  const { threadId }                                        = props
  const dispatch: any                                       = useDispatch()
  const [ attachments, setAttachments ]                     = useState( null )
  const [ galleryItems, setGalleryItems ]                   = useState( null )
  const [ showGallery, setShowGallery ]                     = useState( false )
  const [ initialSlide, setInitialSlide ]                   = useState( 0 )
  const [ attachmentsPagination, setAttachmentsPagination ] = useState( null )
  const threadAttachmentsRefetch: any                       = useSelector( ( state: any ): any => state.communityForum.threadAttachmentsRefetch )
  const appCdnUrl: string                                   = process.env.REACT_APP_CDN + ( process.env.REACT_APP_CDN.slice( -1 ) === '/' ? '' : '/' )

  const [ getAttachments, { loading, error } ] = useLazyQuery( GET_FORUM_THREAD_ATTACHMENTS, {
    notifyOnNetworkStatusChange: true,
    onCompleted:                 async ( { forumAttachments }: any ): Promise<void> => {
      setAttachments( forumAttachments.data )
      setAttachmentsPagination( forumAttachments.links )
      setGalleryItems( forumAttachments.data.filter( ( attachment: any ): any => attachment.type === 'image' ) )
    }
  } )

  const fetchAttachments: any = ( page: number = 1, thread_id: string = threadId ): void => {
    getAttachments( { variables: { input: { page, thread_id } } } )
  }

  const handleAttachment: any = ( attachment: any ): any => {
    if ( attachment.type === 'application' ) {
      window.open( appCdnUrl + attachment.url, '_blank' ).focus()
    }

    if ( attachment.type === 'image' ) {
      const index: number = galleryItems.findIndex( ( item: any ): any => item.id === attachment.id )

      setInitialSlide( index )
      setShowGallery( true )
    }
  }

  const arrowsStyles: string = 'flex items-center justify-center absolute w-24 h-3/4 top-1/2 transform -translate-y-1/2 cursor-pointer bg-white z-10 opacity-0 hover:opacity-50'

  const NextArrow: any = ( props: any ): any => {
    const { className, onClick } = props
    return (
      <div
        className={ `${ arrowsStyles } right-0 ${ className }` }
        onClick={ onClick }
      >
        <ChevronRightIcon className="w-12 h-12 text-black" />
      </div>
    )
  }

  const PrevArrow: any = ( props: any ): any => {
    const { className, onClick } = props
    return (
      <div
        className={ `${ arrowsStyles } left-0 ${ className }` }
        onClick={ onClick }
      >
        <ChevronLeftIcon className="w-12 h-12 text-black" />
      </div>
    )
  }

  const sliderSettings = {
    infinite:       true,
    arrows:         true,
    dots:           false,
    speed:          500,
    slidesToShow:   1,
    slidesToScroll: 1,
    swipeToSlide:   true,
    nextArrow:      <NextArrow />,
    prevArrow:      <PrevArrow />
  }

  useEffect( (): any => {
    let shouldFetchAttachments: boolean = true

    if ( ( shouldFetchAttachments && !attachments?.length ) || threadAttachmentsRefetch ) {
      fetchAttachments( 1, threadId )
      dispatch( setThreadAttachmentsRefetch( false ) )
    }

    return (): void => {
      shouldFetchAttachments = false
    }
  }, [ threadId ] )

  return (
    <>
      { loading && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }

      { !loading && ( attachments?.length > 0 ) &&
        <div className="row flex flex-col items-center w-full bg-white rounded-3xl p-4 lg:p-5">
          <div className="col flex flex-col w-full">
            <div className="grid grid-cols-4 gap-4 lg:gap-5">
              { attachments.map( ( attachment: any ): any =>
                                   <CommunityForumThreadSingleAttachment
                                     attachment={ attachment }
                                     key={ attachment.id }
                                     handleAttachment={ (): any => handleAttachment( attachment ) }
                                   />
              ) }
            </div>

            { ( attachmentsPagination?.totalPages > 0 ) &&
              <Pagination
                className="justify-content-center pt-2"
                currentPage={ attachmentsPagination.page }
                totalCount={ attachmentsPagination.totalResults }
                pageSize={ attachmentsPagination.perPage }
                onPageChange={ ( page: number ): Promise<void> => fetchAttachments( page ) }
              />
            }
          </div>
        </div>
      }

      { !loading && attachments?.length === 0 &&
        <div className="row flex items-center w-full bg-white rounded-xl p-4 lg:p-5">
          <div className="col flex flex-col w-full">
            No media in this question yet...
          </div>
        </div>
      }

      { galleryItems &&
        <CommunityForumModal
          ID="cf-media-slider"
          open={ showGallery }
          onClose={ (): any => setShowGallery( false ) }
          content={ <Slider { ...{ initialSlide, ...sliderSettings } }>
            { galleryItems.map( ( item: any ): any =>
                                  <div className="thread-media-image" key={ item.id }>
                                    <button
                                      onClick={ async (): Promise<void> => await prefetchFileAndDownload( item.url, `${ item.name }.${ item.file_ext }`, true ) }
                                      className="absolute z-10 p-[10px] left-0 top-0 bg-white opacity-50 hover:opacity-75"
                                    >
                                      <ArrowDownTrayIcon className="w-5 h-5" />
                                    </button>
                                    <Image
                                      src={ appCdnUrl + item.url }
                                      alt={ item.name }
                                      defaultSrc={ assetFilePath( item.file_ext ) }
                                    />
                                  </div>
            ) }
          </Slider> }
        />
      }

      { error &&
        <div className="flex flex-col w-full bg-white rounded-xl p-4 lg:p-5">
          <b>Error occurred while fetching Forum Thread Media:</b>
          { error.graphQLErrors.map( ( { message }: any, i: number ): any => (
            <div key={ i } className="flex w-full"><span>{ message }</span></div>
          ) ) }
        </div>
      }
    </>
  )
}

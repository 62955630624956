import { useQuery }              from '@apollo/client'
import PropTypes                 from 'prop-types'
import React, { memo, useState } from 'react'
import { useDispatch }           from 'react-redux'
import Nav                       from '../components/Nav/Nav'
import { Toast }                 from '../components/Toast'
import { setCurrentUser }        from '../features/user/currentUserSlice'
import { setView }          from '../features/user/userViewSlice'
import { GET_CURRENT_USER } from '../graphql/user'
import PageLoader           from '../loaders/page'

interface MainLayoutProps
{
  children: React.ReactNode;
  scrollable?: boolean;
}

/**
 * Global layout.
 *
 * @param {*} props
 * @returns HTML Node
 */
const MainLayout: React.FC<MainLayoutProps> = memo( ( props ) => {
  const [ user, setUser ] = useState( null )
  const dispatch: any     = useDispatch()

  const { loading, data } = useQuery( GET_CURRENT_USER, {
    onCompleted: ( { currentUser }: any ): void => {
      // Keep a local reference of the user here.
      setUser( currentUser )
      dispatch( setCurrentUser( currentUser ) )
    },
    onError:     (): any => {
      console.error('User NOT loaded')
      window.location.href = constructRedirectURL()
    }
  } )

  /**
   * Toggles the current user's view. This is called from the Header component.
   *
   * Ensure all pages inherits MainLayout and get the current user from state:
   * const currentUser = useSelector((state: any) => state.currentUser.user);
   */
  const toggleUserAsAdmin = () => {
    // Deep clone.
    let _user   = { ...user }
    _user.admin = !_user.admin
    setUser( _user )
    dispatch( setCurrentUser( _user ) )
    // This should refetch data if the data/permissions was already set from server based on if the user is admin or not.
    dispatch( setView() )
  }

  const constructRedirectURL = () => {
    const href = window.location.href

    if ( !user ) {
      return !href.includes( '/login' ) ? `/login?next=${ href }` : '/login'
    }

    return '/login'
  }

  const render = () => (
    <>
      <div className="MainLayout bg-blue-subtle flex h-screen flex-row">
        <Nav />

        <main className={ `w-full h-screen pt-12 overflow-y-auto sm:px-0 flex flex-col relative` }>
          <div className="flex flex-grow h-screen">
            <div className="flex flex-col overflow-y-auto h-screen flex-1">
              { props.children }
            </div>
          </div>

          {/* <MainFloatingMenu /> */ }
          {/* Controlled by redux */ }

          <Toast />
        </main>

        <button id="adminUserViewToggle" onClick={ toggleUserAsAdmin } className="hidden absolute h-0 select-none" type="button" />
      </div>
    </>
  )

  return loading && !data?.currentUser ? PageLoader() : render()
} )

MainLayout.propTypes = {
  children: PropTypes.oneOfType( [
                                   PropTypes.arrayOf( PropTypes.node ),
                                   PropTypes.node
                                 ] ).isRequired,

  scrollable: PropTypes.bool

}

export default MainLayout

import { useMutation }                          from '@apollo/client'
import { ArrowUturnLeftIcon }                   from '@heroicons/react/24/solid'
import { useState }                             from 'react'
import { useDispatch }                          from 'react-redux'
import { useNavigate }                          from 'react-router-dom'
import { Button, TextField }                    from '../'
import { setForumRanksRefetch }                 from '../../features/forum/communityForumSlice'
import { setToasts, showToaster }               from '../../features/toaster/toasterSlice'
import { CREATE_FORUM_RANK, UPDATE_FORUM_RANK } from '../../graphql/communityForum'
import { LoadingEllipsis }                      from '../Loaders'

export default function SettingsForumRankForm( props: any ): JSX.Element {
  const { forumRank }                                 = props
  const dispatch: any                                 = useDispatch()
  const navigate: any                                 = useNavigate()
  const [ forumRankName, setForumRankName ]           = useState( forumRank?.name ?? '' )
  const [ forumRankLevel, setForumRankLevel ]         = useState( forumRank?.level ? `${ forumRank.level }` : '0' )
  const [ forumRankThreshold, setForumRankThreshold ] = useState( forumRank?.threshold ? `${ forumRank.threshold }` : '0' )
  const [ nameError, setNameError ]                   = useState( false )
  const [ levelError, setLevelError ]                 = useState( false )
  const [ thresholdError, setThresholdError ]         = useState( false )

  const resetErrors: any = (): void => {
    setNameError( false )
    setLevelError( false )
    setThresholdError( false )
  }

  const checkErrors: any = (): boolean => {
    resetErrors()

    let hasErrors: boolean = false

    if ( !forumRankName.length || forumRankName.length < 3 ) {
      setNameError( true )
      hasErrors = true
    }

    if ( !forumRankLevel ) {
      setLevelError( true )
      hasErrors = true
    }

    if ( !forumRankThreshold ) {
      setThresholdError( true )
      hasErrors = true
    }

    return hasErrors
  }

  const handleSubmit: any = async (): Promise<any> => {
    if ( checkErrors() ) return

    if ( forumRank?.name === forumRankName &&
         forumRank?.level === forumRankLevel &&
         forumRank?.threshold === forumRankThreshold
    ) return

    const formData: any = {
      name:      forumRankName,
      level:     parseInt( forumRankLevel ),
      threshold: parseInt( forumRankThreshold )
    }

    if ( forumRank ) {
      formData.id = forumRank.id
      await updateForumRank( { variables: { input: formData } } )
    } else {
      await createForumRank( { variables: { input: formData } } )
    }

    await dispatch( setForumRanksRefetch( true ) )
  }

  const [ createForumRank, { loading: creating } ] = useMutation( CREATE_FORUM_RANK, {
    onCompleted: (): void => dispatchMessage( 'success', 'Forum rank created!' ),
    onError:     (): void => dispatchMessage( 'error', 'Creating failed. Please try again' )
  } )

  const [ updateForumRank, { loading: updating } ] = useMutation( UPDATE_FORUM_RANK, {
    onCompleted: (): void => dispatchMessage( 'success', 'Forum rank updated!' ),
    onError:     (): void => dispatchMessage( 'error', 'Update failed. Please try again' )
  } )

  const dispatchMessage: any = ( type: string, message: string ): void => {
    dispatch( setToasts( [ { id: '1', type, message } ] ) )
    dispatch( showToaster( true ) )
  }

  return (
    <>
      <div className="w-24 my-4">
        <Button
          label="Back"
          blue
          onClick={ (): any => navigate( -1 ) }
          icon={ <ArrowUturnLeftIcon className="w-4 h-4" /> }
        />
      </div>
      <div className="flex flex-col w-full bg-white rounded-3xl p-4 xl:p-5 space-y-3">
        { ( creating || updating ) && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }

        { !creating && !updating &&
          <>
            <div className="flex flex-wrap items-center w-full mb-6">
              <div className="text-blue-header w-full mb-3">Name</div>
              <TextField
                label=""
                value={ forumRankName }
                name="name"
                type="text"
                onChange={ setForumRankName }
                placeholder="Name"
                wrapperClasses="w-full"
                fieldClasses={ `py-4 px-5 border ${ nameError ? 'border-red-600' : 'border-grey-light' } rounded-2xl w-full text-blue-input font-medium placeholder-grey-input` }
                isRequired
              />
            </div>

            <div className="flex flex-wrap items-center w-full mb-6">
              <div className="text-blue-header w-full mb-3">Level</div>
              <TextField
                label=""
                value={ forumRankLevel }
                name="level"
                type="number"
                onChange={ setForumRankLevel }
                placeholder="Level"
                wrapperClasses="w-full"
                fieldClasses={ `py-4 px-5 border ${ levelError ? 'border-red-600' : 'border-grey-light' } rounded-2xl w-full text-blue-input font-medium placeholder-grey-input` }
                isRequired
              />
            </div>

            <div className="flex flex-wrap items-center w-full mb-6">
              <div className="text-blue-header w-full mb-3">Threshold</div>
              <TextField
                label=""
                value={ forumRankThreshold }
                name="threshold"
                type="number"
                onChange={ setForumRankThreshold }
                placeholder="Threshold"
                wrapperClasses="w-full"
                fieldClasses={ `py-4 px-5 border ${ thresholdError ? 'border-red-600' : 'border-grey-light' } rounded-2xl w-full text-blue-input font-medium placeholder-grey-input` }
                isRequired
              />
            </div>

            <div className="flex flex-wrap w-full">
              <div className="flex pt-5">
                <Button label={ forumRank ? 'Update rank' : 'Add new rank' } yellow onClick={ handleSubmit } />
              </div>
            </div>
          </>
        }
      </div>
    </>
  )
}

import { useLazyQuery }             from '@apollo/client'
import { useEffect }                from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link }                     from 'react-router-dom'
import { setAccountTypes }          from '../../features/user/currentUserSlice'
import { ACCOUNT_TYPES }            from '../../graphql/account_type'
import UserAvatar                   from '../User/UserAvatar'
import CommunityForumBadge          from './CommunityForumBadge'

export default function CommunityForumUserInfo( props: any ): JSX.Element {
  const { compact, list, user } = props
  const dispatch: any     = useDispatch()
  const accountTypes: any = useSelector( ( state: any ): any => state.currentUser.accountTypes )
  const accountType: any  = accountTypes.find( ( at: any ): any => at.id === user.account_type_id )

  const [ getAccountTypes, { loading, error } ] = useLazyQuery( ACCOUNT_TYPES, {
    onCompleted: ( { accountTypes }: any ): void => dispatch( setAccountTypes( accountTypes ) )
  } )

  useEffect( (): any => {
    let shouldFetch: boolean = true

    if ( shouldFetch && !accountTypes?.length ) {
      getAccountTypes()
    }

    return (): void => {
      shouldFetch = false
    }
  }, [ accountTypes ] )

  return (
    <>
      { !loading && user &&
        <div className={ `flex ${ compact ? '' : 'flex-col justify-center ' }${ list ? 'shadow-soft p-2 xl:p-4 ' : '' }items-center w-full rounded-2xl mb-2` }>
          <Link to={ `/profile/${ user.id }` }>
            <UserAvatar user={ user } minSize={ 24 } maxSize={ 24 } />
          </Link>
          <div className={ `flex flex-col${ compact ? ' ml-2' : '' }` }>
            <Link to={ `/profile/${ user.id }` } className={ list ? ' mb-2' : '' }>
              <div className={ `${ compact ? 'text-lg leading-none' : 'text-center text-2xl mt-2 2xl:mt-4' } text-blue-header font-bold w-full` }>{ user.name }</div>
            </Link>
            { !list &&
              <div className={ `${ compact ? 'text-xs my-1' : 'text-center text-sm mb-2' } text-blue-header font-medium w-full` }>{ accountType?.name ?? 'Member' }</div>
            }
            <CommunityForumBadge compact={ compact } points={ user.forum_points } rankId={ user.forum_rank_id } />
          </div>
        </div>
      }
      { error &&
        <div className="flex flex-col w-full bg-white rounded-xl p-4 lg:p-5">
          <b>Error occurred when fetching Account Types:</b>
          { error.graphQLErrors.map( ( { message }: any, i: number ): any => (
            <div key={ ( i + 1000 ) } className="flex w-full"><span>{ message }</span></div>
          ) ) }
        </div>
      }
    </>
  )
}

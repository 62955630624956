import { gql } from '@apollo/client'

const AccountTypeFragment: any = gql`
  fragment AccountTypeData on AccountType {
    id
    name
    created_at
    updated_at
  }
`

export const ACCOUNT_TYPES: any = gql`
  query AccountType {
    accountTypes {
      ...AccountTypeData
    }
  }
  ${ AccountTypeFragment }
`

export const CREATE_ACCOUNT_TYPE: any = gql`
  mutation CreateAccountType($input: MutationCreateAccountTypeInput!) {
    createAccountType(input: $input) {
      __typename
      ... on FieldError {
        field
        message
      }
      ... on MutationCreateAccountTypeSuccess {
        data {
          ...AccountTypeData
        }
      }
    }
  }
  ${ AccountTypeFragment }
`

export const UPDATE_ACCOUNT_TYPE: any = gql`
  mutation UpdateAccountType($input: MutationUpdateAccountTypeInput!) {
    updateAccountType(input: $input) {
      __typename
      ... on FieldError {
        field
        message
      }
      ... on MutationUpdateAccountTypeSuccess {
        data {
          ...AccountTypeData
        }
      }
    }
  }
  ${ AccountTypeFragment }
`

export const DELETE_ACCOUNT_TYPE: any = gql`
  mutation DeleteAccountType($input: MutationDeleteAccountTypeInput!) {
    deleteAccountType(input: $input) {
      __typename
      ... on FieldError {
        field
        message
      }
      ... on MutationDeleteAccountTypeSuccess {
        data
      }
    }
  }
`

import { useSelector }     from 'react-redux'
import { LoadingEllipsis } from '../../components/Loaders'
import UserProfileForm     from '../../components/User/UserProfileForm'
import MainLayout          from '../../layouts/MainLayout'

export default function UserProfileEdit(): JSX.Element {
  const currentUser: any = useSelector( ( state: any ): any => state.currentUser.user )

  return (
    <MainLayout>
      <div className="max-w-screen-3xl mx-auto w-full">
        <div className="flex-grow xl:p-8 p-3 space-y-4 xl:space-y-8">
          { !currentUser && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4 mt-4 w-full" /> }
          { currentUser && <UserProfileForm user={ currentUser } /> }
        </div>
      </div>
    </MainLayout>
  )
}

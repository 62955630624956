import { useLazyQuery }                     from '@apollo/client'
import { useEffect }                        from 'react'
import { useDispatch, useSelector }         from 'react-redux'
// @ts-ignore
import { ReactComponent as CrownIcon }      from '../../assets/svgs/badge-crown.svg'
// @ts-ignore
import { ReactComponent as LeaderIcon }     from '../../assets/svgs/badge-leader.svg'
// @ts-ignore
import { ReactComponent as MoonIcon }       from '../../assets/svgs/badge-moon.svg'
// @ts-ignore
import { ReactComponent as RisingStarIcon } from '../../assets/svgs/badge-star.svg'
import { setForumRanks }                    from '../../features/forum/communityForumSlice'
import { GET_FORUM_RANKS }                  from '../../graphql/communityForum'

export default function CommunityForumBadge( props: any ): JSX.Element {
  const { compact, rankId, points } = props
  const dispatch: any               = useDispatch()
  const forumRanks: any             = useSelector( ( state: any ): any => state.communityForum.ranks )
  const rank: any                   = forumRanks.find( ( rank: any ): any => rank.id === rankId )

  const [ getRanks, { loading, error } ] = useLazyQuery( GET_FORUM_RANKS, {
    notifyOnNetworkStatusChange: true,
    onCompleted:                 ( { forumRanks }: any ): void => dispatch( setForumRanks( forumRanks ) )
  } )

  const resolveStyle: any = ( type: string ): string => {
    if ( rank ) {
      switch ( rank.level ) {
        case 1:
          return ( type === 'text' ) ? 'text-badge-moon' : 'bg-badge-moon'
        case 2:
          return ( type === 'text' ) ? 'text-badge-star' : 'bg-badge-star'
        case 3:
          return ( type === 'text' ) ? 'text-badge-leader' : 'bg-badge-leader'
        case 4:
          return ( type === 'text' ) ? 'text-badge-crown' : 'bg-badge-crown'
        default:
          return ( type === 'text' ) ? 'text-badge-moon' : 'bg-badge-moon'
      }
    } else {
      return ( type === 'text' ) ? 'text-badge-moon' : 'bg-badge-moon'
    }
  }

  const icons: any = {
    1: MoonIcon,
    2: RisingStarIcon,
    3: LeaderIcon,
    4: CrownIcon
  }

  const UserBadge: any = icons[ rank?.level ?? 1 ]

  useEffect( (): any => {
    let shouldFetch: boolean = true

    if ( shouldFetch && !forumRanks?.length ) {
      getRanks()
    }

    return (): void => {
      shouldFetch = false
    }
  }, [ forumRanks ] )

  return (
    <div className="flex items-center justify-center">
      { !loading && rank &&
        <div className={ `flex items-center justify-center py-0.5 ${ compact ? 'px-1' : 'px-1.5' } ${ resolveStyle( 'bg' ) } rounded-full shadow-soft` }>
          <div className="flex items-center justify-center py-1 pl-3 pr-4 bg-white rounded-full">
            <UserBadge className={ `w-5 h-5 ${ resolveStyle( 'text' ) }` } />
            <div className={ `${ resolveStyle( 'text' ) } ${ compact ? 'text-sm ' : '' }font-bold ml-2` }>{ rank.name }</div>
          </div>
          <div className={ `${ compact ? 'text-sm py-1.5' : 'py-2' } pl-2 text-white font-semibold pr-3` }>{ points } points</div>
        </div>
      }

      { error &&
        <div className="flex flex-col w-full bg-white rounded-xl p-4 lg:p-5">
          <b>Error occurred when fetching Forum Ranks:</b>
          { error.graphQLErrors.map( ( { message }: any, i: number ): any => (
            <div key={ ( i + 1000 ) } className="flex w-full"><span>{ message }</span></div>
          ) ) }
        </div>
      }
    </div>
  )
}

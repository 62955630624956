import { UserCircleIcon } from '@heroicons/react/24/outline'

export default function UserAvatar( props: any ): JSX.Element {
  const { user, minSize, maxSize } = props

  const wrapperStyle: string = `flex items-center justify-center w-${ minSize ?? '16' } h-${ minSize ?? '16' } lg:w-${ maxSize ?? '24' } lg:h-${ maxSize ?? '24' }`
  const imageStyle: string   = `max-w-${ minSize ?? '16' } max-h-${ minSize ?? '16' } lg:max-w-${ maxSize ?? '24' } lg:max-h-${ maxSize ?? '24' } rounded-2xl`
  const iconStyle: string    = `w-${ minSize ?? '16' } h-${ minSize ?? '16' } lg:w-${ maxSize ?? '24' } lg:h-${ maxSize ?? '24' }`

  const appCdnUrl: string = process.env.REACT_APP_CDN + ( process.env.REACT_APP_CDN.slice( -1 ) === '/' ? '' : '/' )

  return (
    <>
      { user.avatar_url &&
        <div className={ wrapperStyle }>
          <img
            className={ imageStyle }
            src={ appCdnUrl + user.avatar_url }
            alt={ user.name }
          />
        </div>
      }
      { !user.avatar_url && <UserCircleIcon className={ iconStyle } /> }
    </>
  )
}

import { useLazyQuery }        from '@apollo/client'
import { useEffect, useState } from 'react'
import { useSelector }         from 'react-redux'
import { useParams }           from 'react-router-dom'
import { LoadingEllipsis }     from '../../components/Loaders'
import UserEditForm            from '../../components/Users/UserEditForm'
import { GET_USER_BY_ID }      from '../../graphql/user'
import MainLayout              from '../../layouts/MainLayout'

export default function UsersEdit(): JSX.Element {
  const { user_id }       = useParams()
  const currentUser: any  = useSelector( ( state: any ): any => state.currentUser.user )
  const [ user, setUser ] = useState( null )

  const [ getUserById, { loading } ] = useLazyQuery( GET_USER_BY_ID, {
    onCompleted: ( { userById }: any ): void => setUser( userById )
  } )

  useEffect( (): any => {
    let shouldFetch: boolean = true

    if ( shouldFetch && user_id ) {
      getUserById( { variables: { input: { id: user_id } } } )
    }

    return (): void => {
      shouldFetch = false
    }
  }, [ user_id ] )

  return (
    <MainLayout>
      <div className="max-w-screen-3xl mx-auto w-full">
        <div className="flex-grow xl:p-8 p-3 space-y-4 xl:space-y-8">
          { loading && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4 mt-4 w-full" /> }
          { !loading && currentUser?.admin && user && <UserEditForm user={ user } /> }
          { !loading && !currentUser?.admin &&
            <div className="row flex flex-col items-center w-full bg-white rounded-3xl p-3 xl:p-4">
              Access Denied!
            </div>
          }
        </div>
      </div>
    </MainLayout>
  )
}

export default function Hero( props: any ): JSX.Element {
  const { title, description, link, ctaTitle } = props

  const imageUrl: string = process.env.REACT_APP_CDN + '/assets/Banana_Moon_Top_100-3000x1080.jpg'

  return (
    <div
      style={ { backgroundImage:    `url(${ imageUrl })` } }
      className="hero-banner flex flex-col w-full min-h-[290px] rounded-xl overlow-hidden relative"
    >
      <div className="flex-1 flex-grow overlow-hidden max-w-sm relative rounded-l-xl h-full w-full bg-gradient-to-r from-blue-primary/90 sm:p-10 p-5">
        <div className="space-y-6 text-white">
          <h1 className="text-3xl font-semibold">{ title }</h1>
          <p>{ description }</p>

          { link?.length &&
            <a href={ link } className="inline-block px-4 py-3 bg-yellow-primary text-sm font-medium rounded-full text-blue-primary">{ ctaTitle }</a>
          }
        </div>
      </div>
    </div>
  )
}

// slice reducer
import { createSlice } from '@reduxjs/toolkit'

export const currentTenantSlice = createSlice( {
  name:         'currentTenant',
  initialState: {
    tenant:    null,
    locations: []
  },
  reducers:     {
    setCurrentTenant: ( state, action ) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.tenant = action.payload
    },
    setLocations:     ( state, action ) => {
      state.locations = action.payload
    }
  }
} )

// Action creators are generated for each case reducer function
export const { setCurrentTenant, setLocations } = currentTenantSlice.actions

export default currentTenantSlice.reducer
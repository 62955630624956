import {
  $applyNodeReplacement,
  type DOMConversionMap,
  type DOMConversionOutput,
  type DOMExportOutput,
  type EditorConfig,
  type LexicalNode,
  type NodeKey,
  type SerializedTextNode,
  type Spread,
  TextNode
} from 'lexical'

export type SerializedMentionNode = Spread<
  {
    mentionName: string;
  },
  SerializedTextNode
>;

function $convertMentionElement(
  domNode: HTMLElement
): DOMConversionOutput | null
{
  const textContent: string = domNode.textContent

  if ( textContent !== null ) {
    const node: MentionNode = $createMentionNode( textContent )
    return {
      node
    }
  }

  return null
}

const mentionStyle = 'background-color: rgba(24, 119, 232, 0.2)'

export class MentionNode extends TextNode
{
  __mention: string
  __mention_id: string
  __mention_email: string

  static getType(): string
  {
    return 'mention'
  }

  static clone( node: MentionNode ): MentionNode
  {
    return new MentionNode( node, node.__text, node.__key )
  }

  static importJSON( serializedNode: SerializedMentionNode ): MentionNode
  {
    const node: MentionNode = $createMentionNode( serializedNode.mentionName )
    node.setTextContent( serializedNode.text )
    node.setFormat( serializedNode.format )
    node.setDetail( serializedNode.detail )
    node.setMode( serializedNode.mode )
    node.setStyle( serializedNode.style )
    return node
  }

  constructor( mention: any, text?: string, key?: NodeKey )
  {
    super( text ?? mention.name, key )
    this.__mention = mention.name
    this.__mention_id = mention.id
    this.__mention_email = mention.email
  }

  exportJSON(): SerializedMentionNode
  {
    return {
      ...super.exportJSON(),
      mentionName: this.__mention,
      type:        'mention',
      version:     1
    }
  }

  createDOM( config: EditorConfig ): HTMLElement
  {
    const dom: any    = super.createDOM( config )

    dom.style.cssText = mentionStyle
    dom.className     = 'lexical-mention mention'

    dom.setAttribute( 'data-lexical-mention-id', this.__mention_id )
    dom.setAttribute( 'data-lexical-mention-name', this.__mention )
    dom.setAttribute( 'data-lexical-mention-email', this.__mention_email )

    return dom
  }

  exportDOM(): DOMExportOutput
  {
    const element: any = document.createElement( 'span' )

    element.className     = 'user-mention font-bold text-input-header'

    element.setAttribute( 'data-lexical-mention', 'true' )

    element.textContent = `@${ this.__text }`

    return { element }
  }

  static importDOM(): DOMConversionMap | null
  {
    return {
      span: ( domNode: HTMLElement ): any => {
        if ( !domNode.hasAttribute( 'data-lexical-mention' ) ) {
          return null
        }
        return {
          conversion: $convertMentionElement,
          priority:   1
        }
      }
    }
  }

  isTextEntity(): true
  {
    return true
  }

  canInsertTextBefore(): boolean
  {
    return false
  }

  canInsertTextAfter(): boolean
  {
    return false
  }
}

export function $createMentionNode( mention: any ): MentionNode
{
  const mentionNode = new MentionNode( mention )
  mentionNode.setMode( 'segmented' ).toggleDirectionless()
  return $applyNodeReplacement( mentionNode )
}

export function $isMentionNode(
  node: LexicalNode | null | undefined
): node is MentionNode
{
  return node instanceof MentionNode
}

import { gql } from '@apollo/client'

const UserFragment: any = gql`
  fragment UserData on User {
    id
    roi_id
    avatar_url
    forum_rank_id
    forum_points
    account_type_id
    director
    location_id
    name
    email
    status
    admin
  }
`

const LinksFragment: any = gql`
  fragment LinksData on PaginatorLinks {
    totalResults
    totalPages
    page
    perPage
    prevPage
    nextPage
  }
`

export const GET_USERS: any = gql`
  query Users($input: QueryUsersInput!) {
    users(input: $input) {
      data {
        id
        name
        email
        account_type_id
      }
      links {
        ...LinksData
      }
    }
  }
  ${ LinksFragment }
`

export const GET_MENTIONS_USERS_LIST: any = gql`
  query MentionsUsersList {
    mentionsUsersList {
      id
      name
      email
    }
  }
`

export const GET_FORUM_TOP_USERS_LIST: any = gql`
  query ForumTopUsersList($input: QueryForumTopUsersListInput!) {
    forumTopUsersList(input: $input) {
      id
      name
      email
      avatar_url
      forum_points
      forum_rank_id
    }
  }
`

export const GET_USER_BY_ID: any = gql`
  query UserById($input: QueryUserByIdInput!) {
    userById(input: $input) {
      ...UserData
    }
  }
  ${ UserFragment }
`

export const GET_CURRENT_USER: any = gql`
  query CurrentUser {
    currentUser {
      ...UserData
    }
  }
  ${ UserFragment }
`

export const DELETE_AVATAR_FILE: any = gql`
  mutation DeleteAvatarFile($input: MutationDeleteAvatarFileInput!) {
    deleteAvatarFile(input: $input)
  }
`

export const UPDATE_USER: any = gql`
  mutation UpdateUser($input: MutationUpdateUserInput!) {
    updateUser(input: $input) {
      __typename
      ... on FieldError {
        field
        message
      }
      ... on MutationUpdateUserSuccess {
        data {
          ...UserData
        }
      }
    }
  }
  ${ UserFragment }
`

export const DELETE_USER: any = gql`
  mutation DeleteUser($input: MutationDeleteUserInput!) {
    deleteUser(input: $input) {
      __typename
      ... on FieldError {
        field
        message
      }
      ... on MutationDeleteUserSuccess {
        data
      }
    }
  }
`

import { ChatBubbleOvalLeftIcon, EyeIcon, HeartIcon } from '@heroicons/react/24/outline'
import { SelectOption }                    from '../Select/Select'
import CommunityForumTableItem             from './CommunityForumTableItem'

export default function CommunityForumThreadListTable( props: any ): JSX.Element {
  const {
          isAdmin,
          forumCategories,
          categoryId,
          threadList,
          handleCategoryChange,
          handleSortChange,
          refetch,
          sortBy,
          sortable
        } = props

  const sortOptions: any    = [
    {
      id:   'latestActivityNewest',
      name: 'Newest'
    },
    {
      id:   'latestActivityOldest',
      name: 'Oldest'
    },
    {
      id:   'mostLiked',
      name: 'Most Liked'
    }
    // {
    //   id:   'dateCreatedNewest',
    //   name: 'Date Created: Newest'
    // },
    // {
    //   id:   'dateCreatedOldest',
    //   name: 'Date Created: Oldest'
    // }
  ]
  const selectedOption: any = sortOptions.find( ( option: any ): any => option.id === sortBy )

  return (
    <>
      <div className={ `py-4 px-4 2xl:px-8 mb-2 lg:mb-4 flex flex-col md:flex-row justify-between lg:justify-normal lg:w-full lg:grid grid-forum-table-cols${ isAdmin ? '-admin' : '' } gap-2` }>
        <div className="hidden lg:flex placeholder"></div>
        <div className="flex items-center w-full md:w-auto">
          <div className="flex items-center pl-1 text-grey-input rounded-2xl border border-grey-light overflow-hidden w-full md:w-auto">
            <SelectOption
              fieldClasses="border-none focus:ring-0 font-medium w-full md:w-[255px]"
              wrapperClasses="w-full"
              setSelected={ handleCategoryChange }
              selected={ categoryId }
              placeholder="Category (All)"
              options={ forumCategories.map( ( cat: any ): any => {
                return { key: cat.id, value: cat.name }
              } ) }
            />
          </div>
        </div>

        <div className="hidden lg:flex justify-center items-center text-blue-header"><HeartIcon className="w-5 h-5 mx-auto" /></div>

        <div className="hidden lg:flex justify-center items-center text-blue-header"><EyeIcon className="w-5 h-5 mx-auto" /></div>

        <div className="hidden lg:flex justify-center items-center text-blue-header"><ChatBubbleOvalLeftIcon className="w-5 h-5 mx-auto" /></div>

        { sortable &&
          <div className="flex lg:justify-end items-center w-full md:w-auto">
            <div className="flex items-center pl-4 text-grey-input rounded-2xl border border-grey-light overflow-hidden w-full md:w-auto">
              <div className="text-base	font-medium whitespace-nowrap">Sort by:</div>
              <SelectOption
                setSelected={ handleSortChange }
                selected={ selectedOption }
                placeholder={ selectedOption.name }
                wrapperClasses="w-full"
                fieldClasses="border-none focus:ring-0 font-medium w-full md:w-auto"
                options={ sortOptions.map( ( sort: any ): any => {
                  return { key: sort.id, value: sort.name }
                } ) }
              />
            </div>
          </div>
        }
      </div>

      <div className="bg-blue-subtle p-2 2xl:p-4 rounded-3xl overflow-x-auto space-y-2 2xl:space-y-4">
        { threadList.map( ( forumThread: any, index: any ): any =>
                            <CommunityForumTableItem
                              forumCategory={ forumCategories.find( ( c: any ): boolean => c.id === forumThread.category_id ) }
                              key={ index }
                              forumThread={ forumThread }
                              refetch={ refetch }
                              isAdmin={ isAdmin }
                            /> ) }
        </div>
    </>
  )
}

interface SelectOptionProps
{
  errorField?: boolean
  required?: boolean
  name?: string
  label?: string
  labelClasses?: string
  fieldClasses?: string
  wrapperClasses?: string
  id?: string | number
  selected?: string | number
  options: { key: string | number; value: string | number }[]
  setSelected: ( data: any ) => void
  bg?: string
  placeholder?: string
}

export const SelectOption: any = ( props: SelectOptionProps ): JSX.Element => {
  const {
          placeholder,
          errorField,
          name,
          required,
          label,
          labelClasses,
          fieldClasses,
          wrapperClasses,
          id,
          bg,
          selected,
          options,
          setSelected
        } = props

  const handleSelectChange: any = ( event: any ): void => {
    const selectedValue: string = event.target.value
    setSelected( selectedValue )
  }

  const selectStyling: string = fieldClasses ?? `bg-${ bg ?? 'gray-50' } border ${ errorField ? 'border-red-500' : 'border-gray-300' } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`

  return (
    <div className={ wrapperClasses ?? '' }>
      { label && (
        <label htmlFor={ id?.toString() || 'select_option' } className={ labelClasses ?? 'block mb-2 text-sm font-medium text-gray-900 dark:text-white' }>
          { label }
        </label>
      ) }
      <select
        id={ id?.toString() || 'select_option' }
        value={ selected }
        name={ name }
        required={ required }
        onChange={ handleSelectChange }
        className={ `${selectStyling} cursor-pointer` }
      >
        <option value="">{ placeholder ?? 'Please choose...' }</option>
        { options.map( ( option: any ): any => (
          <option key={ option.key } value={ option.key }>
            { option.value }
          </option>
        ) ) }
      </select>
    </div>
  )
}

import { ChatBubbleLeftRightIcon }                                       from '@heroicons/react/24/outline'
import { FolderIcon }                                                    from '@heroicons/react/24/solid'
import { Link }                                                          from 'react-router-dom'
import { assetFilePath, formatFilePathForView, prefetchFileAndDownload } from '../../utilities'
import Breadcrumb                                                        from '../Breadcrumb/Breadcrumb'
import { Image }                                                         from '../Image'
import PinMe                                                             from '../PinMe/PinMe'

interface SearchResultItemProps
{
  item: any
  addQueryToLocalStorage: ( event: any, file: any ) => void
  handleDownload?: ( e: any, file: any ) => void
  type: string
}

export default function SearchResultItem( props: SearchResultItemProps ): JSX.Element {
  const { item, addQueryToLocalStorage, handleDownload, type } = props

  const determineDocumentPath: any = ( document: any ): string => {
    return document.folder
           ? '/documents/folder/' + document.unique_id
           : '/documents/file/' + document.unique_id
  }

  const markup: any = ( item: any ): any => (
    <div className="flex items-center">
      <div className="flex items-center gap-x-2 flex-1">
        { type === 'documents' && item.folder &&
          <FolderIcon className="text-blue-secondary w-5 h-5" />
        }
        { type === 'documents' && !item.folder &&
          <Image
            src={ assetFilePath( item.files[ 0 ]?.extension ) }
            defaultSrc={ assetFilePath( item.files[ 0 ]?.extension ) }
            klass="w-5 h-5"
          />
        }

        { type === 'forum' &&
          <ChatBubbleLeftRightIcon className="w-5 h-5" />
        }

        <div className="flex-1">
          <div className="flex-1 truncate text-sm max-w-[97%]">{ item.title }</div>
          <Breadcrumb unique_id={ item.unique_id } />
        </div>
      </div>
    </div>
  )

  return (
    <div className="flex flex-1 items-center">
      { type === 'documents' && item.folder &&
        <Link
          data-title={ item.title }
          data-folder={ item.folder }
          data-path={ determineDocumentPath( item ) }
          data-extension={ item.files[ 0 ]?.extension }
          data-file-path={ item.files[ 0 ]?.path }
          to={ determineDocumentPath( item ) }
          onClick={ ( e: any ): any => addQueryToLocalStorage( e, false ) }
          className="flex-1 w-full">
          { markup( item ) }
        </Link> }

      { type === 'documents' && !item.folder &&
        <button
          data-title={ item.title }
          data-folder={ item.folder }
          data-path={ formatFilePathForView( item.files[ 0 ]?.path ) }
          data-extension={ item.files[ 0 ]?.extension }
          data-file-path={ item.files[ 0 ]?.path }
          onClick={ ( e: any ): void => {
            addQueryToLocalStorage( e, item.files[ 0 ] )
            handleDownload( e, item.files[ 0 ] )
          } }
          className="flex-1 w-full text-start"
        >
          { markup( item ) }
        </button> }

      { type === 'document' &&
        <div className="absolute right-3">
          <PinMe document={ item } />
        </div>
      }

      { type === 'forum' &&
        <Link
          data-title={ item.title }
          data-path={ `/forum/${ item.id }` }
          to={ `/forum/${ item.id }` }
          onClick={ ( e: any ): any => addQueryToLocalStorage( e, false ) }
          className="flex-1 w-full">
          { markup( item ) }
        </Link>
      }
    </div>
  )
}

import { useMutation, useQuery }                                              from '@apollo/client'
import { ArrowUturnLeftIcon }                                                 from '@heroicons/react/24/solid'
import { useDispatch, useSelector }                                           from 'react-redux'
import { useNavigate }                                                        from 'react-router-dom'
import { Button }                                                             from '../../components'
import { LoadingEllipsis }                                                    from '../../components/Loaders'
import SettingsForumCategoryForm                                              from '../../components/Settings/SettingsForumCategoryForm'
import { setForumCategories }                                                 from '../../features/forum/communityForumSlice'
import { setToasts, showToaster }                                             from '../../features/toaster/toasterSlice'
import { CREATE_FORUM_CATEGORY, GET_FORUM_CATEGORIES, UPDATE_FORUM_CATEGORY } from '../../graphql/communityForum'
import MainLayout                                                             from '../../layouts/MainLayout'

export default function SettingsForumCategories(): JSX.Element {
  const navigate: any        = useNavigate()
  const dispatch: any        = useDispatch()
  const currentUser: any     = useSelector( ( state: any ): any => state.currentUser.user )
  const forumCategories: any = useSelector( ( state: any ): any => state.communityForum.categories )

  const { loading, refetch } = useQuery( GET_FORUM_CATEGORIES, {
    onCompleted: ( { forumCategories }: any ): void => dispatch( setForumCategories( forumCategories ) )
  } )

  const [ createForumCategory, { loading: creating } ] = useMutation( CREATE_FORUM_CATEGORY, {
    onCompleted: (): void => dispatchMessage( 'success', 'New forum category created!' ),
    onError:     (): void => dispatchMessage( 'error', 'Creating failed. Please try again' )
  } )

  const [ updateForumCategory, { loading: updating } ] = useMutation( UPDATE_FORUM_CATEGORY, {
    onCompleted: (): void => dispatchMessage( 'success', 'Forum category updated!' ),
    onError:     (): void => dispatchMessage( 'error', 'Update failed. Please try again' )
  } )

  const dispatchMessage: any = ( type: string, message: string ): void => {
    dispatch( setToasts( [ { id: '1', type, message } ] ) )
    dispatch( showToaster( true ) )
  }

  const handleFormSubmit: any = async ( name: string, id: string ): Promise<void> => {
    const formData: any = { name }

    if ( id.length ) {
      formData.id = id
      await updateForumCategory( { variables: { input: formData } } )
    } else {
      await createForumCategory( { variables: { input: formData } } )
    }

    await refetch()
  }

  return (
    <MainLayout>
      <div className="max-w-screen-3xl mx-auto w-full">
        <div className="flex-grow xl:p-8 p-3 space-y-4 xl:space-y-8">
          { !currentUser && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4 mt-4 w-full" /> }
          { currentUser?.admin &&
            <>
              <div className="w-24 my-4">
                <Button
                  label="Back"
                  blue
                  onClick={ (): any => navigate( -1 ) }
                  icon={ <ArrowUturnLeftIcon className="w-4 h-4" /> }
                />
              </div>

              { ( loading || creating || updating ) && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }

              { ( !loading && !creating && !updating ) &&
                <>
                  { forumCategories?.map( ( forumCategory: any, index: any ): any =>
                                            <SettingsForumCategoryForm
                                              handleFormSubmit={ handleFormSubmit }
                                              forumCategory={ forumCategory }
                                              key={ index }
                                            /> ) }

                  <SettingsForumCategoryForm handleFormSubmit={ handleFormSubmit } />
                </>
              }
            </>
          }
          { !currentUser?.admin &&
            <div className="row flex flex-col items-center w-full bg-white rounded-3xl p-3 xl:p-4">
              Access Denied!
            </div>
          }
        </div>
      </div>
    </MainLayout>
  )
}

import { useMutation }                                from '@apollo/client'
import { PencilSquareIcon, TrashIcon }                from '@heroicons/react/20/solid'
import { ChatBubbleOvalLeftIcon, EyeIcon, HeartIcon } from '@heroicons/react/24/outline'
import { formatDistance }                             from 'date-fns'
import { useDispatch }                                from 'react-redux'
import { Link }                                       from 'react-router-dom'
import { setToasts, showToaster }                     from '../../features/toaster/toasterSlice'
import { UPDATE_FORUM_THREAD }                        from '../../graphql/communityForum'
import { LoadingEllipsis }                            from '../Loaders/Loaders'
import CommunityForumNotificationsBellButton          from './CommunityForumNotificationsBellButton'

export default function CommunityForumTableItem( props: any ): JSX.Element {
  const { isAdmin, forumCategory, forumThread, refetch } = props
  const dispatch: any                                    = useDispatch()

  const [ deleteForumThread, { loading: deleting } ] = useMutation( UPDATE_FORUM_THREAD, {
    onCompleted: (): void => {
      dispatchMessage( 'success', 'Question deleted!' )
      refetch()
    }
  } )

  const dispatchMessage: any = ( type: string, message: string ): void => {
    dispatch( setToasts( [ { id: '1', type, message } ] ) )
    dispatch( showToaster( true ) )
  }

  const handleDelete: any = ( e: any ): void => {
    e.preventDefault()

    if ( window.confirm( 'Are you sure you want to delete this Question?' ) ) {
      deleteForumThread( { variables: { input: { id: forumThread.id, deleted: true } } } )
    }
  }

  return (
    <>
      { deleting &&
        <LoadingEllipsis klass="bg-white ellipse-yellow ellipse-xl p-4" />
      }

      { !deleting && forumCategory &&
        <div className={ `bg-white rounded-2xl p-2 2xl:p-4 w-full flex lg:grid grid-forum-table-cols${ isAdmin ? '-admin' : '' } gap-2` }>
          <CommunityForumNotificationsBellButton threadId={ forumThread.id } notificationsEnabled={ forumThread.forum_thread_users_notification?.enabled } refetch={ refetch } />

          <div className="flex justify-center flex-col w-full">
            <Link to={ '/forum/' + forumThread.id } className="text-blue-header font-bold text-xl">
              <span>{ forumThread.title }</span>
            </Link>

            <div className="text-sm text-grey-input">
              <div className="inline-flex lg:hidden">
                { formatDistance(
                  new Date( forumThread.last_reply?.updated_at ? forumThread.last_reply.updated_at : forumThread.created_at ),
                  new Date(),
                  { addSuffix: true }
                ) }
                &nbsp;
              </div>
              <span>in </span>
              <Link to={ `/forum?category=${ forumCategory.id }` }>{ forumCategory.name }</Link> by <Link to={ `/profile/${ forumThread.author_id }` }>{ forumThread.author.name }</Link>
            </div>

            <div className="flex lg:hidden space-x-4">
              <div className="flex lg:hidden justify-center items-center text-blue-header">
                <HeartIcon className="w-4 h-4 mx-auto mr-1" />
                { forumThread.replies_liked_count ?? 0 }
              </div>
              <div className="flex lg:hidden justify-center items-center text-blue-header">
                <EyeIcon className="w-4 h-4 mx-auto mr-1" />
                { forumThread.views }
              </div>
              <div className="flex lg:hidden justify-center items-center text-blue-header">
                <ChatBubbleOvalLeftIcon className="w-4 h-4 mx-auto mr-1" />
                { forumThread.replies_count ?? 0 }
              </div>
            </div>
          </div>

          <div className="hidden lg:flex justify-center items-center text-blue-header font-bold text-xl">{ forumThread.replies_liked_count ?? 0 }</div>

          <div className="hidden lg:flex justify-center items-center text-blue-header font-bold text-xl">{ forumThread.views }</div>

          <div className="hidden lg:flex justify-center items-center text-blue-header font-bold text-xl">{ forumThread.replies_count ?? 0 }</div>

          <div className="hidden lg:flex flex-col justify-center items-end text-sm text-grey-input">
            <Link to={ `/profile/${ forumThread.last_reply?.author?.id ? forumThread.last_reply.author.id : forumThread.author_id }` }>{ forumThread.last_reply?.author?.name ? forumThread.last_reply.author.name : forumThread.author.name }</Link>
            <div>
              { formatDistance(
                new Date( forumThread.last_reply?.updated_at ? forumThread.last_reply.updated_at : forumThread.created_at ),
                new Date(),
                { addSuffix: true }
              ) }
            </div>
          </div>

          { isAdmin &&
            <div className="flex justify-center items-center">
              <Link to={ `/forum/edit/${ forumThread.id }` } className="flex p-0 mr-2 text-blue-secondary hover:text-blue-secondary-darker">
                <PencilSquareIcon className="w-4 h-4" />
              </Link>

              <button
                className="p-0 float-right hover:text-red-600"
                color="default"
                onClick={ handleDelete }
              >
                <TrashIcon className="w-4 h-4" />
              </button>
            </div>
          }
        </div>
      }
    </>
  )
}

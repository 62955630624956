import { useLazyQuery }                                from '@apollo/client'
import { debounce }                                    from 'lodash-es'
import { useEffect, useState }                         from 'react'
import { useDispatch, useSelector }                    from 'react-redux'
import { useSearchParams }                             from 'react-router-dom'
import CommunityForumAskQuestion                       from '../../components/Forum/CommunityForumAskQuestion'
import CommunityForumHomeTable                         from '../../components/Forum/CommunityForumHomeTable'
import CommunityForumRecentQuestions                   from '../../components/Forum/CommunityForumRecentQuestions'
import CommunityForumTopUsers                          from '../../components/Forum/CommunityForumTopUsers'
import CommunityForumUserInfo                          from '../../components/Forum/CommunityForumUserInfo'
import { LoadingEllipsis }                             from '../../components/Loaders'
import SearchBar                                       from '../../components/Search/SearchBar'
import { setForumCategories, setThreadList }           from '../../features/forum/communityForumSlice'
import { GET_FORUM_CATEGORIES, GET_FORUM_THREAD_LIST } from '../../graphql/communityForum'
import MainLayout                                      from '../../layouts/MainLayout'

export default function CommunityForum(): JSX.Element {
  const dispatch: any                           = useDispatch()
  const [ searchParams ]                        = useSearchParams()
  const [ forumCategoryId, setForumCategoryId ] = useState( '' )
  const [ sortBy, setSortBy ]                   = useState( 'latestActivityNewest' )
  const currentUser: any                        = useSelector( ( state: any ): any => state.currentUser.user )
  const currentTenant: any                      = useSelector( ( state: any ): any => state.currentTenant.tenant )
  const forumCategories: any                    = useSelector( ( state: any ): any => state.communityForum.categories )
  const bodyInfo: string                        = currentTenant?.pages?.forum?.body ?? ''
  const headerText: string                      = currentTenant?.pages?.forum?.header ?? 'CommunityForum'

  const resolveSort: any = ( sort: string ): string => {
    switch ( sort ) {
      case 'mostLiked':
      case 'latestActivityNewest':
        // case 'dateCreatedNewest':
        return 'desc'
      case 'latestActivityOldest':
        // case 'dateCreatedOldest':
        return 'asc'
      default:
        return 'desc'
    }
  }

  const resolveSortBy: any = ( sortBy: string ): string => {
    switch ( sortBy ) {
      case 'latestActivityNewest':
      case 'latestActivityOldest':
        return 'created_at'
      case 'mostLiked':
        return 'replies_liked_count'
      default:
        return 'created_at'
    }
  }

  const [ getCategories, { loading: loadingCategories, error: errorLoadingCategories } ] = useLazyQuery(
    GET_FORUM_CATEGORIES, {
      notifyOnNetworkStatusChange: true,
      onCompleted:                 ( { forumCategories }: any ): void => dispatch( setForumCategories( forumCategories ) )
    } )

  const [ getForumThreadList, { loading: loadingThreads, error: errorLoadingThreads, refetch } ] = useLazyQuery(
    GET_FORUM_THREAD_LIST, {
      notifyOnNetworkStatusChange: true,
      onCompleted:                 ( { forumThreadList }: any ): void => dispatch( setThreadList( forumThreadList ) )
    } )

  const [ searchThreads, { data: searchThreadList, loading: searchingThreads } ] = useLazyQuery( GET_FORUM_THREAD_LIST )

  const getSearchThreads: any = async ( query: string = '', page: number = 1 ): Promise<void> => {
    await searchThreads( { variables: { input: { query, page, perPage: 15, sort: 'desc', sortBy: 'created_at' } } } )
  }

  const debouncedSearch: any = debounce( async ( query: string, page: number ): Promise<any> => await getSearchThreads( query, page ), 2000 )

  const handleSearch: any = ( value: string ): void => {
    if ( value.length && value.trim().length >= 3 ) {
      debouncedSearch( value, 1 )
    }
  }

  const fetchCategories: any = async (): Promise<any> => {
    await getCategories()
  }

  const fetchForumThreadList: any = async ( categoryId: string, sortBy: string, page: number ): Promise<any> => {
    await getForumThreadList( {
                                variables: {
                                  input: {
                                    category_id: categoryId,
                                    page:        ( page > 0 ) ? page : 1,
                                    sort:        resolveSort( sortBy ),
                                    sortBy:      resolveSortBy( sortBy )
                                  }
                                }
                              } )

  }

  useEffect( (): any => {
    let shouldFetchCategories: boolean = true

    if ( shouldFetchCategories && !forumCategories.length ) {
      fetchCategories()
    }

    return (): void => {
      shouldFetchCategories = false
    }
  }, [ forumCategories ] )

  useEffect( (): any => {
    const categoryParam: string = searchParams.get( 'category' )
    const sortByParam: string   = searchParams.get( 'sortBy' ) ?? ''
    const pageParam: number     = !isNaN( parseInt( searchParams.get( 'page' ) ) ) ? parseInt( searchParams.get( 'page' ) ) : 1

    if ( categoryParam ) {
      setForumCategoryId( categoryParam )
    } else {
      setForumCategoryId( '' )
      if ( searchParams.has( 'category' ) ) {
        searchParams.delete( 'category' )
      }
    }

    if ( sortByParam ) {
      setSortBy( sortByParam )
    } else {
      setSortBy( 'latestActivityNewest' )
    }

    fetchForumThreadList( categoryParam, sortByParam, pageParam )
  }, [ searchParams ] )

  return (
    <MainLayout>
      <div className="max-w-screen-3xl mx-auto w-full">
        <div className="flex-grow 2xl:p-8 px-4 py8 space-y-4 2xl:space-y-8 ">

          <div className="flex flex-col 2xl:flex-row 2xl:space-x-8">
            <div className="w-full 2xl:w-2/3">
              <div className="row flex items-center w-full bg-white rounded-3xl p-4 2xl:p-6 mb-6">
                <div className="col flex flex-col w-full text-blue-header">
                  <h4 className="card-title mb-2 text-xl font-bold">{ headerText }</h4>
                  <p className="text-muted text-sm font-medium">{ bodyInfo }</p>
                </div>
              </div>

              <div className="row flex items-center w-full bg-white rounded-3xl p-1.5 2xl:py-2 2xl:px-4 mb-6 text-blue-input">
                <SearchBar
                  resultsInModal
                  loading={ searchingThreads }
                  results={ searchThreadList?.forumThreadList?.data ?? null }
                  pagination={ searchThreadList?.forumThreadList?.links ?? null }
                  onChange={ handleSearch }
                  setPage={ getSearchThreads }
                  placeholder="Search forum"
                  type="forum"
                />
              </div>

              <div className="row flex flex-col items-center w-full bg-white rounded-3xl p-3 2xl:p-4">
                <div className="col flex flex-col w-full">
                  { ( loadingThreads || loadingCategories ) &&
                    <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4 mt-4 w-full" />
                  }

                  { ( !loadingThreads && !loadingCategories ) &&
                    <CommunityForumHomeTable
                      categoryId={ forumCategoryId }
                      forumCategories={ forumCategories }
                      refetch={ (): any => refetch() }
                      sortBy={ sortBy }
                    />
                  }
                </div>
              </div>

              { errorLoadingCategories &&
                <div className="flex flex-col w-full bg-white rounded-xl p-4 2xl:p-5">
                  <b>Error occurred when fetching Forum Categories:</b>
                  { errorLoadingCategories.graphQLErrors.map( ( { message }: any, i: number ): any => (
                    <div key={ ( i + 1000 ) } className="flex w-full"><span>{ message }</span></div>
                  ) ) }
                </div>
              }

              { errorLoadingThreads &&
                <div className="flex flex-col w-full bg-white rounded-xl p-4 2xl:p-5">
                  <b>Error occurred when fetching Forum Thread List:</b>
                  { errorLoadingThreads.graphQLErrors.map( ( { message }: any, i: number ): any => (
                    <div key={ ( i + 2000 ) } className="flex w-full"><span>{ message }</span></div>
                  ) ) }
                </div>
              }
            </div>

            <div className="w-full 2xl:w-1/3 mt-4 2xl:mt-0">
              <div className="row flex flex-col items-center w-full mb-3 2xl:mb-6">
                <CommunityForumAskQuestion isColumn label="Ask a new question" />
              </div>

              { currentUser &&
                <div className="row flex flex-col items-center w-full mb-3 2xl:mb-6">
                  <div className="flex flex-col justify-center bg-white shadow-soft rounded-3xl w-full p-4 2xl:p-8 space-y-4 2xl:space-y-8">
                    <CommunityForumUserInfo user={ currentUser } />
                    <CommunityForumRecentQuestions user={ currentUser } qty={ 3 } />
                  </div>
                </div>
              }

              <div className="row flex flex-col items-center w-full">
                <div className="flex flex-col justify-center bg-white shadow-soft rounded-3xl w-full p-4 2xl:p-8 space-y-4 2xl:space-y-8">
                  <CommunityForumTopUsers limit={ 5 } />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

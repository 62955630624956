import { useLazyQuery }                        from '@apollo/client'
import { debounce }                            from 'lodash-es'
import { useLayoutEffect }                     from 'react'
import { useDispatch, useSelector }            from 'react-redux'
import { LoadingEllipsis }                     from '../../components/Loaders/Loaders'
import PinnedDocuments                         from '../../components/PinnedDocuments/PinnedDocuments'
import SearchBar                               from '../../components/Search/SearchBar'
import { setDocumentFolders }                  from '../../features/documents/documentsSlice'
import { GET_DOCUMENT_FOLDERS, GET_DOCUMENTS } from '../../graphql/document'
import MainLayout                              from '../../layouts/MainLayout'
import DocumentsTable                          from './DocumentsTable'

export default function Documents(): any {
  const folders: any       = useSelector( ( state: any ): any => state.documents.folders )
  const dispatch: any      = useDispatch()
  const currentTenant: any = useSelector( ( state: any ): any => state.currentTenant.tenant )
  const bodyInfo: string   = currentTenant?.pages?.documents?.body ?? ''

  const [ getFolders, { loading: fetchingDocumentFolders, error: documentFoldersError } ] = useLazyQuery(
    GET_DOCUMENT_FOLDERS,
    {
      onCompleted: ( { documentFolders }: any ): any => dispatch( setDocumentFolders( documentFolders ) )
    }
  )

  useLayoutEffect( () => {
    if ( !folders.length ) {
      getFolders()
    }
  }, [] )

  const [ searchDocuments, { data: searchDocumentList, loading: searchingDocuments } ] = useLazyQuery( GET_DOCUMENTS )

  async function getDocuments( query: string = '', page: number = 1 ): Promise<any>
  {
    await searchDocuments(
      {
        variables: { input: { query, page, perPage: 15, sort: 'desc', sortBy: 'created_at' } }
      } )
  }

  const debouncedSearch: any = debounce( async ( query: string, page: number ): Promise<void> => await getDocuments( query, page ), 2000 )
  const handleSearch         = ( value: string ): void => {
    if ( value.length && value.trim().length >= 3 ) {
      debouncedSearch( value, 1 )
    }
  }

  // @ts-ignore
  return (
    <MainLayout>
      <div className="max-w-screen-3xl mx-auto w-full">
        <div className="flex-grow lg:p-10 p-3 lg:space-y-8 space-y-3">

          <SearchBar
            resultsInModal
            loading={ searchingDocuments }
            results={ searchDocumentList?.documents?.data ?? null }
            pagination={ searchDocumentList?.documents?.links ?? null }
            onChange={ handleSearch }
            setPage={ getDocuments }
            placeholder="Search documents"
            type="documents"
          />

          <div className="row flex items-center w-full bg-white rounded-xl p-4 lg:p-5">
            <div className="col flex w-full">
              <div className="card w-full relative">
                <div className="card-body">
                  <div className="heading w-full mb-8">
                    <h4 className="card-title mb-0 text-xl text-medium">Documents</h4>
                    <p className="text-muted text-sm text-gray-500">{ bodyInfo }</p>
                  </div>

                  { /**  Pinned documents --> */ }
                  <PinnedDocuments />

                  <div className="doc-app">
                    {
                      fetchingDocumentFolders
                      ? <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" />
                      : <DocumentsTable />
                    }
                    {
                      documentFoldersError &&
                      <div className="flex flex-col">
                        <b>Error occurred when fetching document folders:</b> { ' ' }
                        { documentFoldersError.graphQLErrors.map( ( { message }, i: number ) => (
                          <span key={ i }>{ message }</span>
                        ) ) }
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

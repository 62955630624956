import MainLayout from "../../layouts/MainLayout"

export default function HowToVideos() {
    const items: any[] = [
        {title: "Homepage", videoUrl: "https://bananamoonhub.s3.amazonaws.com/videos/Homepage.mp4"},
        {title: "News", videoUrl: "https://bananamoonhub.s3.amazonaws.com/videos/News.mp4"},
        {title: "Documents", videoUrl: "https://bananamoonhub.s3.amazonaws.com/videos/Documents.mp4"},
        {title: "Profile", videoUrl: "https://bananamoonhub.s3.amazonaws.com/videos/Profile.mp4"},
        {title: "CommunityForum", videoUrl: "https://bananamoonhub.s3.amazonaws.com/videos/Forum.mp4"},
        {title: "Suppliers Page", videoUrl: "https://bananamoonhub.s3.amazonaws.com/videos/Suppliers.mp4"},
        {title: "BM Platform", videoUrl: "https://bananamoonhub.s3.amazonaws.com/videos/BM%20Platform.mp4"},
        {title: "The Shop", videoUrl: "https://bananamoonhub.s3.amazonaws.com/videos/The%20shop.mp4"},
        {title: "Tasks", videoUrl: "https://bananamoonhub.s3.amazonaws.com/videos/Tasks.mp4"}
    ];

    return (
        <MainLayout>
            <div className="max-w-screen-lg mx-auto w-full bg-white sm:p-12 p-6">
                <header className="mb-3">
                    <h1 className="sm:text-xl text-lg font-bold">How To Videos</h1>
                </header>

                <p><a className="hover:underline text-blue-primary font-medium" target="_blank" href="https://docs.bananamoon-thehub.co.uk/">Click here</a> to view our nursery website 'how to' videos and documentation.</p>

                <section className="sm:space-y-6 space-y-3 divide-y flex flex-col justify-center">
                    {items.map((item: any) => (
                        <article className="sm:py-6 py-3" key={item.title}>
                            <h3 className="sm:my-4 my-2 text-center text-lg font-semibold">{ item.title }</h3>
                            <video height={ 280 }>
                                <source src={ item.videoUrl} />
                                Your browser does not support the video tag.
                            </video>
                        </article>
                    ))}
                </section>

            </div>
        </MainLayout>
    )
}
import { useMutation }                                from '@apollo/client'
import { HeartIcon as LikeIcon, StarIcon, TrashIcon } from '@heroicons/react/24/outline'
import { HeartIcon as LikedIcon, SparklesIcon }       from '@heroicons/react/24/solid'
import { format, parseISO }                           from 'date-fns'
import { useSelector }                                from 'react-redux'
import { Link }                                       from 'react-router-dom'
import { UPDATE_FORUM_THREAD }                        from '../../graphql/communityForum'
import { LoadingEllipsis }                            from '../Loaders'
import CommunityForumUserInfo                         from './CommunityForumUserInfo'

export default function CommunityForumSingleReply( props: any ): JSX.Element {
  const { canModify, reply, replyable, bestReplyId, setBestReply, toggleLike, handleDelete } = props
  const currentUser: any                                                                     = useSelector( ( state: any ): any => state.currentUser.user )
  const isBestReply: boolean                                                                 = bestReplyId && ( reply.id === bestReplyId )
  const isLiked: boolean                                                                     = reply.likes?.find( ( like: any ): any => ( like.user_id === currentUser?.id ) && like.is_liked )
  const replyLikes: any                                                                      = reply.likes.filter( ( like: any ): any => like.is_liked )

  const [ updateForumThread, { loading } ] = useMutation( UPDATE_FORUM_THREAD )

  const markBestReply: any = async (): Promise<void> => {
    if ( window.confirm( 'Are you sure you want mark this replay as best?' ) ) {
      await updateForumThread( { variables: { input: { id: reply.thread_id, best_reply_id: reply.id } } } )
      setBestReply( reply )
    }
  }

  const removeBestReply: any = async (): Promise<void> => {
    if ( window.confirm( 'Are you sure you want unmark this replay as best?' ) ) {
      await updateForumThread( { variables: { input: { id: reply.thread_id, best_reply_id: '' } } } )
      setBestReply( null )
    }
  }

  return (
    <>
      <div className="flex flex-col md:flex-row justify-between">
        <div className="flex">
          { ( replyable || isBestReply ) &&
            <CommunityForumUserInfo compact user={ reply.author } />
          }
          { !replyable && reply.thread &&
            <div className="col flex w-full">
              <Link to={ '/forum/' + reply.thread.id } className="text-2xl text-medium text-blue-500 hover:text-blue-800">
                Question: <span>{ reply.thread.title }</span>
              </Link>
            </div>
          }
        </div>
        <div className="flex items-baseline justify-end space-x-2">
          <>
            { isBestReply && !loading &&
              <div className="flex items-start text-blue-input">
                <div className={ `flex items-center${ canModify ? ' cursor-pointer' : '' }` } onClick={ (): any => canModify && removeBestReply() }>
                  <SparklesIcon className="w-5 h-5 mr-2" />
                  <span>Best reply</span>
                </div>
              </div>
            }
            { replyable && !isBestReply && !loading && canModify &&
              <StarIcon className="w-4 h-4 text-blue-input cursor-pointer" onClick={ (): any => markBestReply() } />
            }
            <div className="flex items-center">
              { replyable && !loading && isLiked &&
                <LikedIcon className="w-4 h-4 text-blue-input cursor-pointer" onClick={ (): any => toggleLike() } />
              }
              { replyable && !loading && !isLiked &&
                <LikeIcon className="w-4 h-4 text-blue-input cursor-pointer" onClick={ (): any => toggleLike() } />
              }
              { ( replyLikes.length > 0 ) &&
                <span className="ml-1">{ replyLikes.length }</span>
              }
            </div>
            { currentUser.admin &&
              <button
                className="p-0 float-right hover:text-red-600"
                color="default"
                onClick={ handleDelete }
              >
                <TrashIcon className="w-4 h-4" />
              </button>
            }
            { loading && <LoadingEllipsis klass="ellipse-yellow ellipse-xl p-4" /> }
          </>
        </div>
      </div>

      <div className="flex flex-col overflow-x-auto break-words mt-4" dangerouslySetInnerHTML={ { __html: reply.body } } />

      <div className="flex text-grey-input mt-4">Posted on { format( parseISO( reply.created_at ), 'do MMMM yyyy hh:mm a' ) }</div>
    </>
  )
}
